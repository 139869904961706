.valuePlainStyle {
  composes: global(body--l-fixed);
  color: var(--c-tertiary-dark);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 2px;
}

.headerMultiSelect {
  padding-top: 4px;
}

.checkboxLabel {
  font-weight: bold;
}

.descriptionContainer {
  margin-top: -2px;
}

.descriptionContainerMultiSelect {
  margin-top: 6px;
}

.radio {
  font-weight: 500;
}

@media (min-width: 768px) {
  .descriptionContainer {
    margin-top: 2px;
  }

  .descriptionContainerMultiSelect {
    margin-top: 5px;
  }
}
