.root {
    height: 100%;
    transition: transform 300ms;
}

.spinner {
    height: 100%;
}

.hasActiveThread {
    transform: translateX(-30%);
}
