.page {
  composes: global(t-32) global(b-32) global(container);
}

.content {
  margin: auto;
}
.signInFormInner,
.signInBrandLogosContainer {
  padding-left: 0;
  padding-right: 0;
}

.signUpHereButton {
  font-weight: bold;
}

.passwordRecoveryButton {
  font-weight: bold;
}

.redirectPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.redirectBlock {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.redirectImgContainer {
  text-align: center;
  width: 180px;
}

.redirectImgContainer > * {
  width: 100%;
}

.redirectTitle {
  text-align: center;
}

.redirectSubtitle {
  font-weight: 400;
  padding: 0 30px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .bottomBtnGroup {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .redirectBlock {
    margin-top: 0;
  }

  .redirectImgContainer {
    width: 280px;
  }


}
@media screen and (min-width: 1024px) {
  .bottomBtnGroup {
    justify-content: flex-start;
  }

  .redirectTitle {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1023.98px) {
  .signInFormInner {
    margin-left: auto;
    margin-right: auto;
  }
  .signInBrandLogosContainer {
    margin-left: auto;
    margin-right: auto;
  }
}
