
.modalRoot {
  z-index: 3002;
}

.modal {
  box-shadow: none;
  width: 100vw;
  min-height: 100%;
  border: none;
  margin: 0;
  border-radius: 0;
  padding: 0;
  max-height: 100%;
  overflow-y: auto;
}
.modalRoot:before {
  display: none !important;
}

.crossButton {
  width: 52px;
  height: 52px;
  position: fixed;
  right: var(--cwrr-scroll-width, 0);
}

.calendarDayPickerContainer {
  padding-bottom: 156px;
}

@media (min-width: 1680px) {
  .gutterRemover {
    margin-left: -32px;
    margin-right: -32px;
  }
}

.calendarFooter {
  composes: global(db-m);
  position: fixed;
  bottom: 0;
  left: 0;
  right: var(--cwrr-scroll-width, 0);
  background: #ffffff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}
.calendarFooterInner {
  height: 92px;
}

@media screen and (min-width: 0px) and (max-width: 767.98px) {
  .calendarFooterInner {
    height: 84px;
  }

  .footerButtonSelect {
    margin-left: 0;
  }
}
@media screen and (max-width: 1023.98px) {
  .calendarDayPickerContainer {
    padding-bottom: 172px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
  .calendarDayPickerContainer {
    margin: auto;
    width: calc((100% + 24px) / 12 * 9);
    padding-bottom: 172px;
  }
}

.dayPrice {
  composes: global(dn) global(db-m);
  height: 28px;
  padding: 0 8px;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: var(--c-tertiary-dark);
  position: absolute;
  bottom: 11px;
  left: 11px;
  border-radius: 4px;
  background: #ffffff;
  text-align: left;
}

.dayPriceDiff {
  composes: dayPrice;
  color: var(--c-status-warning-dark);
  background: var(--c-status-warning-light);
  border-radius: 4px;
}

.disabledPrevDates .dayPrice,
.disabledPrevDates .dayPriceDiff {
  display: none;
}
