.title {
  height: 60px;
  display: inline-flex;
  composes: global(body--l);
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.continueButtonContainer {
  white-space: normal;
}
.buttonStyle {
  width: 100%;
  box-sizing: border-box;
  height: 52px;
}

.buttonStyle span {
  font-size: 16px;
  line-height: 16px;
}

.buttonStyle svg {
  width: 24px;
  height: 24px;
  /* margin-right: 24px; */
}
@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .buttonStyle {
  }
  .title {
    height: 70px;
  }
}
@media screen and (min-width: 568px) and (max-width: 1023.98px) {
  .title {
    height: 69px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
  .title {
    height: 100%;
    margin: 20px 0 0;
    padding: 0;
  }
  .continueButtonContainer {
    white-space: normal;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1679.98px) {
  .title {
    height: 100%;
    margin: 20px 0 0;
    padding: 0;
  }
  .continueButtonContainer {
    white-space: normal;
  }
}
@media screen and (min-width: 1024px) {
  .continueButtonContainer {
    white-space: normal;
    padding-bottom: 17px;
  }
  .title {
    height: 100%;
    margin: 20px 0 0;
    padding: 0;
  }
}
@media screen and (min-width: 1680px) {
  .continueButtonContainer {
    white-space: normal;
  }
}
