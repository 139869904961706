
.container {
  position: relative;
  background: var(--c-quaternary);
  border-radius: 4px;
}

.container::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: transparent;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23C4CDD5FF' stroke-width='1' stroke-dasharray='12%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  pointer-events: none;
}

.title {
  display: flex;
  align-items: center;
}

.questionTooltipContainer {
  max-width: 290px;
}

.questionIcon {
  cursor: pointer;
}

.questionIcon svg {
  width: 25px;
  height: 25px;
}

.questionIcon:hover svg path {
  fill: var(--c-tertiary);
}

.button {
  background: #edf0f2;
  width: 100%;
}

@media (min-width: 768px) {

  .inner {
    padding: 10px 20px 20px 24px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    padding-top: 22px;
  }

  .button {
    width: auto;
    margin-top: 10px;
  }
}
