.root {
}

.touchable {
    cursor: pointer;
}

.label,
.value {
    color: var(--c-tertiary);
}
.label {
    font-weight: 500;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon {
    margin-left: 4px;
    line-height: normal;
    font-size: 24px;
    color: var(--c-black-cool);
}
