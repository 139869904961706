.root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-color: var(--c-quaternary-light);
}

.list {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
}

.empty {
    height: 100%;
}

.message {
    margin-bottom: 12px;
}

.groupTitle {
    color: var(--c-tertiary-light);
}

.typingPadding {
    min-height: 0;
    transition: min-height 300ms;
}

.typingPaddingActive {
    min-height: 48px;
}
