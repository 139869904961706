.container {
  position: relative;
}
.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(10px, 1fr)); 
  grid-template-rows: repeat(2, minmax(10px, 1fr));
  grid-gap: 16px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.gridPhotoContainer {
  aspect-ratio: 1 / 1;
  height: 100%;
  max-width: 100%;
}
.gridPhoto {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.gridItem:nth-child(1) {
  grid-column: 1/7;
  grid-row: 1/3;
}

.gridItem:nth-child(2) {
  grid-column: 7/10;
  grid-row: 1; 
}
.gridItem:nth-child(3) {
  grid-column: 10/13;
  grid-row: 1; 
}

.gridItem:nth-child(4) {
  grid-column: 7/10;
  grid-row: 2; 
}

.gridItem:nth-child(5) {
  grid-column: 10/13;
  grid-row: 2; 
}

.showPhotosButton {
  position: absolute;
  bottom: 32px;
  right: 32px;
}

.showPhotosButton button {
  border: none;
}

@media (min-width: 1024px) {
  .gridPhotoContainer{
    aspect-ratio: 4 / 3;
  }
}
@supports not (aspect-ratio: 4 / 3) {
  .gridPhotoContainer {
    position: relative;
  }

  .gridPhotoContainer::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  .gridPhoto {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media (min-width: 1440px) {
    .gridPhotoContainer::before {
      padding-top: 75%;
    }
  }
}