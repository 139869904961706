.modal {
  width: 100%;
  margin: 0 auto;
}

.description {
  color: var(--c-tertiary);
}

@media (min-width: 375px) {
  .modal {
    width: 343px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .modal {
    width: 486px;
    padding-top: 14px;
  }
  .title {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1024px) {
  .modal {
    width: 496px;
    padding-top: 12px;
  }

  .title {
    font-size: 32px;
    line-height: 48px;
  }
}
