.header {
}

.content {
    margin-left: auto;
    margin-right: auto;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    background: #ffffff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
}



@media (max-width: 767.98px) {
    .footerContent {
        justify-content: center;
    }
}

@media (min-width: 1680px) {
    .content,
    .header,
    .footerContent {
        padding: 0 14.8%;
    }
}
