.placeholderDateText {
	color: var(--c-tertiary-light);
}

.pageDevider {
	background-color: var(--c-quaternary);
}

@media (min-width: 768px) {
	.pageDevider {
		background-color: var(--c-quaternary-dark);
	}
}
