.container {
  display: grid;
  grid-template-columns: 92px 1fr;
  grid-gap: 0 16px;
}

.title {
  color: var(--tertiary-light, #7F7F7F);
}

.value {
  font-weight: 500;
}

@media (min-width: 768px) {
  .container {
    grid-template-columns: 109px 1fr;
  }
}

@media (min-width: 1024px) {
  .container {
    grid-template-columns: 140px 1fr;
  }
}

@media (min-width: 1440px) {
  .container {
    grid-template-columns: 110px 1fr;
  }
}

@media (min-width: 1680px) {
  .container {
    grid-template-columns: 100px 1fr;
  }
}
