.title {
  color: var(--c-black-cool);
}
.subtitle {
  color: var(--c-tertiary);
}
@media (max-width: 767.98px) {
  .modal {
    padding: 16px 16px 32px;
  }
}
@media (min-width: 768px) {
  .modal {
    max-width: 486px;
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .modal {
    max-width: 632px;
  }
}
@media (min-width: 1440px) {
  .modal {
    max-width: 636px;
  }
}
@media (min-width: 1920px) {
  .modal {
    max-width: 760px;
  }
}
