.row {
  composes: global(row) global(no-gutters) global(b-12) global(b-m-0);
  margin: -12px -8px;
}
@media (min-width: 768px) {
  .row {
    margin-top: -16px;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .row {
    margin-left: -12px;
    margin-right: -12px;
  }
}
@media (min-width: 1680px) {
  .row {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.cell {
  composes: global(col-6) global(col-s-4);
  touch-action: none;
}

.activeCell {
  composes: cell;
  opacity: 0.3;
}

.gaps {
  padding: 12px 8px;
}
@media (min-width: 768px) {
  .gaps {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
@media (min-width: 1024px) {
  .gaps {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1680px) {
  .gaps {
    padding-left: 16px;
    padding-right: 16px;
  }
}
