.optionsList,
.optionsListItem {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
.optionsList {
  list-style: none;
}

.optionsListCard {
  display: flex;
  align-items: stretch;
  margin-left: -8px;
  margin-right: -8px;
}

.optionsListItem {
  display: block;
  width: 100%;
}
.optionsListItemCard {
  margin: 0 8px;
}
.optionsListItemCollapse {
  border-top: 1px solid #c4cdd5;
}
.optionsListItemCollapse:last-child {
  border-bottom: 1px solid #c4cdd5;
}

@media screen and (min-width: 1024px) and (max-width: 1679.98px) {
  .optionsListItemCard {
    margin-left: 24px;
    margin-right: 0;
  }

  .optionsListItemCard:first-child {
    margin-left: 8px;
  }
  .optionsListItemCard:last-child {
    margin-right: 8px;
  }
}

@media screen and (min-width: 1680px) {
  .optionsListItemCard {
    margin-left: 32px;
    margin-right: 0;
  }

  .optionsListItemCard:first-child {
    margin-left: 8px;
  }
  .optionsListItemCard:last-child {
    margin-right: 8px;
  }
}