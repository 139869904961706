
.popper {

}
@media (min-width: 768px) {
	.popper {
		margin-left: 16px;
		width: calc(100vw - 48px);
		max-width: 832px;
	}
}

@media (min-width: 1024px) {
	.popper {
		margin-left: 32px;
		width: 832px;
	}
}

@media (min-width: 1414px) {
	.popper {
		margin-left: 0px;
	}
}