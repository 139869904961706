.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--widget-card-popup-color, #BFC5CE);
  background: var(--widget-card-popup-color, #fff);
}
.cardContainer {
  min-height: 556px;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
}
.headerContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
}
.header {
  display: flex;
  align-items: flex-start;
  gap: 0 12px;
}

.nameContainer {
  flex: 1
}
.rvClassContainer {
  margin-top: -2px;
}
.priceContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-end;
}
.priceTextContainer {
  margin-top: -8px;
}
.lightningIcon svg {
  width: 24px;
  height: 24px;
}

.lightningIcon {
  width: 30px;
  height: 30px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.lightningIconDark svg path {
  fill: #FF9745;
}

.deliveryOnlyIconDark svg path,
.standardRentalIconDark svg path {
  fill: #fff !important;
}

.lightningTooltipContent {
  width: 198px;
}
.footer {
  width: 100%;
  margin-top: -14px;
}
.footerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  background: var(--widget-border-dates-color, #EAECEF);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.footerIconContent {
  width: 320px;
}

.footerIconContentDark {
  background-color: #EEEEEE;
  color: #232323;
}

.lightningTooltipContentDark {
  background-color: #EEEEEE;
  color: #232323;
}

.footerIconContentDark[data-popper-placement*="top"] .arrow::before  {
  border-color: #EEEEEE transparent transparent transparent;
}

.footerIconContentDark[data-popper-placement*="left"] .arrow::before  {
  border-color: transparent transparent transparent #EEEEEE;
}


.standardRentalIcon,
.deliveryOnlyIcon {
  cursor: pointer;
}

.standardRentalIcon:hover svg path,
.deliveryOnlyIcon:hover svg path {
  fill: #4d6539;
}
.deliveredFrom {
  width: calc(100% - 72px);
}
.deliveredFrom.deliveredFromSmall {
  width: calc(100% - 34px);
}
.deliveredFromTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.footerIcons {
  display: flex;
  grid-gap: 0 8px;
  margin-right: 8px;
}

.standardRentalIcon svg,
.deliveryOnlyIcon svg {
  width: 24px;
  height: 24px;
}
.standardRentalIcon svg path,
.deliveryOnlyIcon svg path {
  fill: var(--widget-text-border-color, #000);
}
.deliveredFromTitle {
  color: var(--widget-text-border-color, #474342);
}
.title,
.location,
.priceSum,
.featuresNumb {
  color: var(--widget-text-border-color, #474342);
}
.priceSumContainer {
  margin-left: -2px;
}
.priceText,
.rvClass,
.featuresName {
  color: var(--widget-text-border-2-color, #747272);
}
.priceText {
  white-space: nowrap;
  text-align: right;
}

.location {
  margin-top: -16px;
}
.features {
  display: flex;
  flex-wrap: wrap;
  gap: 500px 16px;
  height: 60px;
  overflow: hidden;
  margin-top: 4px;
}
.featuresName {
  white-space: nowrap;
}
.featuresNumb {
  margin-top: -9px;
  text-align: center;
}
.featuresItem {
  position: relative;
}

.featuresItem + .featuresItem {
  padding-left: 2px;
}

.featuresItem:first-child {
  border-left: none;
}
.featuresItem:first-child::before {
  display: none;
}
.imgContainer {
  position: relative;
}

.img {
  object-fit: cover;
  object-position: center center;
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  max-height: 470px;
}
.showMoreButton {
  color: var(--widget-text-color, #474342) !important;
  background: var(--widget-background-color, #fff) !important;
  border-color: var(--widget-text-color, #474342) !important;
}
.showMoreButton:hover,
.showMoreButton:focus {
  background: var(--widget-pressed-button-color, #E6E6E6) !important;
}
@media (min-width: 720px) {
  .card {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .features {
    gap: 500px 24px;
  }

  .rvClassContainer {
    margin-top: -4px;
  }

  .footer {
    margin-top: -18px;
  }

  .footerIcons {
    margin-right: -2px;
  }
  .deliveredFrom {
    width: calc(100% - 64px);
  }
  .deliveredFrom.deliveredFromSmall {
    width: calc(100% - 26px);
  }
}

@media (min-width: 1024px) {
  .card {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

@media (min-width: 1440px) {
  .card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 1680px) {
  .card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}