.header {
  display: flex;
  align-items: center;
}

.clubName {
  padding-top: 12px;
}

.clubIconContainer {
  margin-right: 9px;
}

.description {
  color: var(--c-tertiary-light, #7F7F7F);
}

.footerButton {
  line-height: 16px;
}

.clubIcon {
  width: 40px;
  height: 40px;
}

@media (min-width: 768px) {
  .clubIconContainer {
    margin-right: 12px;
  }

  .clubIcon {
    width: 48px;
    height: 48px;
  }

  .joinButton {
    padding: 18px 24px;
    height: 52px;
  }
}

@media (min-width: 1440px) {
  .clubIcon {
    width: 54px;
    height: 54px;
  }
}
