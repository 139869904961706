.fieldsContainer {
  align-items: flex-end;
}

.updateButton {
  font-weight: bold;
}

.headerTitle {
  display: flex;
  align-items: center;
}

.headerTitle svg {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}

.headerTitle svg path {
  fill: revert-layer;
}

.addPayoutInfo,
.addPayoutInfoButton {
  position: relative;
  z-index: 1;
}
.bancAccountNumber {
  letter-spacing: 4px;
}
.bankAccountNumberField {
  width: 10px;
  height: 10px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  z-index: -1;
  pointer-events: none;
  cursor: default;
}

@media (max-width: 768px) {
  .headerTitle svg {
    margin-left: 8px;
  }
}