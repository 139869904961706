.modalWrap {
  overflow: hidden;
}
.modal {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.crossButton {
  width: 52px;
  height: 52px;
}

.header {
  height: 123px;
  background: #fff;
  padding: 16px 54px 0;
}

.title {
  text-align: center;
  font-weight: bold;
}

.footer {
  height: 84px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
}

.footerInner {
  height: 100%;
  padding: 16px 16px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

}

.content {

}

.contentInner1 {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 208px);
  margin-right: 4px;
  scrollbar-width: thin;
  scrollbar-color: #C4CDD5 transparent;
}
.contentInner1::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
}
.contentInner1::-webkit-scrollbar-track {
  border-radius: 4px;
}
.contentInner1::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #C4CDD5;
}

.contentInner2 {
  padding: 17px 12px 32px 16px;
}

.crossButton {
  border-radius: 0;
}

@media (min-width: 768px) {

  .header {
    height: 112px;
    padding: 21px 54px 0;
  }
  
  .contentInner1 {
    overflow-y: auto;
    max-height: calc(100vh - 196px);
  }

  .contentInner2 {
    padding: 18px 12px 39px 16px;
  }
}

@media (min-width: 1024px) {
  .modal {
    height: auto;
    width: 960px;
    border-radius: 8px;
    overflow: hidden;
    max-height: calc(100vh - 80px);
  }

  .crossButton {
    width: 42px;
    height: 42px;
  }
  
  .header {
    height: 124px;
  }

  .footer {
    height: 132px;
    box-shadow: none;
  }

  .footer::before {
    left: 0px;
    right: 0px;
    z-index: -1;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 0px;
    box-shadow: 0 8px 32px rgb(0 0 0 / 25%);
    transition: height 300ms;
  }

  .footer.isShadow::before {
    height: 40px;
  }

  .content {
    margin-bottom: 132px;
  }
  .contentInner1 {
    overflow-y: auto;
    max-height: calc(100vh - 336px);
  }

  .contentInner2 {
    padding: 7px 40px 5px 48px;
  }
}

@media (min-width: 1440px) {
  .contentInner2 {
    padding: 12px 48px 5px 56px;
  }
}
