.paymentPreviewContainerClassName {
  padding: 0;
}

.paymentFormContainerClassName {
  padding: 0;
}

.footerButtons {
  padding: 0;
  position: fixed;
  height: 84px;
  left: 0;
  right: var(--cwrr-scroll-width, 0);
  bottom: 0px;
  background: #FFF;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
}

.footerButtons > div {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

}


  .footerButtons > div,
  .buttonsContainerInner > div  {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
    width: 100%;
    gap: 32px 16px;
  }
  .footerButtonNext {
    text-align: center;
  }
  .footerButtons > div > div > button:first-child,
  .buttonsContainerInner > div > button:first-child {
    order: 1;
    align-self: flex-start;
    margin-right: 0;
  }
  .footerButtons .footerButtonCancel{
    order: 2;
  }
  .footerButtonCancel {
    padding-right: 0;
  }

  .buttonsContainerInner {
    position: fixed;
    height: 84px;
    right: var(--cwrr-scroll-width, 0);
    left: 0;
    bottom: 0px;
    background: #FFF;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  }

  @media (min-width: 768px) {
    .buttonsContainerInner {
      height: 92px;
    }
  }
  
  @media (min-width: 1024px) {
    .paymentFormContainerClassName {
      padding: 0;
      padding-bottom: 20px;
    }
    .buttonsContainerInner {
      position: static;
      box-shadow: none;
      height: auto;
      background-color: none;
    }

    .footerButtons {
      padding-top: 20px;
      position: static;
      box-shadow: none;
      height: auto;
      background: none;
    }
  }