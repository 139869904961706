.wrapper {}

.main {
  display: flex;
}

.main :global(.cost) {
  color: var(--c-primary);
}

.title {
  margin-right: 36px;
}

.rentalTypeHeader {
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0;
}

.rentalTypeList.isRentalTypeChosen {
  display: flex;
}

.rentalTypeWrapper {
  display: block;
}

.rentalTypeRadio {
  padding: 12px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.rentalTypeWrapper:last-of-type {
  margin-right: 0;
}

.rentalTypeRadioHint {
  color: var(--c-tertiary-light);
  font-size: 16px;
  line-height: 24px;
  padding: 0 0 8px 32px;
}

.rentalTypeRadio{
  position: relative;
}

.rentalTypeRadio.isDisabled :global(.radio__txt) {
  color: var(--c-quaternary-dark)
}

.rentalTypeRadio.isDisabled :global(.radio__pseudo-check) {
  border-color: var(--c-quaternary-dark);
}

.rentalTypeRadioHint.isRentalTypeChosen {
  display: none;
}

@media (max-width: 1023.98px) {
  .mapContainer {
    order: -1;
    margin-top: -28px;
  }
  .mapInner {
    margin-right: -16px;
    margin-left: -16px;
  }
}

@media (min-width: 768px) {  
  .main {
    justify-content: center;
  }

  .title {
    margin-right: 0;
  }

  .rentalTypeHeader {
    font-size: 18px;
    line-height: 27px;
    padding: 16px 0 12px;
  }
}

@media (min-width: 1024px) {
  .main {
    justify-content: flex-start;
  }
}
