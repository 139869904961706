.card {
  display: block;
  height: 100%;
  border: 1px solid #c4cdd5;
  border-radius: 8px;
  cursor: pointer;
  padding: 4px 24px 8px;
  transition: all 200ms;
}
.cardChecked {
  border-color: transparent;
  background: rgba(80, 184, 60, 0.1);
  box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.25);
  cursor: auto;
}
.card:not(.cardChecked):hover {
  background: rgba(80, 184, 60, 0.05);
}
.insuranceOptionCard :global(p) {
  font-size: 16px;
  line-height: 24px;
  color: #212b36;
}

.insuranceOptionCard :global(strong)  {
  font-weight: 500 !important;
}

.insuranceOptionCard :global(ul) {
  margin-top: 24px;
  padding: 0 24px;
  margin-bottom: 24px;
  padding-left: 14px;
  list-style: none;
}
.insuranceOptionCard :global(ul > li) {
  font-size: 16px;
  line-height: 24px;
  color: #212b36;
  margin-top: 16px;
}
.insuranceOptionCard :global(ul > li):before {
  color: #000;
  content: "•";
  position: relative;
  left: -7px;
  font-size: 18px;
  margin-left: -7px;
}
.input {
  display: none;
}

.header {
  position: relative;
  padding-right: 40px;
}

.headerIconCheck {
  position: absolute;
  right: 0;
  top: 20px;
  width: 32px;
  height: 32px;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms;
}
.cardChecked .headerIconCheck {
  opacity: 1;
  visibility: visible;
}

.t-0 {
  padding-top: 0;
}

@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
  .card {
    padding-top: 9px;
  }

  .header {
    margin-bottom: 13px;
  }
}

@media screen and (min-width: 1440px){
  .header {
    margin-bottom: 12px;
  }
}