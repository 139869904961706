.avatar {
  position: relative;
  max-width: 136px;
}

.avatarImg {
  width: 100%;
  border-radius: 50%;
  border: 7px solid #fff;
}

.avatarBadgeContainer {
  display: flex;
  justify-content: center;
  margin-top: -23px;
  margin-bottom: -6px;
}

.avatarBadgeContent {
  width: 100px;
  height: 28px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: grid;
  place-content: center;
}
@media (min-width: 768px){
  .avatarImg {
    border: none;
  }
  .avatarBadgeContainer {
    margin-top: -15px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) and (max-width: 1439.98px) {
  .avatarBadgeContent {
    width: 58px;
  }
}
