.card {
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.card:hover {
  background-color: var(--c-quaternary-light);
}

.card.isActive {
  outline: 1px solid var(--c-primary);
}

.pin {
  cursor: pointer;
  position: absolute;
  background: #FFFFFF;
  border-radius: 4px;
  top: 12px;
  left: 12px;
  width: 42px;
  height: 42px;
  border: none;
  z-index: 1;
}

.pin:before {
  content: '';
  position: absolute;
  top: 11px;
  left: 11px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--c-tertiary);
}

.pin.isActive:before {
  border: 2px solid var(--c-primary);
}

.pin:after {
  content: '';
  position: absolute;
  top: 16px;
  left: 16px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid transparent;
  background-color: transparent;
}

.pin.isActive:after {
  border: 2px solid var(--c-primary);
  background-color: var(--c-primary);
}

.card:hover .pin {
  background: var(--c-quaternary-light);
}

.image {
  height: 216px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
}

.link {
  cursor: pointer;
  position: absolute;
  background: #FFFFFF;
  border-radius: 4px;
  top: 12px;
  right: 12px;
  width: 42px;
  height: 42px;
  border: none;
  color: var(--c-tertiary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkIcon {
  width: 24px;
  height: 24px;
}

.description {
  padding: 4px 16px 0px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.nameText {
  font-size: 16px;
  line-height: 24px;
  color: var(--c-black-cool);
  padding: 8px 0;
}

.location {
  display: none;
}

.price {
  margin-left: 12px;
  text-align: right;
  white-space: nowrap;
}

.priceText {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
}

.pricePeriod {
  margin-top: -12px;
  font-size: 12px;
  line-height: 18px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--c-charcoal);
}

.params {
  display: flex;
  flex-wrap: wrap;
}

.param {
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
}

.paramName {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0;
}

.paramValue {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 2.1px;
  padding: 0;
  font-weight: 700;
}

.param.class {
  width: 100%;
  margin-right: 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
}

.param.class .paramName {
  display: none;
}

.param.class .paramValue {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0;
  color: var(--c-charcoal);
}

.mobileLocation {
  background: rgba(196, 205, 213, 0.2);
}

@media (min-width: 768px) {
  .card {
    padding: 20px 24px 20px 20px;
    display: flex;
    outline: 1px solid var(--c-quaternary-dark);
    box-shadow: none;
  }

  .pin {
    position: relative;
    top: 0;
    left: 0;
    width: 20px;
    height: auto;
    align-self: stretch;
    border-radius: 0;
    padding: 0;
    margin-right: 20px;
    flex: none;
  }

  .pin:before {
    top: calc(50% - 10px);
    left: 0;
  }

  .pin:after {
    top: calc(50% - 5px);
    left: 5px;
  }

  .image {
    width: 230px;
    height: 172px;
    flex: none;
    margin-right: 32px;
  }

  .link {
    top: 16px;
    right: 16px;
  }

  .description {
    flex: 1 0 auto;
    padding: 0;
    max-width: calc(100% - 302px);
    overflow: hidden;
  }

  .header {
    display: block;
    flex: none;
  }

  .name {
    padding-right: 70px;
    max-width: 100%;
  }

  .nameText {
    padding: 0;
    font-size: 20px;
    line-height: 30px;
    color: var(--c-tertiary);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .location {
    display: block;
  }

  .locationText {
    font-size: 14px;
    line-height: 21px;
    padding: 4px 0;
    color: var(--c-tertiary);
  }

  .price {
    margin-left: 16px;
    flex: none;
    position: absolute;
    top: 20px;
    right: 24px;
  }

  .priceText {
    padding: 0;
    font-size: 20px;
    line-height: 30px;
    color: var(--c-tertiary);
  }

  .pricePeriod {
    margin-top: 0;
    font-size: 14px;
    line-height: 21px;
    color: var(--c-tertiary-light);
  }

  .params {
    padding: 24px 0;
    height: 64px;
    overflow: hidden;
    box-sizing: content-box;
    row-gap: 64px;
  }

  .param {
    padding: 0 24px;
    align-items: flex-start;
    margin-right: 0;
  }

  .paramName {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: var(--c-tertiary-light);
    padding-bottom: 8px;
  }

  .paramValue {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: var(--c-tertiary);
    padding-top: 8px;
  }

  .param.class {
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 24px;
  }

  .param.class .paramName {
    display: block;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: var(--c-tertiary-light);
    padding-bottom: 8px;
  }

  .param.class .paramValue {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: 700;
    color: var(--c-tertiary);
    padding-top: 8px;
  }

  .param + .param {
    border-left: 1px solid var(--c-quaternary);
  }
  
  .param:first-child {
    padding-left: 0;
  }

  .param:last-child {
    display: none;
  }

  .mobileLocation {
    display: none;
  }
}

@media (min-width: 1440px) {
  .param:last-child {
    display: flex;
    padding-right: 0;
  }
}
