.root :global(.btn--secondary):not(:hover) {
    background-color: transparent !important;
}

.baseText {
    color: var(--c-tertiary);
}

.helpText {
    color: var(--c-tertiary-light);
}

.helpText a {
    font-weight: 700;
    font-size: inherit !important;
}

.changes {
    border-left: 2px solid var(--c-quaternary-dark);
    padding-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.change:first-child .changeLabel {
    padding-top: 0;
}

.changeLabel {
    color: var(--c-tertiary-light);
}

.changeValue {
    margin-right: 16px;
    text-decoration: line-through;
}

.changeValue,
.changeNewValue {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.change:not(:last-child) {
    margin-bottom: 8px;
}
