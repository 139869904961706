.canvas {
  background: #c4cdd5 url("./icons/background.svg") repeat 50% 50%;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.imageCropContainer {
  padding: 16px;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .imageCropContainer {
    padding: 32px;
  }
}
@media (min-width: 1440px) {
  .imageCropContainer {
    padding: 60px;
  }
}
@media (min-width: 1920px) {
  .imageCropContainer {
    padding: 80px;
  }
}

.imageCropContainerOffset {
  position: relative;
  flex: 1 1 100%;
}

.toolbarContainer {
  flex-basis: 84px;
  flex-grow: 0;
  flex-shrink: 0;
  background: white;
}
@media (min-width: 768px) {
  .toolbarContainer {
    flex-basis: 92px;
  }
}

.imageCropPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imageCrop {
  display: block;
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.25));
}

.imageCrop :global(div:first-child) {
  width: 100%;
  height: 100%;
}
