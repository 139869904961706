
.modal {
  padding: 8px 16px 16px;
}
.crossButton {
  width: 42px !important;
  height: 42px !important;
}

@media (min-width: 768px) {
  .modal {
    max-width: 496px;
    width: 496px;
    margin: 0 auto;
    padding: 12px 56px 28px;
  }

  .crossButton {
    width: 52px !important;
    height: 52px !important;
  }
  
}