.destination {
  background: var(--c-grey-cool);
  border-radius: 8px;
}

.btnIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 12px;
}

.btnIcon svg {
  color: var(--c-tertiary-light);
}
.btnIcon:hover svg {
  color: var(--c-tertiary);
}

.inputUnits {
  composes: global(field-units);
  width: 62px;
  flex: 0 0 auto;
}

.destinationField {
  background: #fff;
}

.radio {
  margin-right: 0;
}

.tooltipQuestionContainer {
  width: 310px;
}
.tooltipQuestionContainerText {
  color: #fff;
}
@media (min-width: 768px) {
  .milesField {
    width: 164px;
  }
}

@media (min-width: 1440px) {
  .title {
    font-size: 18px;
    line-height: 27px;
  }

  .subTitle {
    font-size: 18px;
    line-height: 27px;
  }
}