.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.addDriverButton {
  font-weight: 500;
  min-width: max-content;
}

@media (max-width: 767.98px) {
  .titleMobile {
    font-size: 16px;
    line-height: 24px;
  }
}
