.imageWrap {
  margin-top: 16px;
  height: 52px;
}
@media (max-width: 767.98px) {
  .imageWrap {
    margin-top: 24px;
  }
}

.image {
  display: block;
  width: auto;
  height: 40px;
  max-height: 100%;
  margin: 0 auto;
}

.title {
  padding-top: 16px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
@media (min-width: 1024px) {
  .imageWrap {
    margin-top: 24px;
    height: 56px;
  }
  .image {
    height: 48px;
  }
}
@media (max-width: 767.98px) {
  .title {
    padding-top: 12px;
    padding-bottom: 4px;
    font-size: 14px;
    line-height: 21px;
  }
}
