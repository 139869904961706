.userInfoOuter {
  display: grid;
  grid-template-columns: 1fr;
}

.userInfoInner {
  display: grid;
  grid-gap: 0 16px;
  grid-template-columns: 62px 1fr;
}

@media (min-width: 768px) {
  .userInfoOuter {
    grid-template-columns: 50% auto;
    justify-content: space-between;
  }

  .userInfoInner {
    grid-template-columns: 112px 1fr;
  }
}

@media (min-width: 1024px) {
  .userInfoOuter {
    grid-template-columns: 1fr;
  }

  .userInfoInner {
    grid-gap: 0 24px;
    grid-template-columns: 60px 1fr;
  }

  .userInfoDateContainer {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 1440px) {
  .userInfoOuter {
    grid-template-columns: 50% auto;
    justify-content: space-between;
  }

  .userInfoInner {
    grid-template-columns: 86px 1fr;
  }

  .userInfoDateContainer {
    display: block;
  }
}

@media (min-width: 1680px) {
  .userInfoOuter {
    grid-template-columns: 50% auto;
    justify-content: space-between;
  }

  .userInfoInner {
    grid-template-columns: 100px 1fr;
    grid-gap: 0 36px;
  }

  .userInfoDateContainer {
    display: flex;
  }
}