.avatar {
  position: relative;
  width: 109px;
  height: 109px;
}

.avatarImg {
  border-radius: 50%;
  width: 100%;
}

.avatarBadgeContainer {
  position: relative;
  width: 100px;
  height: 28px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: -14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1023.98px) {
  .avatarBadgeContainer {
    margin-left: 5px;
  }
}

@media (min-width: 1024px) {
  .avatar {
    position: relative;
    width: 86px;
    height: 86px;
  }
  .avatarBadgeContainer {
    width: 86px;
    height: 28px;
    left: calc(50% - 43px);
  }

  .avatarBadge {
    width: 74px;
    height: 17px;
  }
}
