.items {
    overflow: hidden;
}

@media (max-width: 767.98px) {
    .card:not(:last-child) .listItem {
        border-bottom: 1px solid var(--c-grey);
    }

    .card:not(:first-child) .listItem {
        padding-top: 8px;
    }

    .listItem {
        padding-bottom: 32px;
    }
}
