
.StripeElement {
    cursor: text;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.StripeElement.masked:after {
    content: "••••";
    display: block;
    position: absolute;
    bottom: 5px;
    left: 10px;
    transition: opacity 300ms;
}

.StripeElement.masked.card:after {
    content: "•••• •••• •••• ••••";
}

.StripeElement iframe {
    transition: opacity 300ms;
}

.StripeElement--empty:not(.StripeElement--focus) iframe {
    opacity: 0;
}

.StripeElement--empty.StripeElement--focus.masked:after {
    opacity: 0;
}

.stripe-input__brand {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
}
