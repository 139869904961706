.root {
    composes: global(mb-120);
}

.buttonsPosition {
    composes: global(t-20);
    position: absolute;
    bottom: 28px;
    left: 0;
    padding-bottom: 0;
}

.footerButtonsPosition button:not(:last-child) {
    margin-right: 16px !important;
}

.footerButtonsPosition {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}
.buttonsContainerInner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0 16px
}
.shadowContainer {
    display: none;
}
@media (min-width: 1024px) {
    /* .root {
        overflow: auto;
        height: calc(100vh - 300px);
    } */
    
    .footerButtonsPosition {
        justify-content: left;
    }
    .buttonsContainerInner {
        justify-content: right;
    }

}
@media (max-width: 767.98px) {
    .footerButtonsPosition {
        width: auto;
    }
    .buttonsPosition {
        justify-content: center;
    }
    .buttonsContainerInner {
        justify-content: center;
    }
}

@media (max-width: 1023.98px) {
    .buttonsPosition {
        position: fixed;
        left: 0;
        right: var(--cwrr-scroll-width, 0);
        bottom: 0;
        padding: 16px;
        background: #FFFFFF;
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
        display: flex;
        grid-gap: 0 16px;

    }
    .buttonsContainerInner {

    }
    .footerButtonsPosition {
        display: flex;
        width: 100%;
    }

    .footerButtonsPosition {
        display: flex;

    }

    .footerButtonsPosition button:not(:last-child) {
        margin-right: 16px !important;
    }
}
@media (min-width: 768px) {
    .buttonsPosition {
        justify-content: right;
    }
    .buttonsContainerInner {
        justify-content: right;
    }
    .footerButtonsPosition {
        width: auto;

    }
    .footerButtonsPositionPaymentOptions {
        justify-content: space-between;
        width: 100%;
    }

}
@media (min-width: 1024px) and (max-height: 729.98px) {
    .buttonsPosition {
        position: fixed;
        left: 0;
        right: var(--cwrr-scroll-width, 0);
        bottom: 0;
        padding: 16px;
        background: #FFFFFF;
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: right;
        align-items: center;
        z-index: 1;
    }
    .buttonsContainerInner {
        width: 738px;
        margin: 0 auto;
        display: flex;
        justify-content: right;
        gap: 0 32px;
    }
    .footerButtonsPosition {
        width: 738px;
        margin: 0 auto;
        display: flex;
        justify-content: right;
        gap: 0 32px;
    }
    .footerButtonsPosition {
        display: flex;
        justify-content: right;
    }

    .buttonsPosition button:not(:last-child) {
        margin-right: 0px !important;
    }
}
@media (min-width: 1024px) and (min-height: 730px) {
    .root {
        margin: 0;
        margin-bottom: 31px;
    }
    .root.isPaymentMethodStep {
        margin-bottom: 20px;
    }
    .buttonsContainerInner {
        gap: 0 32px;
        justify-content: right;
        margin-right: 6px;
    }
    .buttonsPosition {
        position: absolute;
        left: 0;
        right: 0px;
        bottom: -89px;
        padding: 0 16px;
        background: #FFFFFF;
        box-shadow: none;
        height: 104px;
        gap: 0 32px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 0px;
    }
    .footerButtonsPosition {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0 32px;
        margin-top: -15px;
    }
    .footerButtonsPosition {
        display: flex;
        justify-content: flex-end;
        gap: 0 32px;
        margin-right: 7px;
        margin-left: 16px;
    }

    .hasScrollShadow .shadowContainer {
        display: block;
        position: absolute;
        top: -50px;
        left: 0;
        height: 50px;
        right: 0px;
        left: 0px;
        overflow: hidden;
        pointer-events: none;
    }

    .hasScrollShadow .shadowContainer::after {
        content: '';
        position: absolute;
        top: 50px;
        left: 32px;
        right: 32px;
        height: 104px;
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
        z-index: 1;
    }
    .buttonsPosition.hasScrollShadow::before {
        position: absolute;
        top: -80px;
        left: 0;
        right: var(--cwrr-scroll-width, 0);
        height: 80px;
        content: '';
        background: linear-gradient(1deg, rgba(255, 255, 255, 0.7) 0.89%, rgba(255, 255, 255, 0) 99.11%);
        pointer-events: none;
    }
    .buttonsPosition.hasScrollShadow {

    }
    .buttonsPosition button:not(:last-child) {
        margin-right: 0 !important;
    }
}