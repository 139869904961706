.collapse {
  margin: 12px 0;
}
.collapseHide .collapseContent {
  height: 0px;
  overflow: hidden;
}
.insuranceOptionCard :global(p) {
  font-size: 16px;
  line-height: 24px;
  color: #212b36;
}
.insuranceOptionCard :global(ul) {
  margin-top: 24px;
  padding: 0 24px;
  margin-bottom: 24px;
  padding-left: 14px;
  list-style: none;
}
.insuranceOptionCard :global(ul > li) {
  font-size: 16px;
  line-height: 24px;
  color: #212b36;
  margin-top: 16px;
}
.insuranceOptionCard :global(ul > li):before {
  color: #000;
  content: "•";
  position: relative;
  left: -7px;
  font-size: 18px;
  margin-left: -7px;
}
@media (max-width: 767px) {
  .collapse {
    margin: 8px 0;
  }
}

.collapseHeader {
  position: relative;
  min-height: 56px;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collapseRadio {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.collapseRadio input {
  margin-right: 0;
}
@media (max-width: 767px) {
  .collapseHeader {
  }
}

.collapseHeader::after {
  content: "";
  display: block;
  width: 56px;
  height: 56px;
  background-image: url("./chevron-down.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 200ms;
}
.collapseActive .collapseHeader::after {
  transform: rotate(180deg);
}

.collapseHeader :global(.radio__pseudo-check) {
  top: 12px;
  left: 0;
  margin: 0;
}

.label {
  font-size: 18px;
  line-height: 27px;
  padding-left: 37px;
}
@media (max-width: 767px) {
  .labelRight {
    position: relative;
    z-index: 1;
    font-size: 16px;
    line-height: 24px;
    width: auto;
    cursor: pointer;
    padding-right: 56px;
  }
}
.labelRight {
  position: relative;
  z-index: 1;
  width: auto;
  cursor: pointer;
  padding-right: 56px;
  padding-left: 0;
}
.collapseContent {
  padding-left: 36px;
  padding-right: 16px;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: all 200ms;
}
.collapseActive .collapseContent {
  opacity: 1;
  visibility: visible;
  max-height: 100%;
}

.collapseContent :global(.list) {
  padding-top: 7px;
}
@media (max-width: 767px) {
  .collapseContent :global(.list) {
    padding: 8px 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .collapse {
    margin: 14px 0;
  }
}