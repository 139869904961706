.popperRoot {
  position: absolute !important;
}

.clearButton {
  background: var(--widget-card-popup-color, #fff);
  color: var(--widget-checkbox-color, #23410A) !important;
}
.actionBar {
  margin-top: 10px;
}
.button {
  background: var(--widget-background-color, #fff);
  border: 1px solid  var(--widget-text-color, #474342);
  display: flex;
    flex: 1 1;
    width: 100%;
}

.button span {
  color: var(--widget-text-color, #474342);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.button:hover, .button:focus {
  background: var(--widget-pressed-button-color, #E6E6E6);
  border-color: var(--widget-text-color, #474342) !important;
}

.button:hover, .button:focus span {
  color: var(--widget-text-color, #474342);
}

.button.buttonDarkTheme svg path {
  fill: white;
}

.clearIcon {
  height: 16px;
  margin-top: -6px;
}
