.modalEditReview {
    max-height: 100%;
    overflow-y: auto;
}
.modalEditReview:before {
    display: none !important;
}

.myReviews__mutedText {
    color: #757272;
}

.myReviewsReview {
    border-bottom: 1px solid rgba(196, 205, 213, 0.5);
}

.crossButton {
    position: fixed;
    right: var(--cwrr-scroll-width, 0);
}

.canChange {
    cursor: pointer;
}
@media (min-width: 1440px) {
    .myReviewsReview {
        display: flex;
        flex-direction: column;
    }

    .myReviewsReviewEmpty {
        display: flex;
        flex-direction: column;
    }

}

.reviewContent__img {
    width: 100%;
    border-radius: 50%;
}

.reviewContent__rating {
    border-left: 1px solid #C4CDD5;
    border-right: 1px solid #C4CDD5;
}

.photosList {
    list-style: none;
    padding: 0;
}

.photosList__item,
.photosList__item_last {
    cursor: pointer;
}

.photosList__item img,
.photosList__item_last img {
    border-radius: 4px;
    min-height: 46px;
}

.photosList__item_last p {
    background: #EAECEF;
    color: #757272;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-top: 10px;
}

.photosList__aspectRatioBox, 
.photosList__aspectRatioBox_last {
    aspect-ratio: 4 / 3;
    position: relative;
}
.photosList__aspectRatioBox_last {
    width: 100%;
}
.photosList__photoLast {
    opacity: 0;
}

.photosList__photo, 
.photosList__photoLast {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center; 
}
 
.editReview__rating {
    display: flex;
    flex-direction: column;
    align-items:center;
}

@media (min-width: 768px) {
    .editReview__rating {
        flex-direction: row;
        align-items:center;
        justify-content: flex-start;
    }
}

.editReview__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: var(--cwrr-scroll-width, 0);
  z-index: 999;
  background: #ffffff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
}

.editReview__footerContent {
    display: flex;
  justify-content: center;
  align-items: center;
  composes: global(t-16) global(b-16) global(t-m-20) global(b-m-20);
}

@media (min-width: 768px) {
    .editReview__footerContent {
      justify-content: flex-end;
    }
}

.inputRating {
    width: 100%;
    justify-content: space-around;
}

@media (min-width: 500px) {
    .inputRating {
        width: 80%;
    }
}

@media (min-width: 768px) {
    .inputRating {
        width: auto;
    }
}


.ratingStar {
    width: 40px;
    height: 40px;
    /* background-size: contain; */
    background-repeat: no-repeat;
    background-position: 0 0;
    padding: 0 4px;
}

.ratingStar__full {
    background-image: url("./star-full.svg");
    background-size: 40px 40px;
}

.ratingStar__empty {
    background-image: url("./star-empty.svg");
    background-size: 40px 40px;
}

@media (min-width: 768px) {
    .ratingStar {
        width: 24px;
    height: 24px;
    }

    .ratingStar__full {
        background-image: url("./star-full.svg");
        background-size: 24px 24px;
    }
    
    .ratingStar__empty {
        background-image: url("./star-empty.svg");
        background-size: 24px 24px;
    }
}

.ratingStar:first-child {
    padding-left: 0;
}


.slickArrow {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 68px;
    height: 68px;
    padding-left: 0;
    padding-right: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2OCA2OCI+DQogIDxjaXJjbGUgY3g9IjM0IiBjeT0iMzQiIHI9IjMzLjUiIGZpbGw9IiNmZmYiIHN0cm9rZT0iI0M0Q0RENSIvPg0KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTM3LjUxNyAyNS4xODRhMS42NiAxLjY2IDAgMDAtMi4zNSAwbC03LjY1IDcuNjVjLS42NS42NS0uNjUgMS43IDAgMi4zNWw3LjY1IDcuNjVjLjY1LjY1IDEuNy42NSAyLjM1IDAgLjY1LS42NS42NS0xLjcgMC0yLjM1TDMxLjA1IDM0bDYuNDY3LTYuNDY3Yy42NS0uNjUuNjMzLTEuNzE3IDAtMi4zNXoiIGZpbGw9IiM2NTZENzYiLz4NCjwvc3ZnPg0K);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    font-size: 0;
    line-height: 0;
    border: none;
    opacity: 1;
    outline: none;
    left: 0;
}

.slickArrowNext {
    transform: rotate(
180deg
);
right: 0;
left: auto;
}

@media (max-width: 767.98px) {
    .myReviews {
        width: 100%;
    }

    .reviewContent_mobile .reviewContent__imgContainer{
        width: 60px;
        height: 60px;
    }
    .reviewContent_small__headerName {
        padding-top: 0;
    }

    .myReviews__mutedText {

    }

    .editReview__textarea textarea {
        min-height: 124px;
    }
    .photoGalleryContainer {
        margin-top: -16px;
        margin-bottom: 32px;
    }

}
@media (min-width: 768px) {
    .crossButton {
        width: 52px;
        height: 52px;
    }
    .editReview__textarea textarea {
        min-height: 196px;
    }
    .myReviewsReview {
        border: none;
    }
    .review__contentPhotos {
        margin-left: -8px;
    }
    .reviewContent__imgContainer{
        width: calc(100% - 16px);
        min-width: 109px;
        min-height: 109px;

    }

    .editReview__rating {
        margin-top: -6px;
    }

    .editReview__ratingTitle {
        font-size: 18px;
    }
    .photosList__item img,
    .photosList__item_last img {
        min-height: 83px;
    }
    .photoGalleryContainer {
        margin-top: -6px;
        margin-bottom: 32px;
        padding-top: 10px;
    }
    
}
@media (min-width: 1024px) {
    .reviewContent__imgContainer{
        width: calc(100% - 24px);
        min-width: 58px;
        min-height: 58px;
    }

    .editReview__rating {
        margin-top: -7px;
    }
    .review__contentPhotos {
        margin-left: -12px;
    }
    .photosList__item img,
    .photosList__item_last img {
        min-height: 44px;
    }

    .photoGalleryContainer {
        margin-top: -2px;
        margin-bottom: 16px;
        padding-top: 6px;
        padding-bottom: 16px;
    }
}

@media (min-width: 1440px) {
    .reviewContent__imgContainer{
        width: 86px;
        height: 86px;
    }

    .review__contentWrap {
        padding-right: calc(8.3333333333% + 8px);
    }

    .review__contentPhotos {
        margin-left: 94px;
    }
    .photosList {
        margin: 0 -12px;
    }
    .photosList__item,
    .photosList__item_last {
        flex: 0 0 14.75%;
        max-width: 14.75%;
    }
    .photosList__item img,
    .photosList__item_last img {
        min-height: 65px;
    }

    .photoGalleryContainer {
        margin-top: -2px;
        margin-bottom: 16px;
        padding-top: 6px;
        padding-bottom: 16px;
    }

}

@media (min-width: 1680px) {
    .reviewContent__imgContainer{
        width: calc(100px);
        min-width: 100px;
        min-height: 100px;

    }
    .review__contentPhotos {
        margin-left: 96px;
    }

    .review__contentWrap {
        padding-right: calc(8.3333333333%  + 32px);
    }


    .editReviewHeader {
        margin: 0 auto;
        width: 1352px;
    }

    .editReviewTitle {
        margin-left: -16px;

    }
    .reviewContent__imgContainer{
        width: calc(100px);
        min-width: 100px;
        min-height: 100px;

    }

    .review__contentWrap {
        padding-right: calc(16.66666% - 40px);
        padding-left: 0;
    }

    .photosList__item,
    .photosList__item_last {
        flex: 0 0 100px;
        max-width: 100px;
        padding: 0;
        margin-left: 32px;
    }


    .photosList__item:first-child {
        margin-left: 0;
    }
    .photosList__item img,
    .photosList__item_last img {
        min-height: 75px;
    }

    .editReview__footerContent {
        margin: 0 auto;
    }
    .submitReviewBtn {
        margin-right: -29px;
    }
}