.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 70px 24px 20px;
  border: 1px solid var(--c-grey);
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
}

.isActive {
  border: 2px solid var(--c-primary);
}

.label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  padding: 12px 24px 11px;
  background-color: var(--c-primary);
  text-align: center;
}

.labelText {
  padding: 0 !important;
  color: #fff;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.valueText {
  color: var(--c-primary, #23410A);
}

.fw700 {
  font-weight: 700 !important;
}

.paddingReset {
  padding: 0 !important;
}
@media (max-width: 767.98px) {
  .root {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .rootWithLabel {
    padding-top: 66px;
  }
}
@media (max-width: 1023.98px) {
  .root {
    padding-top: 20px;
  }
  .rootWithLabel {
    padding-top: 70px;
  }
  .label {
    padding: 10px 24px 11px;
  }
  .labelText {
    font-size: 18px;
    line-height: 27px;
  }
  .fw700 {
    font-size: 18px;
    line-height: 27px;
    padding-top: 10px;
  }
  .valueText {
    font-size: 32px;
    line-height: 48px;
    margin-top: 4px;
  }
  .goodsamMembershipOptionContentDescription p {
    font-size: 16px;
    line-height: 24px;
  }
  .goodsamMembershipOptionContentButton {
    padding-bottom: 14px;
  }
}
@media (min-width: 1024px) {
  .goodsamMembershipOptionContent {
    margin-top: -1px;
  }
  .labelText {
    margin-top: -1px;
  }
  .valueText {
    font-size: 32px;
    line-height: 48px;
    margin-top: -1px;
    padding-bottom: 0;
  }
  .goodsamMembershipOptionContentDescription {
    margin-top: -7px;
    margin-bottom: -9px;
  }
  .goodsamMembershipOptionContentButton {
    padding-bottom: 18px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .fw700 {
    padding-top: 13px;
  }
  .valueText {
    margin-top: 1px;
  }
  .goodsamMembershipOptionContentDescription {
    margin-top: -7px;
    margin-bottom: -9px;
  }
  .goodsamMembershipOptionContentButton {
    padding-bottom: 10px;
  }
}
