.container {
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 0 12px;
}

.icon svg {
  width: 24px;
  height: 24px;
}

.comment {
  padding-top: 0;
}
