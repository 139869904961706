.wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.container {
  display: flex;
  justify-content: center;
}

.left {
  align-self: stretch;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  padding: 0 16px;
}

.actions {
  display: flex;
  justify-content: center;
}

.button {
  margin-right: 16px;
}

.button:last-child {
  margin-right: 0px;
}

.spinner {
  width: 52px;
  height: 52px;
}

.spinner :global(.spinner__icon) {  
  background-size: contain;
}

@media (max-width: 767.98px) {

  /* styles for footer step period */
  .wrapper.wrapperStepPeriod {
    height: 84px;
  }

  .wrapper.wrapperStepPeriodFullHeight {
    height: 132px;
  }

  .left.leftStepPeriod {
    bottom: auto;
    top: 21px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  .right.rightStepPeriod {
    position: absolute;
    width: 100%;
    bottom: 0px;
    display: flex;
    justify-content: center;
  }

  /* end styles for footer step period  */
}

@media (min-width: 768px) {
  .container {    
    justify-content: space-between;
  }

  .left {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .button {
    margin-right: 24px;
  }
}

@media (min-width: 1680px) {
  .button {
    margin-right: 32px;
  }
}
