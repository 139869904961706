.brand {
  position: absolute;
  top: 35px;
  right: 20px;
}

.header {
  background: var(--c-grey-cool, #EEE);
}

.title {
  color: var(--c-tertiary-light);
}

.subtitle {
  color: var(--c-tertiary-light);
  font-weight: 400;
}

.cardNumber {
  padding-top: 0 !important;
  display: flex;
  align-items: center;
  margin-left: -2px;
  margin-right: -2px;
}

.holderLabel {
  color: var(--c-tertiary-light);
  font-weight: 400;
}

.expiresLabel {
  color: var(--c-tertiary-light);
  font-weight: 400;
}

.expiresNumbers {
  font-weight: 500;
  padding-top: 14px;
}

.expired,
.expiredNumbers {
  color: var(--c-status-error-dark, #D0021B);
}

.bookingProcess.footerButtonsContainer .footerButtonsContainerInner button:not(:last-child) {
  margin-right: 16px;
}

@media (width >= 768px) {
  .cardNumber {
    margin-left: -28px;
    margin-right: -28px;
  }
}
@media (width >= 1024px) {
  .cardNumber {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.cardNumberGroup,
.cardNumberGroupMiddle {
  margin-left: 2px;
  margin-right: 2px;
  flex: 0 0 auto;
  width: 59px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--c-tertiary-light);
}
@media (width >= 768px) {
  .cardNumberGroup,
  .cardNumberGroupMiddle {
    width: 93px;
    margin-right: 28px;
    margin-left: 28px;
  }
}
@media (width >= 1024px) {
  .cardNumberGroup,
  .cardNumberGroupMiddle {
    margin-right: 16px;
    margin-left: 16px;
  }
}
@media (width >= 1680px) {
  width: 117px;
}

.bottomValue {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.expires {
  align-items: flex-end;
}

.expired,
.expired .bottomValue {
  color: var(--c-status-error-dark);
}

.expiredIcon {
  width: 20px;
  height: 20px;
  background: url(../Icon/svg/error.svg) 0 0 no-repeat;
  background-size: contain;
}

.compact .card {
  border: 1px solid var(--c-grey);
  box-shadow: none;
}

@media (min-width: 768px) {
  .brand {
    top: 44px;
    right: 64px;
    width: 87px;
    height: 54px;
  }

  .compact .brand {
    right: 48px;
  }
}

@media (max-width: 767.98px) {
  .cardNumber {
    font-size: 20px;
    padding-bottom: 0;
    margin-bottom: 12px;
    gap: 0 8px;
    height: 30px;
    margin-left: 1px;
    margin-right: 1px;
  }
.pointContainer {
  margin: 0;
  display: inline-block;
  letter-spacing: 3.6px;
}
  .cardNumberGroup > span {
    font-size: 18px;
  }
  .numberContainer {
    letter-spacing: 3.6px;
    display: inline-block;
    margin: 0;
    width: auto;
    flex: none;
  }

  .footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .expires {
    align-items: flex-start;
    text-align: left;
    margin-top: 12px;
  }

  .expired {
    flex-direction: row-reverse;
  }

  .expiredIcon {
    margin-left: 10px;
    margin-right: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1919.98px) {
  .brand {
    right: 50px;
  }
}
@media (min-width: 1440px) {
  .brand {
    top: 51px;
    right: 48px;
  }
}
@media (min-width: 1680px) {
  .brand {
    right: 64px;
  }
  .cardNumberGroup {
    width: 93px;
  }
  .cardNumberGroupMiddle {
    width: 126px;
  }
}

@media (min-width: 1440px) {
  .expiredIcon {
    width: 26px;
    height: 26px;
  }
}
.bookingProcess.footerButtonsContainer .footerButtonsContainerInner {
  align-items: center;
  justify-content: center;
  display: flex;
}
@media (min-width: 768px) {
  .bookingProcess.footerButtonsContainer .footerButtonsContainerInner {
    align-items: center;
    justify-content: right;
    display: flex;
  }
}
@media (min-width: 1024px) and (max-height: 729.98px) {
  .bookingProcess.footerButtonsContainer .footerButtonsContainerInner {
    align-items: center;
    justify-content: right;
    display: flex;
    width: 738px;
    margin: 0 auto;
    gap: 0 32px;
  }
}

@media (min-width: 1024px) and (min-height: 730px) {
  .bookingProcess.footerButtonsContainer .footerButtonsContainerInner {
    align-items: center;
    justify-content: right;
    display: flex;
    gap: 0 32px;
    margin-right: 16px;
  }

  .bookingProcess.footerButtonsContainer.hasScrollShadow::before {
    position: absolute;
    top: -80px;
    left: 0;
    right: var(--cwrr-scroll-width, 0);
    height: 80px;
    content: '';
    background: linear-gradient(1deg, rgba(255, 255, 255, 0.7) 0.89%, rgba(255, 255, 255, 0) 99.11%);
    pointer-events: none;
  }
}