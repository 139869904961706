.crossButton {
    width: 42px !important;
    height: 42px !important;
}

@media (min-width: 768px) {
    .modal {
        max-width: 530px;
    }

    .crossButton {
        width: 52px !important;
        height: 52px !important;
      }
}
