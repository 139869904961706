.container {
  border-top: 1px solid rgba(196, 205, 213, 0.5);
}

.instantRentalsOnly {
  font-weight: bold;
  margin-top: 8px;
}

.checkboxTxt {
  padding-top: 8px;
  padding-bottom: 8px;
}

.check {
  top: 7px;
}