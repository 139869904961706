.listItem {
  background: var(--c-grey-cool, #eee);
  border-radius: 8px;
  width: 100%;
}

.headerLine {
  display: flex;
  justify-content: space-between;
}

.names {
  overflow: hidden;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--c-tertiary);
  padding-bottom: 0;
}

.name.isOwner {
  color: var(--c-primary);
}

.tagContainer {
  flex-shrink: 0;
}

.deleteIcon {
  flex-shrink: 0;
  margin-top: 7px;
  cursor: pointer;
}

.emailContainer {
  width: 100%;
}

.deleteIcon svg {
  width: 24px;
  height: 24px;
}

.email {
  color: var(--c-tertiary-light);
  padding-top: 0;
}

.tag {
  margin-left: 0;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .names {
    flex-grow: 1;
  }
  .headerLine {
    display: flex;
    justify-content: flex-start;
  }

  .tagContainer {
    margin-top: 15px;
    margin-right: 8px;
  }

  .deleteIcon {
    margin-top: 17px;
  }
}

@media (min-width: 1024px) {
  .listItem {
    width: calc(50% - 12px);
  }

  .tagContainer {
    padding-left: 16px;
  }
}

@media (min-width: 1680px) {
  .listItem {
    width: calc(50% - 16px);
  }
}
