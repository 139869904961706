.BookingWidgetBarDefault {
  display: flex;
  align-items: center;
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  height: 92px;
  padding: 20px 16px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  background: #fff;
}

.bookingWidgetBarDefaultLeftColumn {
  margin-right: 16px;
  padding-right: 16px;
}

.bookingWidgetBarDefaultRightColumn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bookingWidgetBarDefaultTotal {
  display: flex;
  align-items: baseline;
  margin-right: 12px;
  composes: global(subtitle--l);
  margin-top: -6px;
}

.bookingWidgetBarDefaultTotalText {
  margin-left: 8px;
  composes: global(body--l);
  white-space: nowrap;
}
.bookingWidgetBarDefaultTotalTextMin {
  display: none;
  composes: global(body--l);
}

.bookingWidgetBarDefaultDurationMax,
.bookingWidgetBarDefaultDurationMin {
  color: var(--c-tertiary-light, #7F7F7F);
  composes: global(body--s);
}

.bookingWidgetBarDefaultDuration {
  display: flex;
  white-space: nowrap;
}
.bookingWidgetBarDefaultDurationMin {
  display: none;
}

.bookingWidgetBarDefaultDurationMax {
  position: relative;
  top: -18px;
}

.bookingWidgetBarDefaultButton {
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 767.98px) {
  .BookingWidgetBarDefault {
    height: 84px;
    padding: 16px 16px;
  }
  .bookingWidgetBarDefaultLeftColumn {
    border-right: none;
    width: 100%;
    min-width: 110px;
    padding: 0;
    margin: 0;
  }

  .bookingWidgetBarDefaultRightColumn {
    width: auto;
  }

  .bookingWidgetBarDefaultTotal {
    order: -1;
    margin-left: 0;
    width: 100%;
    padding-bottom: 0;
    margin-top: -24px;
  }

  .bookingWidgetBarDefaultTotalText {
    display: none;
    white-space: normal;
  }

  .bookingWidgetBarDefaultTotalTextMin {
    display: block;
    margin-left: 4px;
    padding-bottom: 0;
  }

  .bookingWidgetBarDefaultDuration {
    margin-top: -6px;
  }

  .bookingWidgetBarDefaultDurationMax {
    display: none;
  }
  .bookingWidgetBarDefaultDurationMin {
    position: relative;
    bottom: -7px;
    display: block;
  }
}
