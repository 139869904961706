.photoCover {
  height: 216px;
  position: relative;
}
.photoCoverInner {
  height: 100%;
}
.photoCoverSpinner {
  z-index: 1;
}
.photoCoverImageContainer {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.photoCoverImage {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: block;
}

.photoCoverImage img,
.photoCoverImage picture {
  object-fit: cover;
  width: 100%;
  height: 216px;
}
.photoCoverImage div {
  height: 216px;
}

@media screen and (max-width: 767.98px) {
  .photoCover :global(.photoUploadAreaInner2) {
    height: 216px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .photoCover {
    height: 160px;
  }

  .photoCoverImage img,
  .photoCoverImage picture {
    height: 160px;
  }

  .photoCoverImage div {
    height: 160px;
  }
}
