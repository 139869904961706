.notesContent {
  color: #757272;
  padding-top: 0;
}
.notesContent sup {
  font-size: 8px;
  display: inline-block;
  margin-bottom: -4px;
}
.notesRow:first-child .notesContent {
  padding: 4px 0 0;
}

