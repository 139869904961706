.pickUpDelevery {
  width: 100%;
  cursor: pointer;
}
.pickUpDeleveryNoClick {
  width: 100%;
  cursor: text;
}

.label {
  display: flex;
  justify-content: space-between;
}

.pencil {
  margin-right: 2px;
}

.title {
  composes: global(body--l);
  font-weight: 500;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.fullAddress {
  composes: global(body--l);
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fullAddress:hover {
  color: var(--c-primary-light);
}

.tooltip {
  width: 174px;
}
@media screen and (min-width: 320px) and (max-width: 567.98px) {
  .fullAddress {
    margin-top: 11px;
  }
}
@media screen and (min-width: 568px) and (max-width: 1023.98px) {
  .fullAddress {
    margin-top: 12px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
  .fullAddress {
    margin-top: 8px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1679.98px) {
  .fullAddress {
    margin-top: 4px;
  }
}
@media screen and (min-width: 1680px) {
  .fullAddress {
    margin-top: 8px;
  }
}
