.header {
  display: flex;
  width: 100%;
}

.headerRow {
  flex: 1 0 auto;
  display: flex;
}

.headerRow:last-child {
  width: 232px;
  flex: none;
  justify-content: flex-end;
  margin-left: 32px;
}

.empty {
  font-weight: 400;
}

.headerText {
  color: var(--c-tertiary-light);
  display: flex;
}

.btnIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 12px;
}

.btnIcon svg {
  color: var(--c-tertiary-light);
}

.btnIcon:hover svg {
  color: var(--c-tertiary);
}

.tooltipQuestionContainer {
  width: 310px;
}
.tooltipQuestionContainerText {
  color: #fff;
}

@media (max-width: 767px) {
  .headerRow:last-child {
    width: auto;
  }
}