
.modal {
  z-index: 1;
  padding-left: 16px;
  padding-right: 16px;
}

.crossButton {
  width: 52px;
  height: 52px;
}

@media (min-width: 768px) {
  .modal {
    padding: 12px 56px 28px;
    max-width: 496px;
    width: 496px;
  }

  .crossButton {
    width: 42px;
    height: 42px;
  }
}
