.formDescCommonStyle {
  composes: global(body--s);
  color: var(--c-tertiary-light);
}

.formDescHtmlStyle {
  composes: global(body--s);
  color: var(--c-tertiary-light);
}

.formDescHtmlStyle a {
  font-size: inherit !important;
  text-decoration: var(--link-underline-text-decoration);
  text-decoration-thickness: var(--link-underline-text-decoration-thickness);
  text-underline-offset: var(--link-underline-text-underline-offset);
  text-decoration-skip-ink: var(--link-underline-text-decoration-skip-ink);
}

.formDescHtmlStyle a:hover {
  color: var(--c-primary-light);
}

.formDescTagDesc {
  composes: global(body--l);
  color: var(--c-tertiary);
  font-weight: bold;
}

.bodyLBold {
  font-weight: bold;
  composes: global(body--l);
}

.colorTextBodyLight {
  color: var(--c-tertiary-light);
}

.desc {
  border-top: 1px solid #C4CDD5;
  margin-top: 8px;
  padding-top: 8px;
}


@media screen and (min-width: 768px) {
  .desc::before {
    margin-top: 12px;
    padding-top: 12px;
  }
}
