.modalWrap {
  height: 100%;
  background: #fff;
}

.modal {
  box-shadow: none;
  width: 100vw;
  min-height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  max-height: 100%;
  overflow-y: auto;
  background: none;
}

.headActionTagCommonStyle,
.headActionPlainCommonStyle,
.headPlainCommonStyle,
.headTagCommonStyle,
.subTitleL {
  font-size: 20px;
  line-height: 30px;
  padding: 20px 0 12px;
  white-space: nowrap;
  font-weight: 500;
}

.regularActionPlainCommonStyle,
.regularPlainCommonStyle,
.regularActionTagCommonStyle,
.regularTagCommonStyle {
  composes: global(body--l);
  white-space: nowrap;
}

.bodyM {
  composes: global(body--m);
}
.bodyMBold {
  font-weight: bold;
}

.colorTextBodyLight {
  color: var(--c-tertiary-light);
}
.colorTextBody {
  color: var(--c-tertiary);
}

.statusSuccess {
  color: var(--c-status-success-dark);
}

.primary {
  color: var(--c-primary);
}

.action {
  cursor: pointer;
}

.headActionPlainCommonStyle,
.regularActionPlainCommonStyle,
.regularActionTagCommonStyle,
.headActionTagCommonStyle {
  color: var(--c-primary);
  white-space: nowrap;
}

@media screen and (min-width: 1440px) {
  .headActionTagCommonStyle,
  .headActionPlainCommonStyle,
  .headPlainCommonStyle,
  .headTagCommonStyle,
  .subTitleL {
    font-size: 24px;
    line-height: 36px;
    padding: 24px 0 12px;
  }
}
