.header {
  position: relative;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .header__content {
    position: static;
  }
}
.header__link {
  composes: global(df);
  position: absolute;
  top: 0;
  padding: 1px 0;
  color: var(--primary, #23410A);
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  font-style: normal;
  border: none;
}
@media (width >= 768px) {
  .header__link {
    display: none;
  }
}

.header__link:hover .header__icon path {
  fill:  var(--primary, #23410A);
}

.header__icon {
  margin-right: 8px;
}

@media (min-width: 768px) {
  .header {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {
  :global(.myAccount_step-view .title-main) {
    visibility: hidden;
  }
  :global(.myAccount_step-view .header-main) {
    height: 26px;
  }
}
