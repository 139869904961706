.subItemsRow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 0px 16px;
}

.valueColumn {
  justify-content: flex-end;
  display: flex;
  align-items: flex-start;
}

.subCommonStyle {
  color: var(--c-tertiary);
  composes: global(body--l);
}
