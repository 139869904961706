.questionTooltipContainer {
  max-width: 210px;
}
.questionTooltipContainer div,
.questionTooltipContainer p {
  color: #fff !important;
}
.iconQuestionContainer {
  display: inline-flex;
}

.iconQuestionContainer svg,
.iconQuestionContainer path {
  fill: var(--c-tertiary-light);
  color: var(--c-tertiary-light);
}

.iconQuestionContainer:hover svg,
.iconQuestionContainer:hover path {
  fill: var(--c-tertiary);
  color: var(--c-tertiary);
}

.btn {
  width: 24px;
  height: 24px;
  color: #757272;
}
.btn:hover {
  color: #474342;
}
.btn:focus {
  color: #c1122a;
}

.btnIcon {
  width: 24px;
  height: 24px;
  color: inherit;
  cursor: pointer;
}
