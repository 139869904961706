.discountContainer {
  display: inline-flex;
  align-items: center;
}

.discount {
  display: inline-flex;
  font-size: 16px;
  line-height: 28px;
  padding: 0px 8px;
  border-radius: 4px;
  color: #fff;
  background: var(--c-status-success-dark);
  margin-right: 16px;
}

.discountPrice {
  font-size: 20px;
  line-height: 30px;
  color: var(--c-tertiary-light);
  text-decoration-line: line-through;
  margin-right: 12px;
}

.total {
  display: block;
  font-size: 20px;
  line-height: normal;
  color: var(--c-tertiary);
}

.totalText {
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 767.98px) {
  .discountContainer {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .discountPriceContainer {
    display: flex;
    align-items: center;
  }
  .total {
    order: -1;
    width: 100%;
  }

  .discount {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 49px;
    height: 24px;
    order: 1;
  }
  .total {
    font-size: 18px;
    margin-top: 7px;
  }

  .totalText {
    font-size: 14px;
  }
  .discountPrice {
    font-size: 18px;
  }
  .discount {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .discountPrice {
    position: relative;
    top: -1px;
  }

  .discount {
    position: relative;
    top: -2px;
  }
}
