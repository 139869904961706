.root {
    background-color: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    transition: transform 300ms;
    transform: translateX(100%);
    overflow: hidden;
}

.open {
    transform: translateX(0%);
}
