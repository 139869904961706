.map {
    height: 100%;
}

.root {
    height: 100%;
}

.root :global(.gm-style-iw-chr) {
    display: none;
}

.root :global(.gm-style-iw) {
    max-width: 368px !important;
    padding: 0 !important;
    max-height: 1000px !important;
}

.root :global(.gm-style-iw-d) {
    padding: 4px !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    max-height: 1000px !important;
}

.root :global(.rv-card) {
    box-shadow: none;
}

.rvPopup :global(.gm-style-iw) > button {
    display: none !important;
}

.root [style*="z-index: -7771"] {
    display: none;
}

.root [style*="z-index: -7772"] img {
    display: none;
}

.root :global(.cluster),
.root [style*="z-index: -7772"] {
    transition: box-shadow, background;
    transition-duration: 300ms;
}

.root [style*="z-index: -7772"]:before {
    content: "1";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.root [style*="z-index: -7772"],
.root :global(.cluster) {
    background: var(--c-tertiary-dark);
    /* TODO Makarov: need remove to delete bug shadow line on Marker */
    /* box-shadow: 0 0 0 2px #ffffff; */
    border-radius: 50%;
}

.root [style*="z-index: -7772"]:before,
.root :global(.cluster) > div {
    font-weight: bold !important;
    font-size: 18px !important;
    text-transform: uppercase !important;
    color: #ffffff !important;
}

.root :global(.gm-style-iw-c) {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
}

.root .activePoint {
    background: var(--c-secondary) !important;
}

.root .activePoint[style*="z-index: -7772"]:before,
.root .activePoint > div {
    color: var(--c-black-cool) !important;
}

.root :global(.cluster) img {
    display: none;
}

@media (max-width: 380px) {
    .root :global(.gm-style-iw) {
        max-width: calc(100vw - 24px) !important
    }
}

.mobilePopup {
    position: fixed;
    z-index: 1;
    left: 12px;
    right: 12px;
    bottom: 12px;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.mobilePopup :global(.rv-card) {
    max-width: 100%;
}
