
.root {
    flex: 1;
}

.root .date {
    font-weight: 500;
}

.main p {
    color: var(--c-tertiary);
}

.main {
    flex: 1;
    border: 1px solid var(--c-grey);
    border-radius: 8px;
    border-right-width: 0;
    overflow: hidden;
}

.end {
    width: 339px;
    border: 1px solid var(--c-grey);
    border-left-width: 0;
    border-radius: 8px;
}

.statuses,
.status {
    margin: 0;
}

.statuses {
    align-items: flex-end;
}

.status,
.additionalStatus {
    display: inline-flex;
}

.additionalStatus {
    margin: 12px 0 0 0;
}

.date {
    font-weight: 500;
}

.mainHeader {
    align-items: flex-start;
}

.title {
    flex: 1;
    color: var(--c-tertiary);
    font-weight: 400;
}

.title a {
    font-size: inherit;
}

.mainTexts {
    flex: 1;
    overflow: hidden;
}

.photo {
    width: 230px;
    height: 172px;
    border-radius: 4px;
    overflow: hidden;
}

.photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.info {
    white-space: nowrap;
}

.infoLocation {
    overflow: hidden;
}

.infoLocation .infoValue {
    overflow: hidden;
    text-overflow: ellipsis;
}

.info:first-child {
    padding-left: 0;
}

.info:last-child {
    padding-right: 0;
}

.root .infoLabel {
    flex: 1;
    color: var(--c-tertiary-light);
}

.root .infoValue {
    flex: 2;
    font-weight: 500;
}

.additionalInfo {
    color: var(--c-status-warning-dark);
}

.endTexts,
.endButtons {
    display: flex;
    justify-content: space-between;
}

.price {
    color: var(--c-tertiary);
}

.hidden {
    visibility: hidden;
    pointer-events: none;
}

@media (max-width: 1679.98px) {
    .end {
        width: 331px;
    }
}

@media (max-width: 1439.98px) {
    .end {
        width: 255px;
    }
}

@media (max-width: 1023.98px) {
    .root {
        flex-direction: column;
        align-items: stretch;
    }

    .endButtons button:not(:last-child) {
        margin-right: 16px;
    }

    .endTexts {
        flex: 1;
        align-items: center;
        flex-direction: row-reverse;
    }

    .price {
        padding-top: 0;
        margin-right: auto;
        margin-left: 0;
    }

    .devider {
        margin: 0 6px;
        width: calc(100% - 12px);
    }
}

@media (max-width: 767.98px) {
    .photo {
        width: 94px;
        height: 71px;
    }

    .main {
        border: none;
    }

    .statuses {
        margin: 0 0 16px 0;
    }
}

@media (min-width: 768px) {
    .title {
        padding-top: 0;
    }
    .root .infoLabel {
        white-space: nowrap;
    }
    
    .infoLocation .infoValue,
    .infoLocation {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100vw - 425px);
    }
    .end {
        padding-bottom: 18px;
    }
    .endContent {
        display: grid;
        grid-template-columns: minmax(192px, 192px) 1fr auto auto;
        grid-template-areas: 
          "priceContainer additionalInfoContainer chatButtonContainer detailsButtonContainer";
        align-items: center;
        width: 100%;
    }

    .priceContainer {
        grid-area: priceContainer;
    }

    .additionalInfoContainer {
        grid-area: additionalInfoContainer;
    }

    .chatButtonContainer {
        grid-area: chatButtonContainer;
        margin-right: 16px;
    }

    .detailsButtonContainer {
        grid-area: detailsButtonContainer;
    }
    
}

@media (min-width: 1024px) {
    .title {
        padding-bottom: 0;
    }

    .end {
        flex-direction: column;
        padding-bottom: 35px;
    }

    .mainHeader {
        flex-direction: column;
    }

    .statuses {
        margin: 24px 0 16px 0;
    }

    .price {
        padding-top: 4px;
        margin-left: auto;
        text-align: right;
    }

    .devider {
        margin: 6px 0;
        height: auto;
    }

    .infoLocation .infoValue,
    .infoLocation {
        max-width: calc(100vw - 775px);
    }

    .endContent {
        grid-template-columns: min-content 1fr;
        grid-template-areas: 
          "priceContainer          priceContainer"
          "additionalInfoContainer chatButtonContainer"
          "detailsButtonContainer  detailsButtonContainer";
        align-items: flex-start;
        align-content: space-between;
        height: 100%;
    }

    .chatButtonContainer {
        margin-right: 0px;
        padding-top: 4px;
        display: flex;
        justify-content: flex-end;
    }

    .detailsButtonContainer {
        display: flex;
        justify-content: flex-end;
    }

}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .main {
        border-right-width: 1px;
    }

    .title {
        margin-top: -8px;
    }

    .photo {
        width: 160px;
        height: 120px;
    }

    .main {
        border-bottom-width: 0;
    }

    .end {
        width: 100%;
        border-left-style: solid;
        border-top-width: 0;
        border-left-width: 1px;
    }
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
    .title {
        margin-top: -4px;
    }

    .endButtons {
        flex: 1;
        flex-direction: column;
    }

    .chatButton {
        align-self: flex-end;
        margin-top: auto;
    }

    .detailsButton {
        margin-top: auto;
        align-self: flex-end;
    }
}

@media (min-width: 1024px) and (max-width: 1679.98px) {
    .statuses {
        flex-direction: row;
        align-items: flex-start;
    }

    .statuses > *:not(:last-child) {
        margin-right: 12px;
    }

    .statuses > *:last-child {
        margin-top: 0;
    }
}

@media (max-width: 767.98px) {
    .statuses {
        align-items: flex-start;
    }
}
@media (min-width: 1440px) {
    .infoLocation .infoValue,
    .infoLocation {
        max-width: calc(100vw - 1155px);
    }

    .endContent {
        grid-template-columns: min-content 1fr;
        grid-template-areas: 
          "additionalInfoContainer priceContainer"
          "chatButtonContainer     detailsButtonContainer";
    }

    .chatButtonContainer {
        justify-content: flex-start;
    }

    .detailsButtonContainer {
        padding-top: 4px;
    }

    .price {
        padding-top: 12px;
    }

}

@media (min-width: 1680px) {
    .mainHeader {
        flex-direction: row;
    }

    .infos {
        display: flex;
    }

    .info {
        flex-direction: column;
    }

    .info:not(:last-child) {
        border-right: 1px solid var(--c-grey);
    }

    .root .infoLabel {
        padding-top: 0;
    }

    .root .infoValue {
        padding-bottom: 0;
    }

    .statuses {
        margin: 4px 0 0 32px;
    }
}
