.description {
	margin-bottom: -16px;
}

.verificationButton {
	background: #DB982E;
}
@media (min-width: 768px) {
	.description {
		font-size: 18px;
		line-height: 27px;
		margin-bottom: 0px;
	}
}

@media (min-width: 1440px) {
	.title {
		padding-top: 17px;
	}
}
