.listProfileInfoItem svg {
  width: 24px;
  height: 24px;
}
.listProfileInfoLink {
  color: var(--c-primary);
  cursor: pointer;
}

.listProfileInfoLink:hover {
  color: var(--c-primary-light);
}

.listProfileInfoCol {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.listProfileInfoCol.listProfileInfoColDisplayNone {
  display: none;
}

.listProfileInfoItem.listProfileInfoColDisplayNone::before {
  display: none;
}

@media (min-width: 1024px) {
  .listProfileInfoItem {
    position: relative;
    height: 100%;
  }
  .listProfileInfoItem::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    bottom: 8px;
    width: 1px;
    
    background: #C4CDD5;
  }

  .listProfileInfo 
  .listProfileInfoCol:first-child 
  .listProfileInfoItem:first-child:before {
    display: none;
  }
}
@media (min-width: 1440px) {
  .listProfileInfoItem::before {
    display: none;
  }
  .listProfileInfoCol {
    display: block;
  }
}
