.map {
  height: 320px;
}

@media (min-width: 768px) {
  .map {
    aspect-ratio: 16/9;
    height: auto;
  }
}
