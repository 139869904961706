.photoAvatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.photoAvatarSpinner {
  z-index: 1;
}
.photoAvatarInner {
  position: relative;
  height: 100%;
}

.photoAvatarImgContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
}
.photoAvatarImgContainer.photoAvatarImgContainerExtended {
  cursor: pointer;
}
.photoAvatarImg {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.photoAvatarUploadContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.photoAvatarUploadContainer.photoAvatarUploadExtended {
  width: 36px;
  height: 36px;
}
.photoAvatarEditTool :global(.imageCropShadow) {
  border-radius: 50%;
}

@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .photoAvatar {
    width: 109px;
    height: 109px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
  .photoAvatar {
    width: 82px;
    height: 82px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919.98px) {
  .photoAvatar {
    width: 86px;
    height: 86px;
  }
}
@media screen and (min-width: 1920px) {
  .photoAvatar {
    width: 100px;
    height: 100px;
  }
}
