.wrap {
  display: none;
}
.instantRental {
  display: flex;
  gap: 0 8px;
}
.instantRentalImg {
  width: 21px;
  height: 21px;
}
.image img {
  width: 100%;
  object-fit: cover;
  object-position: center right;
  border-radius: 4px;
}

.info {
  display: flex;
  height: 100%;
  background-color: var(--c-grey-cool);
  flex-wrap: wrap;
}

.infoItem {
  display: flex;
}

.details {
  border-radius: 4px;
}

.details.showAsideRvInfoDetails {
  background-color: var(--c-grey-cool);
}

.titleWrap {
  width: 84px;
  flex-grow: 0;
  flex-shrink: 0;
}

.title {
  color: var(--c-charcoal);
  white-space: nowrap;
}

.touchableValue {
  cursor: pointer;
  color: var(--c-primary);
  font-weight: 500;
}

.touchableValue:hover {
  color: var(--c-primary-light);
}

.deliveryTooltipContainer {
  width: 228px;
}
@media (min-width: 768px) {
  .wrap {
    width: 100%;
    background-color: var(--c-grey-cool);
    display: flex;
    min-height: 200px;
  }

  .info {
    flex-wrap: nowrap;
  }
  .infoItemColumn {
    width: auto;
  }
  .instantRental {
    justify-content: center;
    margin-top: -24px;
  }

}

@media (min-width: 768px) and (max-width: 1023.98px) {
    .image {
      flex: 0 0 32.333333%;
      max-width: 32.333333%;
    }
}

@media (min-width: 1024px) {
  .image {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .instantRental {
    justify-content: left;
    margin-top: -4px;
  }
  .wrapInner {
    padding-bottom: 0px;
    padding-right: 0;
  }
}
@media (min-width: 1024px) and (max-height: 729.98px) {
  .wrap {
    width: 100%;
    background-color: var(--c-grey-cool);
    display: flex;
    min-height: 200px;
  }
  .wrapInner {
    padding-bottom: 40px;
    padding-right: 24px;
  }
  .image img {
    height: 160px;
  }
  .info {
    height: auto;
  }
}

@media (min-width: 1024px) and (min-height: 730px) {
  .root {
    align-self: stretch;
    width: auto;
  }
  .wrap {
    width: 256px;
    flex-direction: column;
    min-height: auto;
    height: 100%;
    /* height: calc(100vh - 40px); */
  }

  .textEllipsis {
    display: block;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
    width: 130px;
  }


  .textEllipsis:hover {
    color: var(--c-primary-light);
  }

  .image img {
    border-radius: 0;
  }
}