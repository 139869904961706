.list {
  margin: 0px 0;
  padding: 8px 0;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
}

.listItem {
  position: relative;
}

.listItemInner {
  position: relative;
  aspect-ratio: 4/3;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  background: var(--c-quaternary, #eaecef);
  overflow: hidden;
}

.listItemInner::after {
  position: absolute;
  height: 80px;
  width: 100%;
  left: 0;
  bottom: 0;
  background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.0001) 100%);
  opacity: 0.75;
  pointer-events: none;
  content: "";
}

.listItemInner.hasNotImage {
  display: grid;
  place-content: center;
}

.listItemInner.hasNotImage::after {
  display: none;
}

.photoPlaceholder svg {
    width: 28px;
    height: 30px;
}

.img {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.button {
  width: 32px;
  height: 32px;
  padding: 0px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 11px;
}

.downloadButton {
  left: 11px;
  z-index: 2;
}

.commentButton {
  right: 11px;
  z-index: 2;
}

.caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
  pointer-events: none;
  z-index: 1;
}

@media (min-width: 768px)  {
  .photoPlaceholder svg {
    width: 48px;
    height: 52px;
  }

  .list {
    padding: 16px 0;
    grid-gap: 32px 16px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .title {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 1024px)  {
  .photoPlaceholder svg {
    width: 62px;
    height: 66px;
  }
  
  .list {
    grid-gap: 24px 24px;
    padding: 12px 0;
  }
}

@media (min-width: 1440px)  {
  .photoPlaceholder svg {
    width: 63px;
    height: 67px;
  }

  .list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 1680px)  {
  .photoPlaceholder svg {
    width: 48px;
    height: 51px;
  }

  .list {
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 16px 0;
  }
}
