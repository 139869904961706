.root {
    position: relative;
    border-bottom: 1px solid var(--c-grey);
}

.top {
    min-height: 52px;
}
.subHeader {
    height: 56px;
    border-top: 1px solid #C4CDD5;
    cursor: pointer;
}
.subHeaderBackLink {
    color: var(--c-primary);
    cursor: pointer;
    text-decoration: var(--link-underline-text-decoration);
    text-decoration-thickness: var(--link-underline-text-decoration-thickness);
    text-underline-offset: var(--link-underline-text-underline-offset);
    text-decoration-skip-ink: var(--link-underline-text-decoration-skip-ink);
}

.subHeaderBackLink:hover {
    color: var(--c-primary-light);
}

.backIcon,
.closeIcon {
    position: absolute;
    top: 0;
    width: 52px;
    height: 52px;
    font-size: 24px;
    transition: color 300ms;
    cursor: pointer;
}

.backIcon {
    left: 0;
}

.closeIcon {
    right: 0;
}

.closeIcon:hover {
    color: var(--c-charcoal);
}

.rental:not(:last-child) {
    margin-bottom: 8px;
}

.rental > * {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
}

.rentalLabel {
    color: var(--c-tertiary);
}

.rentalLink {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 700;
    margin-right: 4px;
}

.subHeaderLogo svg {
    width: 95px;
}