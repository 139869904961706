.header {
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.image {
  flex: none;
  width: 94px;
  height: 71px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.title {
  flex: 1 0 auto;
  padding-left: 16px;
  max-width: calc(100% - 110px);
}

.name {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-tertiary);
}

.table {
  padding: 6px 0 8px;
}

.param {
  display: flex;
}

.key {
  flex: none;
  width: 100px;
}

.key_text {
  font-size: 16px;
  line-height: 24px;
  color: var(--c-tertiary-light);
}

.value {
  flex: 1 0 auto;
  max-width: calc(100% - 100px);
}

.value_text {
  font-size: 16px;
  line-height: 24px;
  color: var(--c-tertiary);
}

@media (min-width: 768px) {
  .wrapper {
    padding: 20px 24px;
    background-color: var(--c-quaternary);
    border-radius: 8px;
  }

  .image {
    width: 100px;
    height: 75px;
    border-radius: 8px;
  }

  .name {
    font-size: 18px;
    line-height: 27px;
  }

  .table {
    padding: 8px 0;
    display: grid;    
    grid-template-columns: 1fr 1fr;
  }

  .param {
    order: 0;
  }

  .param.rental_type {order: 0;}
  .param.departure_date {order: 2;}
  .param.return_date {order: 4;}
  .param.nights {order: 1;}
  .param.location {order: 3;}
  .param.add_ons {order: 5;}

  .key {
    width: 112px;
  }

  .value {
    max-width: calc(100% - 112px);
  }
}

@media (min-width: 1024px) {
  .wrapper {
    padding: 0;
  }

  .header {
    display: block;
    padding: 0;
  }

  .image {
    width: 100%;
    height: 256px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .title {
    padding: 20px 24px 0;
  }

  .table {
    display: block;
    padding: 0 24px 20px;
  }
}

@media (min-width: 1680px) {
  .image {
    height: 308px;
  }
}
