.fieldsList {
  display: flex;
  flex-wrap: wrap;
  gap: 38px 16px;
}

.fieldsListItem {
  width: 100%;
}

@media (min-width: 768px) {
  .fieldsListItem {
    flex: 1;
  }

  .fieldsList {
    gap: 32px 16px;
  }
}
