@media (max-width: 767.98px) {
    @supports (height: 100dvh) {
        .modalRoot {
            height: 100dvh;
        }
      }
      
    .modal {
        padding-top: 16px !important;
        padding-left: 16px;
        padding-right: 16px;
        max-width: 100vw;
        min-width: 100% !important;
        overflow: auto;
        max-height: 100%;
        min-height: 100%;
        overflow-y: auto;
    }


    .content {
    }

    .bar {
        position: fixed;
        right: var(--cwrr-scroll-width, 0);
        bottom: 0;
        left: 0;
        z-index: 10;
        background-color: #ffffff;
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
        justify-content: center;
    }
} 

@media (min-width: 768px) {
    .modal {
        width: 468px !important;
    }
}