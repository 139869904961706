.section {
  border-top: 1px solid  #BFC5CE;
  margin-top: 16px;
  padding-top: 32px;
}

@media (max-width: 767.98px) {
  .section:first-child {
    border-top: 0;
    padding-top: 0px;
  }
}

@media (min-width: 768px) {
  .section {
    border-top: 1px solid  #BFC5CE;
    margin-top: 20px;
    padding-top: 40px;
  }
}
