.modal {
}

@media screen and (max-width: 767.98px) {
    .modal {
        display: flex;
        align-items: center;
    }
}

.body {
}

@media screen and (min-width: 768px) {
    .body {
        width: 416px;
    }
}

.notApplicableBody {
    composes: body;
}

@media screen and (min-width: 768px) {
    .notApplicableBody {
        width: 450px;
    }
}

.availableText {
    color: var(--c-charcoal);
}

.priceField {
    width: 220px;
}
