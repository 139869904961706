.root {
  position: relative;
}

@media (min-width: 768px) and (max-width: 1439.98px) {
  .root.rightGradient::after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    content: "";
    width: 100px;
    background-image: linear-gradient(
      271.15deg,
      #ffffff 1.13%,
      rgba(255, 255, 255, 0) 99.21%
    );
    opacity: 1;
    visibility: visible;
    transition: all 100ms;
    z-index: 1;
  }

  .root.leftGradient::before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    content: "";
    width: 100px;
    opacity: 1;
    visibility: visible;
    transition: all 100ms;
    background: linear-gradient(271.15deg, #FFFFFF 1.13%, rgba(255, 255, 255, 0) 99.21%);
    transform: rotate(-180deg);
    z-index: 1;
  }
}

.list {
  composes: global(dn) global(db-m) global(l-0) global(r-0) global(t-16) global(b-16) global(t-m-20) global(b-m-20);
  white-space: nowrap;
  overflow-x: auto;
  list-style: none;
  margin: 0;
}
:global(.no-touchevents) .list {
  scrollbar-width: thin;
  scrollbar-color: #656d76 transparent;
}
:global(.no-touchevents) .list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
}
:global(.no-touchevents) .list::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 2px;
}
:global(.no-touchevents) .list::-webkit-scrollbar-thumb {
  background-color: #656d76;
  border-radius: 2px;
}

.item {
  composes: global(mr-m-16) global(mr-xl-0) global(db) global(dib-m) global(db-xl);
  position: relative;
}
@media (max-width: 767px) {
  .item::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: rgba(196, 205, 213, 0.5);
  }
  .item:first-child::before {
    display: none;
  }
}

.item:last-child {
  margin-right: 0;
}

.link {
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  align-items: flex-start;
  white-space: normal;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-tertiary, #232323);
  cursor: pointer;
  outline: none;
  font-weight: normal;
}

@media (min-width: 1440px), (max-width: 767px) {
  .link {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.linkBasic {
  composes: global(l-16) global(r-16);
}

.linkStatus {
  padding-left: 8px;
  padding-right: 8px;
  composes: global(l-8) global(r-8) global(r-m-16) global(r-xl-8);
}

.linkStatus::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: transparent url("./tickQuaternaryDarkIcon.svg") no-repeat 0 0;
  margin-right: 8px;
  flex: 0 0 auto;
}

.linkStatus.isNewPhotosFromGarage:not(.completedLink):not(.completedActiveLink):not(.activeLink)::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: transparent url("./tick-gray-garage-orange.svg") no-repeat 0 0;
  margin-right: 8px;
  flex: 0 0 auto;
}

.linkStatus.isNewPhotosFromGarage.activeLink:not(.completedLink):not(.completedActiveLink):before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: transparent url("./tick-gray-garage-orange.svg") no-repeat 0 0;
  margin-right: 8px;
  flex: 0 0 auto;
}

.link:hover {

}

.disabledLink {
  composes: link;
  cursor: default;
}

.disabledLink:hover {

}

.activeLink {
  font-weight: 500;
  background-color: var(--c-quaternary);
  pointer-events: none;
}

.activeLink:hover {

}

.completedLink {
  composes: link;
}

.completedActiveLink {
  composes: activeLink;
}

.completedLink::before,
.completedActiveLink::before {
  background-image: url("./tick-green.svg");
}

.completedLink.isNewPhotosFromGarage::before,
.completedActiveLink.isNewPhotosFromGarage::before {
  background-image: url("./tick-green-garage.svg");
}

.errorLink::before {
  background-image: url("./error.svg");
}
@media (max-width: 767px) {
  .linkWithAction {
    composes: global(r-56) global(r-m-16);
    position: relative;
  }
  .linkWithAction::after {
    content: "";
    position: absolute;
    right: 16px;
    width: 24px;
    height: 24px;
    left: auto;
    bottom: auto;
    display: inline-block;
    background: transparent url("./chevron-right.svg") no-repeat 0 0;
  }
}
