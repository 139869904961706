.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  grid-gap: 0 16px;
  padding: 0;
}
.galleryItemInner {
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  height: 100%;
}

.galleryPhoto {
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 4 / 3;
  min-height: 46px;
}

.galleryCount {
  display: grid;
  place-content: center;
  height: 100%;
  color: #FFF;
  background: #EAECEF;
}


@media (min-width: 768px) {
  .galleryPhoto {
    min-height: 83px;
  }
}

@media (min-width: 1024px) {
  .gallery {
    grid-gap:0 24px;
  }
  .galleryPhoto {
    min-height: 44px;
  }
}
@media (min-width: 1440px) {
  .gallery {
    grid-template-columns: repeat(7, minmax(10px, 1fr));
  }

  .galleryPhoto {
    min-height: 65px;
  }
}
@media (min-width: 1680px) {
  .gallery {
    grid-template-columns: repeat(6, 100px);
    grid-gap: 0 32px;
  }
  .galleryPhoto {
    max-width: 100px;
    min-height: 75px;
  }
}