.mdl {
  padding: 16px;
}
@media (min-width: 768px) {
  .mdl {
    padding: 12px 56px 28px;
  }
}
@media (max-width: 767px) {
  .mdl {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
}

.mdlContent {
  flex: 0 1 auto;
  composes: global(t-8) global(t-m-0) global(b-16) global(b-m-0);
}
