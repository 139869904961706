.header {
  composes: global(b-16) global(b-s-20);
  border-bottom: 1px solid #c4cdd5;
}

.headerItem {
  composes: global(t-4) global(b-4);
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #656d76;
}

.content {
  composes: global(t-16) global(t-s-20) global(b-16) global(b-s-20);
  border-bottom: 1px solid #c4cdd5;
}

.contentItem {
  composes: global(row);
}

.cellModel {
  composes: global(col-6);
  display: flex;
}

.cellModelText {
  composes: global(mt-8) global(mb-8);
  margin-left: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.cellClass {
  composes: global(col-6);
}

.cellClassText {
  composes: global(mt-8) global(mb-8);
  display: inline-block;
  padding-top: 2px;
  padding-bottom: 2px;
}
.fw700 {
  font-weight: 700;
}
