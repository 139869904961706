.root {
}

.outerField {
    overflow: hidden;
}

.root :global(.field-input__field) {
    border: none !important;
    padding: 0;
    display: flex;
}

.root :global(.field-input.error .field-input__label) {
    color: inherit;
}

.root .select:global(.error .field-input__label) {
    color: var(--c-status-error-dark);
}

.select {
    height: 100%;
    z-index: 1;
}

.select:focus,
.select:hover {
    z-index: 2;
}

.select:not(:last-child) {
    margin-right: -1px;
}

.select:not(:last-child) :global(.field-select-button)  {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.select:not(:first-child) :global(.field-select-button)  {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
