
.container {
    max-width: 760px;
}

@media (min-width: 1024px) and (max-width: 1920px) {
    .container {
        max-width: 100%;
    }
}
