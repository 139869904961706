.modalWrap {
  height: 100%;
  background: #fff;
}

.modal {
  box-shadow: none;
  width: 100vw;
  min-height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  max-height: 100%;
  overflow-y: auto;
  background: none;
  composes: global(col-l-10) global(col-xl-8) global(col-xxl-6);
}
.content {
  padding: 16px 16px 36px;
  background: #fff;
}

.tagAccountType {
  margin: 0;
}

.crossButton {
  width: 52px;
  height: 52px;
  position: fixed;
  right: var(--cwrr-scroll-width, 0);
}
@media (min-width: 768px) {
  .content {
    padding: 20px 16px 60px;
  }
}

@media (min-width: 1024px) {
  .modalWrap {
    background: rgba(0, 0, 0, 0.25);
  }
  
  .modal {
    max-width: calc(83.3333333333% - 56px);
    overflow-y: visible;
    margin: 40px auto;
    min-height: auto;
    max-height: none;
  }
  .crossButton {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 0;
  }
  .content {
    padding: 12px 56px 28px;
    border-radius: 8px;
  }
}
@media (min-width: 1440px) {
  .modal {
    max-width: calc(66.6666666667% - 104px);
  }
}

@media (min-width: 1680px) {
  .modal {
    max-width: calc(50% - 136px);
  }
}