.item {
  composes: global(mr-m-16) global(mr-xl-0) global(db) global(dib-m) global(db-xl);
  position: relative;
}
@media (max-width: 767.98px) {
  .item::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: rgba(196, 205, 213, 0.5);
  }
  .item:first-child::before {
    display: none;
  }
}

.item:last-child {
  margin-right: 0;
}

.link {
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  align-items: flex-start;
  white-space: normal;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: var(--c-tertiary);
  cursor: pointer;
  outline: none;
}

@media (min-width: 1440px), (max-width: 767.98px) {
  .link {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.linkBasic {
  composes: global(l-16) global(r-16);
}


.disabledLink {
  composes: link;
  cursor: default;
}

.disabledLink:hover {

}

.activeLink {
  font-weight: 500;
  background-color: var(--c-quaternary);
  pointer-events: none;
}

.activeLink:hover {

}

@media (max-width: 767.98px) {
  .linkWithAction {
    composes: global(r-56) global(r-m-16);
    position: relative;
  }
  .linkWithAction::after {
    content: "";
    position: absolute;
    right: 16px;
    width: 24px;
    height: 24px;
    bottom: auto;
    left: auto;
    display: inline-block;
    background: transparent url("./chevron-right.svg") no-repeat 0 0;
  }
}


/* styles for extra nav link */
.linkExtra {
  justify-content: left;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.linkExtra > span {
  padding: 13px 16px;
  display: block;
  width: 100%;
}

.linkExtraDisabled {
  opacity: .6;
  pointer-events: none;
  cursor: text;
}

.linkExtra:focus {
}

.linkExtra:hover {
}

.linkExtra:hover > span {
  color: var(--c-primary-light);
}

.linkExtra > .linkExtraPressed {
  color: var(--c-black-cool) !important;
}

@media (min-width: 1440px), (max-width: 767.98px) {
  .linkExtra > span {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media (max-width: 767.98px) {
  .linkExtra.linkWithAction::after {
    top: 14px;
  }
}
