.root {
  padding: 0 0px;
  list-style: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  margin-top: 0;
}

.item {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}

.button {
  color: var(--c-primary);
}

.button:hover {
  color: var(--c-primary-light);
}

.total {
  composes: global(subtitle--m);
}
.protection {
  cursor: pointer;
}
.rentalRate {
  composes: global(body--l);
}

.rentalRateDiscount {
  display: inline-block;
  margin-left: 4px;
}

.rentalRateDiscountSum {
  color: var(--c-status-success-dark, #39842B);
  font-weight: 500;
}

.taxesFees {
  cursor: pointer;
}

.memberText {
  display: inline-block;
  margin-right: auto;
}

.smallTextIcon {
  transform: translateY(2px);
  margin-right: 7px;
  width: 73px;
  height: 40px;
  margin-top: -5px;

}

.memberSavings {
  position: relative;
  cursor: pointer;
}
.memberSavings::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px;
  right: -20px;
  background-color: rgba(196, 205, 213, 0.2);
  pointer-events: none;
}
.isClientGoodSamMemberClass {
  cursor: text;
}
@media screen and (min-width: 0px) and (max-width: 1023.98px) {
  .memberSavings::after {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 567.98px) {
  .total {
    margin-top: 9px;
  }
}

@media screen and (min-width: 568px) and (max-width: 1023.98px) {
  .total {
    margin-top: 15px;
  }
  .root {
    margin-bottom: 27px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767.98px) {
  .root {
    margin-bottom: 22px;
  }
  .total {
    font-size: 18px;
    line-height: 27px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
  .root {
    margin-bottom: 18px;
  }
  .rentalRate .item {
    height: 34px;
  }

  .isClientGoodSamMemberClass {
    margin-top: 2px;
  }
  .total {
    font-size: 18px;
    line-height: 27px;
    margin-top: 14px;
  }
  .memberSavings::after {
    left: -24px;
    right: -24px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1679.98px) {
  .root {
    margin-bottom: 18px;
  }
  .rentalRate .item {
    height: 34px;
  }

  .isClientGoodSamMemberClass {
    margin-top: 2px;
  }
  .total {
    font-size: 18px;
    line-height: 27px;
    margin-top: 1px;
  }
  .memberSavings::after {
    left: -24px;
    right: -24px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1679.98px) {
  .root {
    margin-bottom: 23px;
  }
  .rentalRate .item {
    height: 32px;
  }
  .item {
    height: 32px;
  }
  .isClientGoodSamMemberClass {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .total {
    font-size: 20px;
    line-height: 30px;
    margin-top: 15px;
  }
  .memberSavings::after {
    left: -24px;
    right: -24px;
  }

  .smallTextIcon {
    margin-right: 3px;
  }
}


@media screen and (min-width: 1440px) {
  .item {
    height: 40px;
  }
}

@media screen and (min-width: 1680px) {
  .total {
    margin-bottom: 15px;
  }
  .total {
    font-size: 20px;
    line-height: 30px;
    margin-top: 15px;
  }
  .memberSavings::after {
    left: -32px;
    right: -32px;
  }
}
