.DayPicker {
  display: inline-block;
  font-size: 1rem
}

.wrapper {
  position: relative;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: calc(34em + 48px);
}

.months {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-left: -16px;
}
.month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: calc(40% - 12px);
  float: left;
}

.month + .month {
  margin-left: 35px;
}
.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;
  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: 50%;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer
}
.caption {
  display: table-caption;
  line-height: 35px;
  height: 35px;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: var(--widget-text-color, #212b36);
}

.caption > div {
  position: absolute;
  left: 0;
}

.weekdays {
  display: table-header-group;
}

.weekdaysRow {
  display: table-row;
}

.weekday {
  display: table-cell;
  width: calc(100% / 7);
  width: 35px;
  height: 30px;
  font-size: 12px;
  line-height: 33px;
  font-weight: 400;
  color: #212b36;
}

.weekday abbr {
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  margin-top: 16px;
  margin-bottom: 4px;
  text-decoration: none;
  color: #656d76;
  font-size: 12px;
  line-height: 33px;
  font-weight: 400;
  color: var(--widget-text-color, #212b36);
}

.body {
  display: table-row-group;
}
.day {
  display: table-cell;
  height: 40px;
  border: 1px solid var(--widget-border-dates-color, #BFC5CE);
}
.week {
  display: table-row;
}

.daySquare {
  display: grid;
  place-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--widget-text-color, #212b36);
}
.navBar {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  position: absolute;
  top: 17px;
  right: 0px;
  left: 0px;
  z-index: 1;
}

.navButtonPrev,
.navButtonNext {
    cursor: pointer;
    width: 35px;
    height: 35px;
}

.navButtonNext {
  background: url("./small-day-picker-dark-arrow-next.svg") no-repeat center center;
}
.navButtonPrev {
  background: url("./small-day-picker-dark-arrow-next.svg") no-repeat  center center;
  transform: rotate(180deg);
}
.navButtonPrev.navButtonInteractionDisabled {
  background: url("./small-day-picker-dark-arrow-next.svg") no-repeat   center center;
  transform: rotate(180deg);
  cursor: default;
  pointer-events: none;
  position: relative;
  opacity: .5;
}


.disabled, .outside {
  opacity: 0.3;
  color: #212b36;
  cursor: default;
  pointer-events: none;
  border: none;
}
.disabled {
  border: 1px solid var(--widget-border-dates-color, #BFC5CE);
}
.disabled.outside {
  border: none;
}

.outside > .daySquare {
  background: none !important;
  color: #212b36 !important;
  cursor: default;
  pointer-events: none;
  opacity: 0;
}

.selected.start .daySquare, .selected.end .daySquare {
  color: var(--widget-background-color, #000);
  background-color: var(--widget-selected-dates-color, #474342);
  cursor: pointer;

}

.selected:not(.start):not(.end):not(.disabled):not(.outside) .daySquare,
.selected:not(.start):not(.end):not(.disabled):not(.outside) .daySquare:hover {
  background-color: var(--widget-selected-dates-color, #CDE4E1);
  border-radius: 0;
  cursor: pointer;
  color: var(--widget-background-color, #212b36);
}

.day:not(.start):not(.end):not(.disabled):not(.outside) .daySquare:hover {
  border: 1px solid var(--widget-text-color, #212b36) !important;
  cursor: pointer;
}

.day.onlyEnteredTo .daySquare:hover {
  border: 1px solid var(--widget-text-color, #212b36) !important;
  cursor: pointer;
}
.day.selected:not(.start):not(.end):not(.disabled):not(.outside) .daySquare:hover {
  border: 1px solid var(--widget-text-color, #212b36) !important;
  border-radius: 0;
  cursor: pointer;
}

@media screen and (max-width: 767.98px) {
  .wrapper {
    width: 288px;
  }
  .months {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 768px) {
  .months {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
  }
}