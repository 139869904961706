.container {
  overflow: hidden;
}
.amenities {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  padding: 0;
  margin: -8px -8px;
}
.amenitiesItemInner {
  position: relative;
  border-right: 1px solid rgba(196, 205, 213, 0.5);
  border-bottom: 1px solid rgba(196, 205, 213, 0.5);
  margin: 0 -1px -1px;

  padding: 28px 16px 16px;
}
.amenitiesItem:last-child .amenitiesItemInner{
  border-right-color: transparent;
}

.amenitiesImgContainer {
  height: 64px;
  display: flex;
  align-items: center;
  justify-self: center;
}
.amenitiesAvailable {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 13px;
}

.amenitiesImg {
  margin: 0 auto;
  height: 40px;
  width: auto;
}
.amenitiesSubTitleContainer {
  height: 42px;
}
.amenitiesSubTitle {
  padding: 0px;
}
@media (min-width: 768px) {
  .amenities {
    grid-template-columns: repeat(4, 25%);
    margin: 0px -15px;
  }
  .amenitiesSubTitle {
    padding-top: 4px;
  }
  .amenitiesItemInner {
    padding: 28px 28px 18px;
  }
  .amenitiesAvailable {
    top: 12px;
    right: 29px;
    width: 31px;
    height: 17px;
  }
}

@media (min-width: 1024px) {
  .amenities {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
  }
  .amenitiesImg {
    height: 48px;
  }
}

@media (min-width: 1440px) {
  .amenities {
    grid-template-columns: repeat(4, 25%);
    margin: -14px -15px -8px;
  }
  .amenitiesItemInner {
    padding: 44px 30px 16px;
  }

  .amenitiesAvailable {
    top: 26px;
  }
}
@media (min-width: 1680px) {
  .amenities {
    grid-template-columns: repeat(5, 20%);
  }
}
