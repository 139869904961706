.buttons {
    position: absolute;
    bottom: 0;
    composes: global(t-20) global(b-20);
    display: flex;
    justify-content: center;
    gap: 0 32px;
}
.myAccountButtonsContainerInner {
    display: flex;
    grid-gap: 0 16px;
}