.charactList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0 16px;
  justify-items: center;
  padding: 0;
}
.charactListModalContainer {
  margin-top: -20px;
}
.title {
  padding-bottom: 0;
}
.titleText {
  color: #656D76;
}
.numb {
  padding-top: 0;
}

.charactListImgContainer {
  height: 40px;
  width: 40px;
  display: grid;
  place-content: center;
  margin: 0 auto -2px;
}

.charactListImg {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center center;
}

@media (min-width: 768px){
  .charactList {
    grid-template-columns: repeat(6, minmax(108px, 1fr));
  }
}

@media (min-width: 1024px){
  .charactList {
    grid-template-columns: repeat(7, minmax(56px, 1fr));
    grid-gap: 0 24px;
  }

  .title {
    min-height: 40px;
  }

}

@media (min-width: 1440px){
  .charactList {
    grid-template-columns: repeat(8, minmax(84px, 1fr));
  }
  .titleText {
    white-space: nowrap;
  }
  .title {
    min-height: auto;
  }
}

@media (min-width: 1440px){
  .charactList {
    grid-gap: 0 32px;
  }
}