.modal {
  margin-top: 0;
  margin-bottom: 0;
  min-height: calc(var(--vh, 1vh) * 100);
  max-width: 100vw;
  min-width: 100%;
  border-radius: 0;
  padding: 16px 16px 28px;
  composes: global(col-12) global(col-xl-8) global(col-xxl-8);
}
.crossButton {
  top: 4px
}

.subtitle {
  padding-bottom: 4px;
}

.item {
  position: relative;
}

.description {
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .modal {
    padding: 12px 16px 28px;
  }

  .crossButton {
    top: 0px
  }

  .iconStandardContainer svg {
    width: 117px;
    height: 60px;
    margin-left: -8px;
  }

  .iconDeliveryOnlyContainer svg {
    width: 117px;
    height: 60px;
    margin-left: -3px;
  }

  .subtitle {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 0;
    margin-bottom: -4px;
  }
}
@media screen and (min-width: 1024px) {
  .modal {
    max-width: 960px;
    min-width: 960px;
    min-height: auto;
    padding: 44px 56px 80px;
    margin: 0 auto;
    border-radius: 8px;
  }
  .title {
    padding-top: 0;
  }
  .contentContainer {
    display: flex;
  }

  .item:last-child::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #EAECEF;
  }
}

@media screen and (min-width: 1440px) {
  
  .subtitle {
    font-size: 24px;
    line-height: 36px;
  }

  .iconStandardContainer svg {
    width: 135px;
    height: 80px;
    margin-left: 0px;
  }

  .iconDeliveryOnlyContainer svg {
    width: 151px;
    height: 83px;
    margin-left: 0px;
  }

}

@media screen and (min-width: 1680px) {
  
}