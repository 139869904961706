.container {
  box-shadow: 0px 2px 20px rgba(101, 109, 118, 0.2);
  border-radius: 8px;
}

.avatar {
  width: 86px;
  height: 86px;
}
.avatarImg {
  border-radius: 50%;
  width: 100%;
}
.name {
  display: block;
}

@media (min-width: 1024px) {
  .avatar {
    width: 109px;
    height: 109px;
  }
}
@media (min-width: 1440px) {
  .avatar {
    width: 86px;
    height: 86px;
  }
}
@media (min-width: 1680px) {
  .avatar {
    width: 100px;
    height: 100px;
  }
}
