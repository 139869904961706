.content {
    composes: global(t-20);
    margin-left: auto;
    margin-right: auto;
}

.cards {
    composes: global(df);
}

.details {
    width: 364px;
}

.details > div:not(:last-child) {
    margin-bottom: 52px;
}

@media (min-width: 1680px) {
    .content {
        max-width: 1352px;
    }

    .details {
        width: 364px;
        margin-right: 100px;
    }
}

@media (max-width: 1679.98px) {
    .content {
        max-width: 1290px;
    }

    .details {
        width: 306px;
        margin-right: 72px;
    }
}

@media (max-width: 1439.98px) {
    .content {
        max-width: 876px;
    }

    .changeBlock,
    .details {
        flex: 1;
    }
}

@media (max-width: 1023.98px) {
    .details {
        margin-right: 16px;
    }
}

@media (max-width: 767.98px) {
    .cards {
        flex-direction: column;
    }
}
