.container {
  margin: 16px 16px 116px;
}

.modalWrap {
  height: 100%;
  background: #fff;
}

.modal {
  box-shadow: none;
  width: 100vw;
  min-height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  max-height: 100%;
  overflow-y: auto;
  background: #fff;
}

.tagAccountType {
  margin: 0;
}

.crossButton {
  width: 52px;
  height: 52px;
  position: fixed;
  right: var(--cwrr-scroll-width, 0);
}
@media (min-width: 768px) {
  .container {
    margin: 20px 16px 116px;
  }
}

@media (min-width: 1024px) {
  .container {
    margin: 20px 56px 20px;
  }

  .modalWrap {
    background: rgba(0, 0, 0, 0.25);
  }
  
  .modal {
    max-width: 760px;
    width: 760px;
    border-radius: 8px;
    overflow-y: visible;
    margin: 40px auto;
    min-height: auto;
    max-height: none;
  }
  .crossButton {
    width: 42px;
    height: 42px;
    position: absolute;
    right: 0;
  }
  .title {
    font-size: 32px;
    line-height: 48px;
  }
}