.sliderContainer {
  position: relative;
}

.sliderItem {
  aspect-ratio: 4 / 3;
  display: block !important;
}

.sliderImg {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.sliderControls {
  position: absolute;
  top: calc(50% - 26px);
  width: 100%;
}

.sliderArrowPrev, 
.sliderArrowNext {
  position: absolute;
  width: 36px;
  height: 52px;
  border: none;
  background-color: transparent;
  background-image: url("./arrow-left.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  text-indent: -99999px;
}
.sliderArrowPrev {
  left: 0;
}

.sliderArrowNext {
  background-image: url("./arrow-right.svg");
  right: 0;
}

.sliderDots {
  position: absolute;
  right: 16px;
  bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  padding: 0 8px;

}

.sliderModal {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  overflow: hidden;
  background: #fff;
}

.modalShow {
  opacity: 1;
  pointer-events: all;
  z-index: 1;
}

.sliderDotsList {
  padding-top: 10px;
}

.sliderModalHeader {
  z-index: 1;
  height: 51px;
}

.modalButtonClose {
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 2;
  width: 52px;
  height: 52px;
  cursor: pointer;
  background-image: url("assets/images/icon-close-black.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.bodyOverflowHidden {
  overflow: hidden;
}