.footerButtons > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}


  .footerButtons > div,
  .buttonsContainerInner > div  {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
    width: 100%;
    gap: 32px 16px;
  }
  .footerButtonNext {
    text-align: center;
  }
  .footerButtons > div > div > button:first-child,
  .buttonsContainerInner > div > button:first-child {
    order: 1;
    align-self: flex-start;
    margin-right: 0;
  }
  .footerButtons .footerButtonCancel{
    order: 2;
  }
  .footerButtonCancel {
    padding-right: 0;
  }
