.container {
    position: relative;
}

@media (max-width: 767.98px) {
    .wrapper {
        display: flex;
        flex-direction: column;
    }
}

.months {
    composes: global(b-16) global(b-m-0);
}

.months1 {
    display: block;
}

.months2 {
    display: block;
}

.months2:before,
.months2:after {
  content: " ";
  display: table;
}

.months2:after {
  clear: both;
}

.month {
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    user-select: none;
    width: 100%;
}

.months2 .month {
    float: left;
    width: calc(50% - 8px);
}
.months2 .month + .month {
    margin-left: 16px;
}

@media (min-width: 1024px) {
    .months2 .month {
        width: calc(50% - 12px);
    }
    .months2 .month + .month {
        margin-left: 24px;
    }
}
@media (min-width: 1680px) {
    .months2 .month {
        width: calc(50% - ((100% - 32px) / 10 - 32px) / 2);
    }
    /* we need a gap equals to a column without gutters */
    .months2 .month + .month {
        margin-left: calc((100% - 32px) / 10 - 32px);
    }
}

.caption {
    display: table-caption;
    line-height: 52px;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 20px;
    color: var(--c-tertiary);
}

@media screen and (min-width: 768px) and (max-width: 1023.98px) {
    .caption {
        font-size: 20px;
    }
}

@media screen and (max-width: 767.98px) {
    .caption {
        padding-top: 16px;
        padding-bottom: 12px;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 0;
    }
}

.body {
    display: table-row-group;
}

.navBar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 52px;
    position: relative;
}

.navButtonPrev,
.navButtonNext {
    cursor: pointer;
    width: 52px;
    height: 52px;
}

.navButtonNext {
    background: center url("./icons/date-arrow-right.svg") no-repeat;
}
.navButtonPrev {
    background-image: url("./icons/date-arrow-left.svg");
}

.navButtonPrevDisabled {
    background-image: url("./icons/date-arrow-left.svg");
    cursor: pointer;
    width: 52px;
    height: 52px;
}

.navButtonPrevDisabled.navButtonInteractionDisabled {
    background: url("./icons/date-arrow-left-disabled.svg") no-repeat 0 0;
    cursor: default;
    pointer-events: none;
}

.navButtonPrevDisabled:hover {
    background: url("./icons/date-arrow-left-hover.svg") no-repeat 0 0;
}

.navButtonPrev:hover {
    background: url("./icons/date-arrow-left-hover.svg") no-repeat 0 0;
}

.navButtonNext:hover {
    background: url("./icons/date-arrow-right-hover.svg") no-repeat 0 0;
}

.weekdays {
    display: table-header-group;
}

.weekdaysRow {
    display: table-row;
}

.weekday {
    display: table-cell;
    width: calc(100% / 7);
}

.weekday abbr {
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-decoration: none;
    color: var(--c-tertiary-light);
}

@media screen and (max-width: 767.98px) {
    .weekday abbr {
        font-size: 14px;
        line-height: 21px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.week {
    display: table-row;
}

.day {
    display: table-cell;
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
    text-align: right;
    color: var(--c-tertiary);
    cursor: pointer;
    border: 1px solid var(--c-quaternary-dark);
}

.firstDay {
}

.lastDay {
}

.outside {
    background: transparent !important;
    cursor: default !important;
}

.outside:not(.lastDay) {
    border-right-color: transparent;
}

.week:first-child > .outside {
    border-top-color: transparent;
    border-left-color: transparent;
}

.week:last-child > .outside {
    border-bottom-color: transparent;
    border-right-color: transparent;
}

.dayBox {
    position: absolute;
    height: 100%;
    width: 100%;
}

@media (max-width: 767.98px) {
    .day {
        font-size: 16px;
    }
    .dayBox {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.daySquare {
    padding-bottom: 100%;
    position: relative;
}

@media screen and (min-width: 768px) {
    .dateLabel {
        padding-top: 14px;
        padding-right: 11px;
    }
}

.disabled {
    cursor: default;
    background-color: var(--c-quaternary);
    pointer-events: none;
    color: var(--c-tertiary-light);
}

.busy {
    background-color: var(--c-quaternary);
}

.selected,
.day:hover {
    background: #EDF8EB;
}

.disabled.selected {
    background: var(--c-status-success-transparent);
}

@media screen and (min-width: 0px) and (max-width: 767.98px) {
    .navBar {
        order: 1;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .navButtonPrev,
    .navButtonNext {
        margin-left: 8px;
        margin-right: 8px;
    }
}

@media screen and (min-width: 768px) {
    .months {
        margin-top: -52px;
    }
}
