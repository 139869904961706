/* TODO: resolve col-l-3-wide width issue */
.aside-info {
  composes: global(offset-auto) global(col-12) global(col-l-3-wide) global(col-xl-3);
}
.headphonesButton {
  justify-content: flex-start;
  padding: 0;
}

.rv_rentals_support_email {
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  justify-content: flex-start;
}

@media (max-width: 1439px) and (min-width: 1024px) {
  .aside-info {
    padding-top: 36px;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .aside-info {
    padding-top: 52px;
  }
}

.aside-info__item {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  composes: global(t-8) global(b-16) global(l-16) global(r-16) global(mb-24);
  border: 2px solid var(--c-secondary, #DEDED8);
}

.aside-info__item-fill {
  border: 2px solid var(--c-quaternary, #eee);
  background-color: var(--c-quaternary);
}

.aside-info__block {
  composes: global(l-m-16) global(r-m-16);
}

.aside-info__title {
  min-width: 160px;
}

.aside-info__title strong {
  font-weight: 500;
}

.aside-info__description {
  composes: global(b-8) global(b-m-0) global(b-l-8) global(t-m-8) global(t-l-0);
}

.aside-info__contacts {
  display: flex;
  composes: global(b-16) global(b-m-0) global(b-l-8) global(t-m-8) global(b-l-8) global(t-l-0);
}

.aside-info__icon {
  margin-right: 10px;
}

@media (min-width: 1024px) {
  .aside-info__contacts {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .aside-info__item {
    flex-direction: row;
  }
}
