.rentalTypeList {
  padding: 0;
}
.rvIcon {
  display: inline-flex;
}
.icon svg {
  width: 24px;
  height: 24px;
}

.container {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid var(--c-grey, #D5D5D5);
  border-radius: 8px;
}

.container:hover {
  background: #F6FBF5;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid transparent !important;
  border-radius: 8px;
}

.container.selected {
  background: #EDF8EB !important;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25) inset !important;
  border: 1px solid transparent !important;
  border-radius: 8px;
}

.description {
  margin-top: -8px;
}

@media (min-width: 768px) {

  .description {
    margin-top: -8px;
  }
  .rentalTypeList {
    display: flex;
  }

  .rentalTypeListItem {
    margin-left: 16px;
  }

  .rentalTypeListItem:first-child {
    margin-left: 0;
  }

  .rvIcon svg {
    width: 101px;
    height: 60px;
  }
  .rentalTypeDeliveryOnlyIcon svg {
    width: 112px;
    height: 60px;
  }

  .icon svg {
    width: 32px;
    height: 32px;
  }
}

