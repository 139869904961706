.root {
}

.filters {
    min-width: calc(196px + 24px);
}

.list {
    flex: 1;
    align-self: flex-start;
}

.header {
    align-items: flex-end;
}

.sortSelect {
    width: 264px;
}

@media (min-width: 1680px) {
    .filters {
        min-width: calc(232px + 32px);
    }
}

@media (max-width: 767.98px) {
    .header {
        flex-direction: column;
        align-items: stretch;
    }

    .sortSelect {
        flex: 1;
        max-width: 300px;
        overflow: hidden;
    }

    .sortSelect :global(.ui-selectmenu-text) {
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .actions {
        flex-wrap: wrap;
    }

    .buildRental {
        order: 1;
        width: 100%;
    }

    .buildRental button {
        width: 100%;
        max-width: calc(300px + 52px + 16px);
    }
}
