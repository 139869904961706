.modalWrap {
  overflow-y: hidden;
}

.modal {
  box-shadow: none;
  width: 100vw;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  max-height: 100%;
  overflow-y: auto;
  background: none;
  background: #fff;
  height: 100%;
  min-height: 100%;
}

.crossButton {
  width: 52px;
  height: 52px;
  cursor: pointer;
}

.container {
  padding: 16px 16px 110px;
}

.title {
  padding-bottom: 20px;
}

.priceFormContainer {
  border-top: 1px solid #C4CDD5;
  margin-top: 8px;
  padding-top: 8px;
}

.footer {
  position: fixed;
  height: 84px;
  left: 0px;
  right: var(--cwrr-scroll-width, 0);
  bottom: 0px;
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
}

.footerInner {
  height: 100%;
  gap: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 4px 141px 44px;
  }

  .container.multiСolumn {
    padding: 4px 16px 44px;
  }

  .title {
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 4px;
  }

  .priceFormContainer {
    margin-top: 12px;
    padding-top: 12px;
  }

  .footer {
    position: static;
    height: 100%;
    box-shadow: none;
  }

  .footerInner {
    justify-content: flex-start;
    padding: 0;
    padding-top: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .modalWrap {
    overflow-y: auto;
  }
  
  .modal {
    width: 760px;
    margin: 40px auto;
    overflow-y: visible;
    min-height: auto;
    max-height: none;
    height: auto;
    border-radius: 8px;
  }

  .modal.multiСolumn {
    width: 796px;
  }

  .crossButton {
    width: 42px;
    height: 42px;
  }

  .container {
    padding: 4px 56px 48px;
  }

  .container.multiСolumn {
    padding: 4px 56px 48px;
  }
}

@media screen and (min-width: 1440px) {
  .modal.multiСolumn {
    width: 856px;
  }

  .container {
    padding: 12px 56px 48px;
  }

  .container.multiСolumn {
    padding: 12px 56px 48px;
  }

  .title {
    font-size: 28px;
    line-height: 42px;
    padding-bottom: 8px;
  }
}

@media screen and (min-width: 1680px) {
  .modal.multiСolumn {
    width: 1024px;
  }
}
