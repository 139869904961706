.downloadIcon {
  cursor: pointer;
}
.downloadIcon svg {
  width: 24px;
  height: 24px;
}

.downloadButton {
  padding: 0;
  border: none !important;
}

.downloadButton:hover,
.downloadButton:focus {
  background-color: transparent !important;
  border: none !important;
}

.totalText {
  color: var(--c-tertiary);
}

.totalPrice {
  color: var(--c-primary);
  font-weight: 400;
}

.taxesText {
  color: var(--c-tertiary-light);
}

.totalPriceContainer {
  /* font-weight: 400; */
}

.taxesPrice {
  color: var(--c-tertiary-light);
}
