.header {
  position: relative;
}
@media (max-width: 767px) {
  .header {
    composes: global(t-16);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .header__content {
    position: static;
  }
}
@media (min-width: 1024px) {
  .header_details {
    align-items: flex-start;
  }
}
.header_details .header__content {
  display: block;
}
@media (min-width: 1024px) {
  .header_details .header__content {
    display: block;
    margin-top: -16px;
  }
}
@media (min-width: 1440px) {
  .header_details .header__content {
    margin-top: 0;
  }
}
@media (min-width: 1680px) {
  .header_details .header__content {
    margin-top: 20px;
  }
}

.header__link {
  composes: global(df);
  padding: 1px 0;
  color: var(--c-primary, #23410A);
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
  font-style: normal;
  border: none;
  text-decoration: var(--link-underline-text-decoration);
  text-decoration-thickness: var(--link-underline-text-decoration-thickness);
  text-underline-offset: var(--link-underline-text-underline-offset);
  text-decoration-skip-ink: var(--link-underline-text-decoration-skip-ink);
}
.header__link :global(.btn__icon) {
  margin-left: 0 !important;
}

@media (min-width: 768px) {
  .header__link {
    display: none;
  }

  .title {
    margin-top: 0px;
  }
}

.header__link :global(.btn__icon) {
  font-size: 0;
  line-height: 0;
}

.header__icon {
  margin-right: 8px;
}

@media (min-width: 768px) {
  .header {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

@media (min-width: 768px) {
  .header_details {
    padding-bottom: 113px;
  }
}
@media (min-width: 1024px) {
  .header_details {
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  .header__details_data {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .header__details_data {
    position: relative;
    bottom: 0;
  }
}
.header__icon_explainer_mobile {
  display: block;
  position: absolute;
  right: 8px;
  top: 1px;
  font-size: 24px;
  color: #474342;
}
:global(.listing_all-steps-view) .header__icon_explainer_mobile {
  top: 8px;
}
@media (min-width: 768px) {
  .header__icon_explainer_mobile {
    display: none;
  }
}
.mdl :global(.mdl__cross) {
  width: 52px;
  height: 52px;
}
