.modal {
  padding: 0;
  margin: 0 auto;
  border-radius: 0;
  max-height: 100%;
  min-height: 100%;
  overflow: hidden;
  width: 100%;
  --height: 116px;
}
.modal.withoutMenu {
  --height: 66px;
}

.header {
  box-sizing: border-box;
  height: var(--height);
  position: relative;
  z-index: 1;
}

.menu {
  overflow-x: auto;
}
.content {
  height: calc(100vh - var(--height));
  overflow-y: auto;
}

@media (min-width: 768px) {
  .container {
    --height: 144px;
  }
}
