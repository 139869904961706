
.modal {
  composes: global(col-12) global(col-m-8) global(col-l-6);
  max-width: 66.6666666667%;
  margin: 0 auto;
  padding: 8px 16px 16px;
}

@media (max-width: 324px) {
  .buttons {
    gap: 16px 0;
    justify-content: center;
  }

  .buttonCancel {
    order: 1;
  }
}


@media (min-width: 768px) {
  .modal {
    max-width: calc(66.6666666667% - 28px);
    padding: 12px 56px 28px;
  }
  .title {
    padding: 32px 0 16px;
  }
}

@media (min-width: 1024px) {
  .modal {
    max-width: calc(50% - 46px);
  }
}

@media (min-width: 1440px) {
  .modal {
    max-width: calc(33.3333333333% - 66px);
  }
}