.container {
  position: relative;
}

.description {
  position: relative;
  overflow: hidden;
}

.pseudoDescription {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
}

.isAllowableHeight::after {
  position: absolute;
  width: 100%;
  height: 56px;
  bottom: 0;
  left: 0;
  content: '';
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.82) 48.44%, #FFF 100%);
  pointer-events: none;
}

.descPlainStyle {
  composes: global(body--l);
  color: var(--c-tertiary-light);
}

.descHtmlStyle a {
  font-size: inherit !important;
}

.descHtmlStyle p {
  color: var(--c-tertiary, #474342);
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0;
}

.moreLessButton {
  font-weight: bold;
}
