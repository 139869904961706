.interval {
    display: flex;
    align-items: center;
    padding: 0;
    height: 40px;
    line-height: 40px;
}

@media (min-width: 1024px) {
    .interval {
        justify-content: center;
    }
}

.text {
    color: var(--c-tertiary);
}

.reset {
    cursor: pointer;
    margin-left: 4px;
}

@media (min-width: 1440px) {
    .text {
        font-size: 24px;
        line-height: 36px;
    }
    
}