.bookingWidgetBar {
  display: flex;
  align-items: center;
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  height: 92px;
  padding: 20px 16px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  background: #fff;
}

.bookingWidgetBarLeftColumn {
  border-right: 1px solid #c4cdd5;
  margin-right: 16px;
  padding-right: 16px;
}

.bookingWidgetBarRightColumn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bookingWidgetBarDiscountInfo {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
}
.bookingWidgetBarPrice {
  font-size: 18px;
  line-height: 27px;
  color: #656d76;
  text-decoration-line: line-through;
  margin-right: 11px;
}
.bookingWidgetBarTotal {
  display: flex;
  align-items: baseline;
  font-size: 20px;
  line-height: 30px;
  color: #212b36;
  font-weight: bold;
  margin-right: 12px;
}

.bookingWidgetBarTotalText {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  white-space: nowrap;
  color: #212b36;
  margin-left: 8px;
}
.bookingWidgetBarTotalTextMin {
  display: none;
}
.bookingWidgetBarDiscount {
  width: 54px;
  height: 28px;
  background: #39842b;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #ffffff;
}

.bookingWidgetBarDuration {
  display: flex;
  white-space: nowrap;
  font-size: 14px;
  line-height: 21px;
  color: #656d76;
}
.bookingWidgetBarDateContainer {
  width: 220px;
}

.bookingWidgetBarDateTitle {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  color: #212b36;
}

.bookingWidgetBarDateInfo {
  font-size: 16px;
  line-height: 24px;
  color: #212b36;
}

.bookingWidgetBarButtonContainer {
  width: 130px;
}

.bookingWidgetBarButton {
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 767.98px) {
  .bookingWidgetBar {
    height: 84px;
    padding: 16px 16px;
  }
  .bookingWidgetBarLeftColumn {
    border-right: none;
    width: 100%;
    min-width: 110px;
    padding: 0;
    margin: 0;
  }

  .bookingWidgetBarRightColumn {
    width: auto;
  }
  .bookingWidgetBarPrice {
    font-size: 18px;
    line-height: 27px;
    position: relative;
    bottom: -1px;
    margin-right: 0;
  }
  .bookingWidgetBarTotal {
    font-size: 18px;
    line-height: 27px;
    order: -1;
    margin-left: 0;
    width: 100%;
  }

  .bookingWidgetBarTotalText {
    display: none;
  }

  .bookingWidgetBarTotalTextMin {
    display: inline-flex;
    font-size: 14px;
    line-height: 21px;
    color: #212b36;
    font-weight: normal;
    margin-left: 4px;
  }
  .bookingWidgetBarDiscountInfo {
    white-space: normal;
    flex-wrap: wrap;
  }
  .bookingWidgetBarDiscount {
    width: 49px;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    margin-left: 8px;
  }

  .bookingWidgetBarDuration {
    display: none;
  }
  .bookingWidgetBarDateContainer {
    display: none;
  }
}
