.container {
  background: #F4F6F8;
  border-radius: 4px;
  width: 100%;
}

.links {
  display: block;
}

.link {
  color: #C1122A;
  cursor: pointer;
}
.price {
  font-weight: normal;
}
.sup {
  font-size: 10px;
}

.tiiLink {
  font-size: inherit;
  line-height: inherit;
  padding: 0;
}

@media screen and (max-width: 767.98px) {
  .container {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .content {
    display: block;
  }
  .priceContainer {
    display: inline-block;
  }

  .link {
    display: block;
  }
}
