.map {
  height: 320px;
  border-radius: 4px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .map {
    width: 100%;
    height: 552px;
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .map {
    height: 474px;
  }
}

@media (min-width: 1680px) {
  .map {
    height: 570px;
  }
}
