.popper {
  background: var(--widget-card-popup-color, #fff) !important;
  margin-top: 4px;
  margin: 4px 16px 0 -16px;
  min-width: 500px;
}

.checkboxLabel {
  color: var(--widget-text-color, #474342);
  white-space: nowrap;
}

.checkboxPseudoCheck {
  border: 1px solid var(--widget-text-border-2-color, #747272);
}

.checkboxPseudoCheck::before {
  border-color: var(--widget-card-popup-color, #fff) !important;
}

.checkboxDark input:checked + span {
  background-color: #bfc5ce !important;
  border-color: #bfc5ce !important;
}

.checkboxDark input + span:hover,
.checkboxDark input:focus + span,
.checkboxDark:hover input + span {
    border-color: #bfc5ce !important;
}
.checkboxDark input:checked + span::before {
  border-color: #2c2a29 !important;
}

.title {
  margin-bottom: -20px;
  color: var(--widget-text-color, #474342);
}
@media screen and (min-width: 720px) {
  .row {
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 16px;
  }
  .col {
    margin-top: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .popper {
    margin: 4px 12px 0 0px;
  }

  .row {
    grid-gap: 0 24px;
  }
}


