.rentalMileageItems {
  display: grid;
  grid-gap: 8px 0;
}

.rentalMileageItem {
  background: var(--c-grey-cool, #eee);
  border-radius: 8px;
  padding: 16px;
  display: grid;
  align-content: space-between;
}

.rentalMileageItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rentalMileageItemHeaderSubtitle {
  color: var(--c-tertiary-light, #7F7F7F);
}

.rentalMileageItemHeaderTitle {
  font-weight: 500;
}

.rentalMileageItemStatusIcon svg {
  width: 24px;
  height: 24px;
}

.rentalMileageItemStatusIcon svg path {
  fill: var(--c-primary);
}

.subheaderButton {
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500;
}

.rentalMileageItemLink {
  font-weight: 500;
  padding: 8px 0;
}

.rentalMileageItemOverageTitle {
  white-space: nowrap;
}

.rentalMileage__feeTag > span {
  margin-left: 0;
}

.outerLink {
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  font-weight: 400;
}

@media (max-width: 767.98px) {
  .modalAddMileage {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }
  .modalAddMileageInner {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .modalAddMileage {
    padding: 12px 56px 28px;
    width: 496px;
    margin: 0 auto;
  }
  
  .titleAdditionalFeeContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .rentalMileage__feeTag > span {
    margin-left: 8px;
  }
  .titleAdditionalFee {
    padding-top: 0;
  }
  .rentalMileageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .rentalMileageHeaderTitle {
    font-weight: 500;
  }
  
  .rentalMileageItems {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 16px;
  }

  .rentalMileageItem {
    padding: 16px 32px;
  }

  .rentalMileageItems.isHaveOverage {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0 8px;
  }

  .rentalMileageItems.isHaveOverage .rentalMileageItem {
    padding: 16px 16px;
  }
}

@media (min-width: 1024px) {
  .rentalMileageItem {
    padding: 16px 32px;
  }
}

.inputContainer {
  width: 288px;
}
.inputFieldAttachedTo {
  width: calc(100% - 72px);
}
.inputFieldAttached {
  width: 72px;
}
.inputFieldAttachedTo :global .field-input__field {
  border-radius: 4px 0px 0px 4px;
}
.inputFieldAttached :global .field-input__field {
  background: #eaecef !important;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #bfc5ce !important;
  border-left: 1px solid transparent !important;
}
