.item:last-child > .itemDevider {
    display: none;
}

.colPrice {
    white-space: nowrap;
    text-align: right;
    padding: 0 !important;
}

@media (max-width: 767.98px) {
    .itemRow {
        flex-direction: column;
        align-items: stretch;
    }

    .itemRow {
        flex-wrap: wrap;
    }

    .colPrice {
        text-align: left;
    }
}
