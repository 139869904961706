.showOptionButtonMarginTop {
  margin-top: 5px;
}

.showOptionButtonMarginTopNegative {
  margin-top: -3px;
}

.showOptionButton {
  font-weight: bold;
}

.chevron > svg {
  width: 24px;
  height: 24px;
}

.chevron path {
  fill: var(--c-primary);
}

@media (min-width: 768px) {
  .showOptionButtonMarginTop {
    margin-top: 0px;
  }
}
