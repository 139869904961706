.container {
  composes: global(mb-32);
  position: relative;
  outline: none;
}
/* .containerNoImg {
    composes: container;
    position: relative;
    outline: none;
} */

/* @media (min-width: 768px) {
    .containerNoImg::before {
        padding-top: calc(var(--image-aspect-ratio, 0.75) * 100%);
    }
} */

.containerImgAdded {
  composes: container;
}

.inner1 {
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
}
.inner1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: transparent;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23C4CDD5FF' stroke-width='1' stroke-dasharray='12%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  pointer-events: none;
}
/* .containerNoImg .inner1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
} */

.acceptedInner1 {
  composes: inner1;
}

.inner2 {
  position: relative;
  border: 32px solid var(--c-grey-cool, #EEE);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  padding: 16px;
}
@media (max-width: 767px) {
  .inner2 {
    border-width: 16px;
  }
}
.containerImgAdded .inner2 {
  border-width: 16px;
}
/* .containerNoImg .inner2 {
    height: calc(100% + 22px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

.acceptedInner2 {
  composes: inner2;
  border-color: rgba(80, 184, 60, 0.2);
}

.text {
  composes: global(subtitle) global(subtitle--s);
}

.button {
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
}

.button span {
  color: var(--c-primary);
}

.button:hover span {
  color: var(--c-primary-light);
}

.hint {
  color: var(--tertiary-light, #7F7F7F);
  composes: global(body--l);
}

.uploadIcon {
  display: block;
  width: 100%;
  height: 97px;
  background: url("./upload-icon.svg") no-repeat 50% 50%;
  background-size: contain;
}
@media (min-width: 768px) {
  .containerImgAdded .inner2 {
    border-width: 32px;
  }
  .uploadIcon {
    height: 136px;
  }
}
