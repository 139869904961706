.root {
  z-index: 3002;
}

.modalRoot {
  z-index: 3002;
}

.modalRoot:before {
  display: none !important;
}

.modal {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  padding-bottom: 200px;
  border-radius: 0;
  max-height: 100%;
  overflow-y: auto;
}
.crossButton {
  position: fixed;
  right: var(--cwrr-scroll-width, 0);
  width: 52px;
  height: 52px;
  border-top-right-radius: 0px;
}
.pickUpDeliveryModal {
}
.pickUpDeliveryModalFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: var(--cwrr-scroll-width, 0);
  z-index: 999;
  background: #ffffff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  height: 84px;
}

.pickUpDeliveryModalFooterRow {
  height: 100%;
}
.pickUpDeliveryModalFooterRowInner {
  height: 100%;
  width: 100%;
}
.pickUpDeliveryModalFooterContent {
  align-items: center;
  height: 100%;
  flex-wrap: nowrap;
}
.pickUpDeliveryModalFooterRowContainer {
  height: 100%;
}
.pickUpDeliveryModalFooterText {
}
.pickUpDeliveryModalNote {
  color: #656d76;
}

.pickUpDeliveryModalTitle {
  font-size: 32px;
  color: #474342;
}
.pickUpDeliveryModalFooterBtns {
  display: flex;
  justify-content: flex-end;
}

.pickUpDeliveryModalFooterBtnsCancel {
  margin-right: 24px;
}
.autocompleteField {
  width: 100%;
  background: #ffffff;
  border: 1px solid #bfc5ce;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #474342;
  padding-top: 23px;
  padding-left: 11px;
  outline: none;
}

.autocompleteField:focus + .autocompleteLabel,
.autocompleteLabel.autocompleteLabelFull {
  color: #474342;
  left: 12px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(2px) translateX(-6px) scale(0.8);
}

.autocompleteField:focus {
  border-color: #474342;
}
.autocompleteLabel {
  pointer-events: none;
  position: absolute;
  left: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #474342;
  font-size: 16px;
  line-height: 24px;
  transform: translateY(59%);
}
.autocompleteContainer {
  position: relative;
}

.messageErrorText {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.cost {
  margin-top: -6px;
}

.locationState {
}

.rowDescription {
  margin-top: -16px;
}
@media screen and (min-width: 320px) and (max-width: 767.98px) {
  .modal {
    padding-bottom: 141px;
    border-radius: 0;
  }

  .main {
    margin: 0;
    order: -1;
    width: 100%;
  }
  .rightColumn {
    order: -1;
    padding: 0;
  }

  .rightColumnInner {
    margin: 0 -16px;
  }

  .pickUpDeliveryModalContainer {
    padding-top: 17px;
  }
  .pickUpDeliveryModalCityStateContainer {
    margin-top: 6px;
  }
  .pickUpDeliveryModalCityState {
    font-size: 16px;
    line-height: 24px;
  }
  .radioButton {
    margin-top: 17px;
    padding-bottom: 5px;
  }
  .radioButtonDelivery {
    margin-top: 20px;
    padding-bottom: 4px;
  }
  .radioButton span {
    color: #474342;
  }
  .radioButton span:first-child {
    margin-top: 3px;
  }
  .pickUpDeliveryModalNoteDeliveryFee {
    font-size: 16px;
    line-height: 24px;
    color: #757272;
  }
  .pickUpDeliveryModalNote {
    font-size: 14px;
    line-height: 21px;
    color: #656d76;
    padding-top: 4px;
  }
  .autocompleteContainer {
    margin-top: 20px;
  }
  .deliveryFeeText {
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    margin-top: 20px;
  }
  .deliveryFeeText b {
    margin-left: 15px;
  }
  .messageError {
    margin-top: 16px;
  }
  .messageErrorText {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }
  .pickUpDeliveryModalFooterText {
    display: none;
  }

  .title {
    width: 100%;
    padding-left: 8px;
    margin-bottom: 8px;
  }
  .pickUpDeliveryModalTitle {
    font-size: 24px;
    width: 100%;
  }
  .pickUpDeliveryModalFooter {
    height: 84px;
  }
  .pickUpDeliveryModalFooterBtns {
    justify-content: center;
  }
  .pickUpDeliveryModalFooterBtnsCancel {
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .main {
    display: flex;
    justify-content: center;
  }

  .main {
    margin: 0;
    order: -1;
    width: 100%;
  }
  .rightColumn {
    order: -1;
    padding: 0;
  }

  .rightColumnInner {
    margin: 0 -16px;
    padding: 0;
  }

  .pickUpDeliveryModalContent,
  .pickUpDeliveryModalFooterRow {
    margin: 0 auto;
  }
  .pickUpDeliveryModalFooterBtns {
    justify-content: flex-end;
  }

  .pickUpDeliveryModalMapContainer {
    display: none;
  }
  .title {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 8px;
  }
  .pickUpDeliveryModalTitle {
    font-size: 28px;
    padding-top: 32px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .pickUpDeliveryModalCityState {
    font-size: 18px;
    line-height: 27px;
  }
  .pickUpDeliveryModalNoteDeliveryFee {
    font-size: 18px;
    line-height: 27px;
    color: #757272;
  }
  .pickUpDeliveryModalFooter {
    height: 92px;
  }
  .pickUpDeliveryModalFooterBtnsCancel {
    margin-right: 16px;
  }

  .radioButton {
    padding-top: 4px;
    padding-bottom: 10px;
  }

  .radioButtonDelivery {
    padding-top: 9px;
    padding-bottom: 0px;
  }

  .deliveryFeeText {
    font-size: 18px;
    line-height: 27px;
    margin-top: 28px;
  }
  .deliveryFeeText b {
    margin-left: 14px;
  }
  .messageErrorText {
    white-space: nowrap;
  }

  .rowDescription {
    margin-top: -20px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
  .pickUpDeliveryModalFooterText {
  }
  .pickUpDeliveryModalFooterBtns {
    justify-content: flex-end;
  }
  .pickUpDeliveryModalTitle {
    font-size: 28px;
    padding: 53px 0 12px 0;
  }
  .radioButton {
    padding-bottom: 9px;
  }
  .radioButtonDelivery {
    padding-top: 9px;
    padding-bottom: 0px;
  }
  .deliveryFeeText {
    font-size: 18px;
    line-height: 27px;
    margin-top: 28px;
  }
  .pickUpDeliveryModalCityState {
    font-size: 18px;
    line-height: 27px;
  }
  .pickUpDeliveryModalNoteDeliveryFee {
    font-size: 18px;
    line-height: 27px;
    color: #757272;
  }
  .deliveryFeeText b {
    margin-left: 14px;
  }
  .modal {
    padding-bottom: 200px;
  }
  .pickUpDeliveryModalFooter {
    height: 92px;
  }
  .pickUpDeliveryModalFooterBtnsCancel {
    margin-right: 24px;
  }
  .messageErrorText {
    white-space: nowrap;
  }

  .rowDescription {
    margin-top: -20px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919.98px) {
  .pickUpDeliveryModalTitle {
    font-size: 32px;
    line-height: 48px;
    padding: 53px 0 12px 0;
  }
  .pickUpDeliveryModalCityState {
    font-size: 18px;
    line-height: 27px;
  }
  .pickUpDeliveryModalNoteDeliveryFee {
    font-size: 18px;
    line-height: 27px;
    color: #757272;
  }
  .radioButton {
    padding-bottom: 9px;
  }
  .radioButtonDelivery {
    padding-top: 7px;
    padding-bottom: 0px;
  }
  .deliveryFeeText {
    font-size: 18px;
    line-height: 27px;
    margin-top: 28px;
  }
  .deliveryFeeText b {
    margin-left: 14px;
  }
  .messageErrorText {
    white-space: nowrap;
  }
  .rowDescription {
    margin-top: -20px;
  }
}
@media screen and (min-width: 1440px) {
  .pickUpDeliveryModalFooter {
    height: 92px;
  }
  .pickUpDeliveryModalFooterBtnsCancel {
    margin-right: 24px;
  }
}
@media screen and (min-width: 1680px) {
  .pickUpDeliveryModal {
    width: 1352px;
    padding-left: 0;
    padding-right: 0;
    max-width: 1352px;
    flex: 0 0 1352px;
    margin: 0 auto;
  }
  .pickUpDeliveryModalFooter {
    height: 92px;
  }

  .pickUpDeliveryModalFooterRowContainer {
    width: 1352px;
    padding-left: 0;
    padding-right: 0;
    max-width: 1352px;
    flex: 0 0 1352px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1920px) {
  .pickUpDeliveryModalContainer,
  .pickUpDeliveryModal,
  .pickUpDeliveryModalFooterRowInner {
    margin: 0 auto;
  }

  .pickUpDeliveryModalTitle {
    font-size: 32px;
    line-height: 48px;
    padding: 53px 0 12px 0;
  }
  .pickUpDeliveryModalNote {
  }
  .pickUpDeliveryModalCityState {
    font-size: 18px;
    line-height: 27px;
  }
  .pickUpDeliveryModalNoteDeliveryFee {
    font-size: 18px;
    line-height: 27px;
    color: #757272;
  }
  .radioButton {
    padding-bottom: 9px;
  }
  .radioButtonDelivery {
    padding-top: 7px;
    padding-bottom: 0px;
  }
  .deliveryFeeText {
    font-size: 18px;
    line-height: 27px;
    margin-top: 28px;
  }
  .deliveryFeeText b {
    margin-left: 14px;
  }
  .messageErrorText {
    white-space: nowrap;
  }
}
