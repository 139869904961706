.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #ffffff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
}

.footer__content {
  display: flex;
  justify-content: center;
  align-items: center;
  composes: global(t-16) global(b-16) global(t-m-20) global(b-m-20);
}

@media only screen and (min-width: 768px) {
  .footer__content {
    justify-content: flex-end;
  }
}
