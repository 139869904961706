
.root {
    align-items: center;
    opacity: 0;
    transition: opacity 300ms;
    transition-duration: 300ms;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: var(--c-quaternary-light);
    pointer-events: none;
}

.root:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 20;
    right: 0;
    top: 0;
    left: 0;
    height: 32px;
    transform: translateY(-100%);
    background: linear-gradient(0deg, var(--c-quaternary-light) 0%, var(--c-quaternary-light-transparent) 100%);
    opacity: 1;
    transition: opacity 300ms;
}

.isScrollAtEnd:before {
    opacity: 0;
}

.isActive {
    opacity: 1;
}

.root img {
    width: 32px;
    height: 32px;
}
