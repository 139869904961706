.container {
  display: grid;
  place-content: center;
  height: 100vh;
}

.title {
  font-size: 20px;
  line-height: 30px;
}

.subtitle {
  color: var(--c-tertiary);
}

.buttons {
  margin-top: 20px;
  padding: 0 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px 32px;
}
