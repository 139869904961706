@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.root {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0px;
    z-index: 990;
    animation: fade-in 300ms;
    max-width: 840px;
}

.paper {
    position: relative;
    background-color: #ffffff !important;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25) !important;
    border-radius: 8px !important;
    padding: 12px 32px;
}

.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

@media (max-width: 1024px) {
    .root {
        position: fixed;
        right: 16px;
        left: 16px;
        max-width: 840px;
    }
}
