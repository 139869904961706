.buttonWrapper {
    overflow: hidden;
}

.clearIcon {
    font-size: 1.5em;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.clearIcon:hover {
    color: var(--c-primary);
}

.buttonWithClear .button {
    padding-right: 48px;
}

.buttonWithClear :global(.btn--size--medium) {
    padding-right: 44px;
}

.buttonWithClear :global(.btn--size--medium) .clearIcon {
    right: 4px;
}

.button.buttonDoubleBorder {
    border: 2px solid #747272;
}

.button {
    border: 1px solid #BFC5CE;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
    .buttonDoubleBorder {
        padding: 11px 15px;
    }
}
@media (min-width: 1024px) and (max-width: 1439.98px) {
    .buttonDoubleBorder {
        padding: 16px 23px;
    }
}

@media (min-width: 1440px){
    .buttonDoubleBorder {
        padding: 16px 15px;
    }
    .isMapClosed .buttonDoubleBorder {
        padding: 16px 23px;
    }
}

@media (min-width: 1680px){
    .buttonDoubleBorder {
        padding: 16px 23px;
    }
}