.root {
    padding: 24px;
    border: 1px solid var(--c-grey, #D5D5D5) !important;
}

.root.rootChecked {
    border: 1px solid transparent !important;
}

.tick {
    top: 24px;
    right: 24px;
}

.imageWrap {
    height: 60px;
    display: flex;
    align-items: center;
}

.image {
    display: block;
    width: auto;
    height: 100%;
    max-height: 60px;
}

@media (max-width: 767.98px) {
    .root {
        padding: 16px 24px;
    }
}
