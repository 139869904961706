.rentalDetailsStatusContainer {
  margin-bottom: -6px;
}

.messageBlock {
  display: flex;
}

@media (min-width: 768px) {
  .messageBlock {
    justify-content: space-between;
  }

  .action {
    padding-left: 32px;
  }

  .button {
    padding: 16px 0;
    font-size: 18px;
    line-height: 27px;
  }
}
