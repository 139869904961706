.modalCarouselCrossButton {
  right: 0 !important;
  top: 0 !important;
}

.sliderDotsList {
    padding: 0 !important;
}

.slideContainer {
  height: calc(100vh - 60px);
}

.slideInner {
  height: 100%;
  width: 100%;
}

.commentButton {
  width: 32px;
  height: 32px;
  padding: 0px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 11px;
  left: 11px;
  z-index: 1;
}

.commentButtonNotChecked path {
  fill: var(--c-primary, red);
}

.slide {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px 0;
  grid-template-rows: max-content minmax(min-content, 1fr) max-content;
  height: 100%
}

.downloadButton {
  width: 32px;
  height: 32px;
  padding: 0px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 11px;
  right: 11px;
  z-index: 1;
}

.position {
  text-align: center;
}

.photoContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--c-tertiary-dark);
}

.photoContainer::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 54px;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  transform: rotate(-180deg);
  z-index: 1;
}

.shadowBottom {
  display: none;
}

.photo {
  width: auto;
  height: auto;
  max-height: 100%;
  position: absolute;
  object-fit: cover;
}

@media (min-width: 768px) {
  .modalCarouselCrossButton {
    top: 20px !important;
  }

  .slideContainer {
    display: flex;
    align-items: center;
    height: calc(100vh - 130px);
    margin-top: 60px;
  }

  .slideInner {
    height: calc(100vh - 0px);
    width: 100%;
    aspect-ratio: 4/3;
  }

  .slide {
    grid-template-rows: 1fr max-content;
    height: 100%;
  }

  .positionContainer {
    grid-row: 2 / 3;
  }

  .photo {
    max-height: 100%;
  } 

  .photoContainer {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
  }

  .photoContainer::before {
    height: 100px;
  }

  .shadowBottom {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 240px;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.0001) 100%);
    z-index: 1;
    display: block;
  }

  .descriptionContainer {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    position: relative;
  }

  .description {
    position: absolute;
    bottom: 40px;
    padding: 0 32px;
    color: #fff;
    z-index: 2;
  }
}
@media (min-width: 1024px) {
  .slideContainer {
    margin-top: 18px;
    height: calc(100% - 0px);
  }

  .slideInner {
    height: calc(100vh - 72px);
  }
} 

@media (min-width: 1440px) {
  .slide {
    height: calc(100% - 0px);
    margin-top: 2px;
  }

  .slideContainer {
    height: calc(100vh - 66px);
  }

  .slideInner {
    height: calc(100vh - 60px);
  }
} 

@media (min-width: 1680px) {
  .slide {
    height: calc(100% - 0px);
    margin-top: 0px;
  }

  .slideContainer {
    height: calc(100vh - 70px);
  }

  .slideInner {
    height: calc(100vh - 69px);
  }
}
