

.headerInner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.headerInnerItem {
  grid-row: 1;
  position: relative;
}
.headerInnerItem:first-child {
  grid-column: 1/-1;
}

.headerInnerItem:last-child {
  grid-column: 2/-1;
  position: relative;
  z-index: 1;
}
.headerInnerItem picture {
  min-height: 286px;
}
.headerInnerItem picture source,
.headerInnerItem img {
  object-fit: cover;
  object-position: 0 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.getReadyTitle {
  margin-top: -38px;
}

.amenitiesTitleText {

}
@media (min-width: 768px){
  .headerInnerItem picture source,
  .headerInnerItem img {
    object-position: 50% 50%;
  }
}
@media (min-width: 1024px){
  .headerInnerItem picture {
    padding-left: 32px;
    min-height: 560px;

  }
  .headerContent {
    padding: 60px 122px 60px 92px;
    box-sizing: border-box;
    min-height: 560px;
    background: linear-gradient(
      180deg, rgba(196, 205, 213, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
  }
}

@media (min-width: 1440px){
  .headerContent {
    padding: 69px 182px 60px 120px;
  }
  .getReadyTitle {
    margin-top: -48px;
  }
}

@media (min-width: 1680px){
  .headerContent {
    padding: 70px 212px 120px 148px;
  }
}