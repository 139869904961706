
.table, .subTable, .navTable {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}
.displayContents {
  display: contents;
}
.table {
  display: block;
}

.row {
  display: table-row;
  height: 100%;
}
.col {
  display: table-cell;
  vertical-align: top;
  padding: 0;
  height: 100%;
}
.col:not(:first-child) .colContent {
  justify-content: flex-end;
  padding-left: 16px;
}
.colInner::before {
  content: '';
  display: block;
  margin: 8px 0;
  width: 100%;
  height: 1px;
  background: repeating-linear-gradient(to right, #c4cdd5 0px, #c4cdd5 8px, transparent 8px, transparent 16px);
}
.colInner {
  display: grid;
  height: 100%;
  grid-template-areas: "before"  
                       "content"  
                       "after"; 
  grid-template-rows: auto 1fr auto;
}

.colInner::before {
  grid-area: before;
}
.colInner .colContent {
  grid-area: content;
}
.colInner::after {
  grid-area: after;
}
.colContent {
  display: flex;
  align-items: center;
}

/* Extra classes for table container ("extraTableContainerClasses" prop) */
.tableContainer.db {
  display: block;
}
.tableContainer.dn {
  display: none;
}

/* subRowsTable */
.subRowsTable {
  display: table-row-group;
}
.subRowsContent {
  display: inline-flex;
  cursor: pointer;
}
.subRowsContentIcon {
  margin-left: 8px;
  transition: all 0.1s;
}
.subRowsContentIcon.hiddenSubRows {
  transform: rotate(180deg);
}
.subRowsTable .colInner {
  opacity: 1;
  max-height: 500px;
  transition: all 0.2s;
}

.subRowsTable.hiddenSubRows .colInner {
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  padding: 0;
}

/* Navigation Classes */
.navTable {
  display: block;
}
.navRow {
  position: relative;
  display: inline-flex;
  --cwrr-pipe-width: 0px;
  --cwrr-pipe-left: 0px;
} 
.navRow::before {
  content: '';
  position: absolute;
  bottom: 8px;
  left: 0;
  height: 4px;
  transition: all 0.25s;
  background-color: #c1122a;
  border-radius: 4px;
  width: var(--cwrr-pipe-width, 0);
  transform: translateX(var(--cwrr-pipe-left, 0));
  z-index: 1;
}

.navRow::after {
  content: '';
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: #E1E6E9;
}
.navRow .colContent {
  padding-top: 8px;
  padding-bottom: 12px;
}
.navRow .col:not(:first-child) .colContent {
  justify-content: center;
  white-space: nowrap;
}

.navRow .colText {
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
}

.navRow .colText:hover {
  color: #c1122a;
}

/* Extra classes for .row ("extraRowClasses" prop) */

.borderTopSolid .colInner::before,
.borderBottomSolid + .row .colInner::before,
.borderBottomSolid:last-child .colInner::after
 {
  background: #C4CDD5;
  content: '';
  display: block;
  margin: 8px 0;
  width: 100%;
  height: 1px;
}

.borderTopDashed .colInner::before,
.borderBottomDashed + .row .colInner::before,
.borderBottomDashed:last-child .colInner::after {
  background: repeating-linear-gradient(to right, #c4cdd5 0px, #c4cdd5 8px, transparent 8px, transparent 16px);
  content: '';
  display: block;
  margin: 8px 0;
  width: 100%;
  height: 1px;
}

.borderTopSolidWithoutMarginBottom .colInner::before {
  margin: 8px 0 0px;
}

.borderTopBottomNone .colInner::before,
.borderTopBottomNone + .row .colInner::before, 
.borderTopNone .colInner::before, 
.borderBottomNone + .row .colInner::before,
.navRow .colInner::before {
  display: none;
} 

.smallPadding .colContent {
  padding: 4px 0;
}
.smallPadding .colContent {
  padding: 4px 0;
}

.mediumPaddingBottom .colContent {
  padding-bottom: 8px;
}
.textPaddingBottomNone .colText {
  padding-bottom: 0;
}

.largePaddingBottom .colContent {
  padding-bottom: 16px;
}

.textMediumMarginBottomNegative .colText {
  margin-top: -4px;
}

.youEarn .col {
  background-color: var(--c-quaternary);
  padding: 12px 0;
}

.youEarn .col:nth-child(1) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 16px;
}

.youEarn .col:nth-child(1) .colText {
  font-weight: 700;
}

.youEarn .col:nth-child(2) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-right: 16px;
}

.adjustedValueRow .colContent{
  align-items: flex-start;
}

.adjustedValueRow .col:nth-child(2) .colText {
  text-align: right;
}

:global(.adjustButton) {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-primary);
  cursor: pointer;
}

:global(.calculatedPrice) {
  color: var(--c-tertiary-light);
}

/* Extra classes for title in col */
.xLargeText {
  padding: 20px 0 12px;
  font-size: 20px;
  line-height: 30px;
}

.xLargeText.textNormal {
  font-style: normal;
}

.boldText .colText {
  font-weight: bold;
}

.normalText .colText {
  font-weight: normal;
}


@media (min-width: 768px) {
  .table {
    display: table;
  }
  .subTable, .subTableTr, .subTableTd {
    display: contents;
  }
  .colInner::before {
    margin: 12px 0;
  }

  /* Extra classes for table container ("extraTableContainerClasses" prop) */
  .tableContainer.db-m {
    display: block;
  }
  .tableContainer.dn-m {
    display: none;
  }

  .tableContainer.narrow .row .col:first-child .colContent {
    padding-left: 30px;
  }
  .tableContainer.narrow .row .col:last-child .colContent {
    padding-right: 30px;
  }

  .rowIsNotNarrow .col:first-child .colContent {
    padding-left: 0px !important;
  }
  .rowIsNotNarrow .col:last-child .colContent {
    padding-right: 0px !important;
  }
  
  .tableContainer.narrow .subRowsContentIcon {
    order: -1;
    margin-left: -32px;
    margin-right: 8px;
  } 

  /* Navigation Classes */
  .navRow {
    display: table-row;
  }
  .navRow::after, .navRow::before {
    display: none;
  }
  .navRow .col {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 1%;
  }

  .navRow::before {
    content: '';
    display: table-cell;
    position: static;
    background: none;
    transition: none;
    width: 1%;
  }

  .navRow .colContent {
    justify-content: flex-end !important;
    height: 100%;
    padding: 0;
  }
  .navRow .colText {
    pointer-events: none;
  }

  .activeTab::after {
    display: none;
  }

  /* Extra classes for .row ("extraRowClasses" prop) */
  .borderTopSolid .colInner::before,
  .borderBottomSolid + .row .colInner::before,
  .borderBottomSolid:last-child .colInner::after,
  .borderTopSolid-db-m .colInner::before,
  .borderBottomSolid + .row .colInner::before,
  .borderBottomSolid:last-child .colInner::after,
  .borderBottomSolid-db-m + .row .colInner::before,
  .borderBottomSolid-db-m:last-child .colInner::after {
    background: #C4CDD5;
    content: '';
    display: block;
    margin: 12px 0;
    width: 100%;
    height: 1px;
  }

  .borderTopDashed-db-m .colInner::before,
  .borderTopDashed .colInner::before,
  .borderBottomDashed + .row .colInner::before,
  .borderBottomDashed:last-child .colInner::after,
  .borderBottomDashed-db-m + .row .colInner::before,
  .borderBottomDashed-db-m:last-child .colInner::after {
    background: repeating-linear-gradient(to right, #c4cdd5 0px, #c4cdd5 8px, transparent 8px, transparent 16px);
    content: '';
    display: block;
    margin: 12px 0;
    width: 100%;
    height: 1px;
  }

  .borderTopSolidWithoutMarginBottom .colInner::before,
  .borderTopSolidWithoutMarginBottom-db-m .colInner::before {
    margin: 12px 0 0px;
  }

  .borderTopSolid-dn-m .colInner::before,
  .borderBottomSolid-dn-m + .row .colInner::before,
  .borderBottomSolid-dn-m:last-child .colInner::after,
  .borderTopDashed-dn-m .colInner::before,
  .borderBottomDashed-dn-m + .row .colInner::before,
  .borderBottomDashed-dn-m:last-child .colInner::after {
    display: none;
  }

  .largePadding .colContent {
    padding: 16px 0;
  }

  .textMediumMarginBottomNegative .colText {
    margin-top: 0px;
  }

  .youEarn .col:nth-child(1) {
    padding-left: 0px;
  }
  
  .youEarn .col:nth-child(2) {
    padding-right: 0px;
  }

  .adjustedValueRow .colContent{
    align-items: flex-start;
  }

  .adjustedValueRow .col:nth-child(2) .colText {
    text-align: right;
  }

  :global(.adjustButton) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--c-primary);
    cursor: pointer;
  }
}
@media (min-width: 1024px) {
  /* Extra classes for table container ("extraTableContainerClasses" prop) */
  .tableContainer.db-l {
    display: block;
  }
  .tableContainer.dn-l {
    display: none;
  }

  /* Extra classes for .row ("extraRowClasses" prop) */
  .borderTopSolid-db-l .colInner::before,
  .borderBottomSolid-db-l + .row .colInner::before,
  .borderBottomSolid-db-l:last-child .colInner::after {
    background: #C4CDD5;
    content: '';
    display: block;
    margin: 12px 0;
    width: 100%;
    height: 1px;
  }

  .borderTopDashed-db-l .colInner::before,
  .borderBottomDashed-db-l + .row .colInner::before,
  .borderBottomDashed-db-l:last-child .colInner::after {
    background: repeating-linear-gradient(to right, #c4cdd5 0px, #c4cdd5 8px, transparent 8px, transparent 16px);
    content: '';
    display: block;
    margin: 12px 0;
    width: 100%;
    height: 1px;
  }

  .borderTopSolid-dn-l .colInner::before,
  .borderBottomSolid-dn-l + .row .colInner::before,
  .borderBottomSolid-dn-l:last-child .colInner::after,
  .borderTopDashed-dn-l .colInner::before,
  .borderBottomDashed-dn-l + .row .colInner::before,
  .borderBottomDashed-dn-l:last-child .colInner::after {
    display: none;
  }
}

@media (min-width: 1440px) {
  /* Extra classes for table container ("extraTableContainerClasses" prop) */
  .tableContainer.db-xl {
    display: block;
  }
  .tableContainer.dn-xl {
    display: none;
  }

  /* Other classes */
  .xLargeText {
    padding: 24px 0 12px;
    font-size: 24px;
    line-height: 36px;
  }
}

@media (min-width: 1680px) {
    /* Extra classes for table container ("extraTableContainerClasses" prop) */
    .tableContainer.db-xxl {
      display: block;
    }
    .tableContainer.dn-xxl {
      display: none;
    }
}