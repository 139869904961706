.modal {
  padding: 16px 16px 56px;
}

.title {
  text-align: center;
  padding: 25px 37px;
}

.crossButton {
  width: 52px;
  height: 52px;
}

.tickIconContainer {
  margin-top: 28px;
  text-align: center;
}

.tickIconContainer svg {
  width: 54px;
  height: 54px;
}

.subtitle {
  margin-top: 19px;
  text-align: center;
}

.description {
  color: var(--c-tertiary-light);
  text-align: center;
  font-size: 16px;
}

@media (min-width: 768px) {
  .modal {
    padding: 16px 16px 100px;
  }

  .title {
    margin-top: 12px;
  }

  .subtitle {
    font-size: 20px;
    line-height: 30px;
    margin-top: 28px;
  }

  .tickIconContainer {
    margin-top: 38px;
  }

  .tickIconContainer svg {
    width: 80px;
    height: 80px;
  }

}

@media (min-width: 1024px) {
  .modal {
    width: 960px;
  }

  .crossButton {
    width: 42px;
    height: 42px;
  }
}

@media (min-width: 1440px) {
  .modal {
    width: 1076px;
  }

  .subtitle {
    margin-top: 25px;
  }
}

@media (min-width: 1680px) {
  .modal {
    width: 1024px;
  }
}
