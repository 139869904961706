.table {
  border-collapse: collapse;
  border: 0;
  width: 100%;
  border-top: 1px solid #c4cdd5;
}

.row {
  position: relative;
}
.labelMemberSaving {
  display: flex;
  align-items: center;
}
.labelMemberSaving svg {
  width: 72px;
  height: 32px;
  margin-right: 3px;
  margin-top: 0px;
  position: relative;
  top: 2px;
}

.valueSpinner {
  display: block;
  transform: scale(.4, .4);
  margin-top: 12px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .rowFooter {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    min-height: 70px;
    padding: 0px 16px 0px;
    background-color: #fff;
    z-index: 200;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  }
}

.cell {
  /* padding: 13px 0 13px; */
}
.cellLabel {
  vertical-align: top;
}
.cellValue {
  text-align: right;

  vertical-align: top;
}

.cellValueMembership {
  vertical-align: top;
}
.cellHelper {
  color: #656d76;
}
.cellFooter {
  vertical-align: top;
}
@media (max-width: 767px) {
  .cellFooter {
    flex: 1 1 auto;
  }
}
/* .cell:first-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background: repeating-linear-gradient(
    to right,
    #c4cdd5 0px,
    #c4cdd5 8px,
    transparent 8px,
    transparent 16px
  );
} */

tbody .row:first-child .cell::before,
.rowHelper .cell::before {
  background: transparent;
}
.rowBeforeHelper .cell::before,
.rowHelper + .row .cell::before,
.cellFooter:first-child::before {
  background: #c4cdd5;
}
.rowBeforeHelper .cell {
  padding-bottom: 0;
}
.rowHelper .cell {
  padding-top: 0;
}

.cellContainer {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 13px 0 9px;
}

.cellContainer:first-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background: repeating-linear-gradient(
    to right,
    #c4cdd5 0px,
    #c4cdd5 8px,
    transparent 8px,
    transparent 16px
  );
}

.bigRow .cellValueContainer:first-child::before,
.bigRow .cellContainer:first-child::before {
  background: #c4cdd5;
}
tbody .row:first-child .cellContainer::before,
.rowHelper .cellContainer::before {
  background: transparent;
}

.cellValueContainer {
  text-align: right;
  justify-content: flex-end;
}

.rowFooter .cellContainer::before {
  background: #c4cdd5;
}
.cellHelper div,
.cellHelper span {
  font-size: 16px;
  line-height: 24px;
  display: block;
  padding-top: 0px;
  padding-bottom: 17px;
}

.label {
  display: flex;
  align-items: center;
}
.labelBtnContainer {
  margin-top: 12px;
  font-size: 14px;
  line-height: 21px;
}
.labelBtn {
  padding: 0 14px;
}

.colorInherit {
  color: inherit;
}

.btnLink {
  color: var(--c-primary);
}
.btnLink:hover {
  color: var(--c-primary-light);
}
.tooltipContainer {
  width: 210px;
}
.tooltipBtn {
  margin-left: 12px;
}
.valueTextMembership {
  position: relative;
}

.cellFooter .valueTextContainer {
  position: relative;
  top: 1px;
}
.valueTextContainer {
  position: relative;
}

.rowMembership .label {
  align-items: flex-start;
}
.totalText,
.totalValueText {
  font-size: 20px;
  line-height: 30px;
  color: #212b36;
}

.rowFooter .cellContainer {
  padding-top: 23px;
  padding-bottom: 0;
}

@media (max-width: 767.98px) {
  .rowMembership .tooltipBtn {
    position: relative;
    top: -4px;
  }
  .valueTextContainer {
    position: relative;
  }
  .valueTextMembership {
    position: relative;
    top: 1px;
  }
  /* .cellFooter {
    padding-top: 15px;
    padding-bottom: 0;
  } */

  .rowFooter .cellContainer {
    padding-top: 0px;
    padding-bottom: 0;
  }
  .labelText {
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
  }
  .valueText {
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    position: relative;
    bottom: -4px;
  }

  .cell {
    /* padding: 10px 0 9px; */
  }
  .cellContainer {
    padding: 8px 0 8px;
  }
  .cellHelper div,
  .cellHelper span {
    font-size: 16px;
    line-height: 24px;
    display: block;
    padding-top: 3px;
  }
  .totalValueText span {
    font-size: 20px;
    line-height: 30px;
  }
  .valueSimpleText {
    bottom: 0;
  }

  .rowFooter .cell:first-child::before {
    display: none;
  }
  .rowFooter .cellContainer::before {
    display: none;
  }
}
