.root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.list {
    width: 100%;
    height: calc(100% + 1px);
    margin-bottom: -1px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.empty {
    height: 100%;
}

.empty * {
    color: var(--c-tertiary-light);
}

.overlay {
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    transition: opacity 300ms;
    opacity: 1;
    pointer-events: none;
}

.overlayHidden {
    opacity: 0;
}
