.control {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid transparent !important;
}
@media (max-width: 767px) {
  .control {
    padding: 8px;
  }
}

.shadow.control {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.line.control {
  border: 1px solid var(--c-grey, #D5D5D5);

}

.control:hover {
  background: #F6FBF5;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid transparent !important;
}

.checkedControl {
  composes: control;
  background: #EDF8EB !important;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25) inset !important;
  border: 1px solid transparent !important;
}

.checkedControl.control:hover {
  background: #EDF8EB !important;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25) inset !important;
  border: 1px solid transparent !important;
}

.tick {
  display: none;
  position: absolute;
  z-index: 10;
  right: 12px;
  top: 12px;
  width: 32px;
  height: 32px;
  background: url(../Icon/svg/icon_tick.svg) 0 0 no-repeat;
  background-size: contain;
}
@media (max-width: 767px) {
  .tick {
    width: 24px;
    height: 24px;
    top: 8px;
    right: 8px;
  }
}

.tick :global(.icon) {
  width: 100%;
  height: 100%;
}

.checkedControl .tick {
  display: block;
}

.checkbox {
  composes: global(dn);
}
