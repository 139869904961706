.container {
  position: relative;
}
.dateIcon {
  position: absolute;
  right: 14px;
  top: 14px;
  font-size: 24px;
}
.placeholder {
  position: absolute;
  top: 23px;
  left: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #757272;
  opacity: 0;
  transition: .5s all;
  pointer-events: none;
}

.isShowPlaceholder {
  opacity: 1;
}