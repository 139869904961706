@keyframes zoom-in {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.popup {
    z-index: 400;
    position: fixed;
    background: #FFFFFF;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: hidden;
    transform: translateY(-100%);
    opacity: 0;
    transition: all 500ms;
    pointer-events: none;
}



@media (min-width: 1024px) {
    .popup {
        right: 40px;
        top: var(--cwrr-chat-top-position, 0px);
        width: 496px;
        height: 558px;
        max-height: calc(100vh - 40px - 96px);

    }

    .popup.popupFixed {
        top: 104px;
    }
}

/* @media (max-width: 1023.98px) {
    .popup {
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 492px;
        max-height: calc(100vh - 80px);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
} */

@media (max-width: 1023.98px) {
    .popup {
        /* top: 0; */
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        max-height: var(--chat-height, 100%);
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        z-index: 9999999;
    }
}

.popupOpen .popup {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
}

.popupOpen .button {
    opacity: 0;
    pointer-events: none;
}

.buttonContainer {
    z-index: 90;
    position: fixed;
    opacity: 1;
    transition: opacity 500ms;
}

@media (min-width: 1024px) {
    .buttonContainer {
        right: 32px;
        bottom: calc(32px + var(--cwrr-bottom-bar-height, 0px));
    }
}

@media (min-width: 1440px) {
    .buttonContainer {
        right: 40px;
        bottom: calc(40px + var(--cwrr-bottom-bar-height, 0px));
    }
}

@media (max-width: 1023.98px) {
    .buttonContainer {
        right: 16px;
        bottom: calc(16px + var(--cwrr-bottom-bar-height, 0px));
    }
}

.button {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25) !important;
    display: none !important;
}

.unreadIndicator {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 0 3px #ffffff;
    z-index: 10;
    background-color: var(--c-secondary);
    animation: zoom-in 300ms;
    display: none;
}

@media (max-width: 767.98px) {
    .unreadIndicator {
        top: 9px;
        right: 9px;
    }
}
