.main {
  flex-wrap: wrap;
}

.summary {
  width: 100%;
  flex: none;
  padding-left: 8px;
  padding-right: 8px;
}

.table {
  width: 100%;
  flex: none;
  padding-left: 8px;
  padding-right: 8px;
}

@media (min-width: 768px) {
  .summary {
    padding: 20px 8px 0;
  }

  .table {
    padding: 12px 8px;
  }
}

@media (min-width: 1024px) {
  .main {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  
  .summary {
    width: 330px;
    padding-left: 12px;
    padding-right: 0;
    box-sizing: content-box;
  }
  
  .table {
    flex: 1 0 auto;
    max-width: calc(100% - 342px);
    padding-left: 80px;
    padding-right: 16px;
  }
}

@media (min-width: 1440px) {
  .summary {
    width: 320px;
  }
  
  .table {
    max-width: calc(100% - 332px);
    padding-left: 110px;
  }
}

@media (min-width: 1680px) {
  .summary {
    width: 396px;
  }
  
  .table {
    max-width: calc(100% - 408px);
    padding-left: 136px;
  }
}