.wrapper {
  width: 500px;
  max-width: 100%;
}

@media (min-width: 768px) {
  .wrapper {
    width: 580px;
  }
}

@media (min-width: 1440px) {
  .wrapper {
    width: 760px;
  }
}
