.root {
  position: relative;
}

@media (min-width: 768px) and (max-width: 1439px) {
  .root::after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    content: "";
    width: 100px;
    background-image: linear-gradient(
      271.15deg,
      #ffffff 1.13%,
      rgba(255, 255, 255, 0) 99.21%
    );
    opacity: 0;
    visibility: hidden;
    transition: all 100ms;
  }

  .root.withGradient::after {
    opacity: 1;
    visibility: visible;
  }
}

.list {
  composes: global(dn) global(db-m) global(l-0) global(r-0) global(t-16) global(b-16) global(t-m-20) global(b-m-20);
  white-space: nowrap;
  overflow-x: auto;
  list-style: none;
  margin: 0;
}
:global(.no-touchevents) .list {
  scrollbar-width: thin;
  scrollbar-color: #656d76 transparent;
}
:global(.no-touchevents) .list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
}
:global(.no-touchevents) .list::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 2px;
}
:global(.no-touchevents) .list::-webkit-scrollbar-thumb {
  background-color: #656d76;
  border-radius: 2px;
}