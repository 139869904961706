
.popper {
    width: 736px;
    margin-left: 16px;
}

.root {
    overflow: hidden;
}

@media (min-width: 1024px) {
    .popper {
        width: 840px;
        margin-left: 32px;
    }
}

@media (min-width: 1230px) {
    .popper {
        margin-left: 0px;
    }
}
