.containerInner {
  display: grid;
  grid-template-columns: max-content max-content max-content;;
  grid-template-areas: 
    "rating rating"
    "name   btns";
}

.rating {
  grid-area: rating;
}
.name {
  grid-area: name;
}
.btns {
  grid-area: btns;
}

@media (min-width: 768px){
  .containerInner {
    grid-template-areas: "rating name btns";
  }
  .rating {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 1024px){
  .containerInner {
    grid-template-areas: 
    "rating name"
    "btns   btns";
  }
  .classText {
    font-size: 18px;
    line-height: 27px;
  }
}
@media (min-width: 1440px){
  .container {
    margin-top: -8px;
  }
  .containerInner {
    grid-template-areas: "rating name btns";
  }

}