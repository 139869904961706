.root {
    border-top: 1px solid rgba(196, 205, 213, 0.5);
}

.disabled {
    pointer-events: none;
}

.disabled .header {
    opacity: 0.5;
}

.disabled .headerIcon {
    opacity: 0.3;
}

.header {
    align-items: flex-start !important;
}

.headerIcon {
    composes: global(t-8) global(b-8);
    margin-top: 2px;
}
.headerIcon > svg {
    color: var(--widget-text-color, #474342);
}
.title {
    color: var(--widget-text-color, #474342);
}
.valueLabel {
    color: var(--widget-text-color, #474342);
}
.clearer {
    composes: global(mr-16);
    color: var(--c-primary);
    font-weight: bold;
}

.clearerDark {
    color: #eaecef;
}
