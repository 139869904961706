.requestHistory {
  border-bottom: 1px solid rgba(196, 205, 213, 0.5);
}
.labelMemberSaving {
  display: flex;
  align-items: center;
}
.labelMemberSaving svg {
  width: 72px;
  height: 16px;
  margin-right: 3px;
  margin-top: 3px;
}
.sup {
  font-size: 10px;
}

.tiiLink {
  font-size: inherit;
  line-height: inherit;
  padding: 0;
}

@media (max-width: 767.98px) {
  .requestHistory {
    border-top: 1px solid rgba(196, 205, 213, 0.5);
    border-bottom: 1px solid rgba(196, 205, 213, 0.5);
  }
  .action_buttons {
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) {
  .requestHistory {
    border: none;
  }
}

.requestHistory__heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 768px) {
  .requestHistory__heading {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.requestHistory__message {
    border-radius: 8px;
    background-color: var(--c-grey-cool);
}

.systemMessage__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 768px) {
  .systemMessage__info {
    flex-direction: row;
    justify-content: space-between;
  }
}

.systemMessage__mutedColor {
    color: var(--c-tertiary);
}

.systemMessage__mutedColorLight {
    color: var(--c-tertiary-light);
}

.requestMessage__price {
    padding-top: 0 !important;
}

.requestMessage__pricesLabel {
    padding-bottom: 0 !important;
}

.requestMessage__infoCell {
    min-width: 140px;
}

.requestMessage__infoCell__label p {
  color: var(--tertiary-light, #7F7F7F);
}

.requestMessage__infoCell__value p {
  color: var(--tertiary);
}

.requestMessage__infoCell__bold {
  color: var(--tertiary, #232323);
  font-weight: 500;
}

.requestMessage__infoValue {
    padding-top: 0 !important;
    font-weight: 400;
}

.requestMessage__infoValue__boldValue {
  font-weight: 500;
}

.requestMessage__topInfoLabel {
    padding-top: 0 !important;
}

.requestMessage__footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.systemMessage__date {
    margin-left: auto;
}
.action_buttons {
  justify-content: left; 
  gap: 0 12px;
}
@media (min-width: 768px) {
  .requestMessage__footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .action_buttons {
    justify-content: end;
    gap: 0 16px;
  }
}

.requestMessage__dash {
  align-self: flex-end;
  margin: 0 8px;
}

.requestMessage__info__left, .requestMessage__info_small {
  border-left: 2px solid var(--c-quaternary-dark);
}

.charge__wrapper {
  align-self: center;
}

.charge__hint {
  color: var(--c-tertiary-light);
  white-space: nowrap;
}
