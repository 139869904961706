.root {
  position: relative;
  background: rgba(196, 205, 213, 0.2);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 0 -20px;
  line-height: normal;
  cursor: pointer;
}

.rootText {
  position: relative;
  width: 100%;
  composes: global(body--l);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0;
  font-size: 14px;
  line-height: 21px;
}

.memberSavings {
  margin-left: 4px;
  font-weight: bold;
}
.becomeText {
  display: flex;
  align-items: center;
}

.smallTextIcon {
  margin-right: 4px;
  margin-left: 4px;
  width: 64px;
  height: 29px;
  position: relative;
}
.rootText.becomeAMemberNoMember {
  display: flex;
}
@media (min-width: 1024px) {
  .primary {
    color: var(--c-tertiary, #232323);

  }
}

@media (max-width: 767px) {
  .root {
    margin: 0 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

@media screen and (min-width: 0px) and (max-width: 767.98px) {
  .root {
    margin: 0px;
    border-radius: 8px;
    height: 184px;
    padding-top: 20px;
  }
  .chooseSubscription {
    margin: 0 auto;
    display: block;
    width: 232px;
  }

  .rootText {
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    padding: 0;
    margin-bottom: 20px;
  }

  .becomeText {
    justify-content: center;
  }

  .smallTextIcon {
    width: 73px;
    height: 32px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .root {
    margin: 0px;
    border-radius: 8px;
    height: 172px;
    padding-top: 19px;
  }
  .chooseSubscription {
    margin: 0 auto;
    display: block;
    width: 232px;
  }

  .rootText {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 1px;
  }

  .becomeText {
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .smallTextIcon {
    width: 73px;
    height: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .root {
    height: 49px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 0px -24px;
  }
  .smallTextIcon {
    width: 64px;
    height: 29px;
  }
  .rootText {
    padding: 10px 0;
    font-size: 14px;
    line-height: 21px;
    position: relative;
    top: -2px;
  }
  .chooseSubscription {
    display: none;
  }
}
@media screen and (min-width: 1680px) {
  .root {
    margin: 0px -32px;
  }
}
