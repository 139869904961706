
.buttonResetFilter,
.buttonResetFilter:disabled {
	border: 1px solid #BFC5CE;
}
.buttonResetFilter svg {
	color: #BFC5CE;
}

.buttonResetFilterActive {
	border: 1px solid #BFC5CE;
}
.buttonResetFilterActive svg {
	color: #474342;
}