.headerCover {
  height: 240px;
}
.headerCoverInner {
  height: 100%;
}
.headerCoverImg {
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px){
  .headerCover {
    height: 300px;
  }

  .profileInfoContainer {
    margin-top: -60px;
  }

  .profileInfoContainer.profileEmpty {
    margin-top: -120px;
  }
}