.root {
  composes: global(t-16) global(t-m-20);
  align-items: center;
  min-height: 92px;
}
.radioRow {
  display: inline-block;
}
@media (max-width: 767px) {
  .radioRow {
  }
}

.radioHide {
  composes: global(radio-row__item);
  display: block;
}
.radioShow {
  composes: global(radio-row__item);
}
.fieldsWrap {
  composes: global(t-0) global(t-m-4);
  display: block;
}
@media (min-width: 768px) {
  .fieldsWrap {
    display: flex;
    padding-left: 32px;
  }
}
.inputWrap {
  display: flex;
  align-items: center;
  width: 288px;
}
.inputWrap:not(:first-child) {
  margin-left: 0;
}
@media (min-width: 768px) {
  .inputWrap:not(:first-child) {
    margin-left: 24px;
  }
}
@media (min-width: 1680px) {
  .inputWrap:not(:first-child) {
    margin-left: 32px;
  }
}

.input {
  composes: global(mt-16) global(mb-16) global(mb-m-20) global(field-input--grouped);
}

.inputUnits {
  composes: global(field-units);
  width: 89px;
  flex: 0 0 auto;
}
