.listItem {
  margin-top: 16px;
}

.listItem:first-child {
  margin-top: 0;
}

@media (min-width: 768px) {
  .listItem {
    margin-top: 20px;
  }
}
