.content {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 1024px) {
    .content {
        max-width: 632px;
    }
}

@media (min-width: 1680px) {
    .content {
        max-width: 760px;
    }
}
