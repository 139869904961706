.popper {
    background: var(--widget-card-popup-color, #fff) !important;
}

.checkboxLabel {
    color: var(--widget-text-color, #474342);
}

.checkboxPseudoCheck {
    border: 1px solid var(--widget-text-border-2-color, #747272);
}

.checkboxPseudoCheck::before {
    border-color: var(--widget-card-popup-color, #fff) !important;
}

.checkboxDark input:checked + span {
    background-color: #bfc5ce !important;
    border-color: #bfc5ce !important;
  }

.checkboxDark input + span:hover,
.checkboxDark input:focus + span,
.checkboxDark:hover input + span {
    border-color: #bfc5ce !important;
}
  
.checkboxDark input:checked + span::before {
    border-color: #2c2a29 !important;
}