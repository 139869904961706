.listing {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 124px;
  composes: global(t-m-20);
  composes: global(t-l-40);
}
.hiddenBar {
  display: none;
}
@media (min-width: 768px) {
  .listing {
    padding-bottom: 152px;
  }
}
@media (min-width: 1440px) {
  .listing {
    padding-bottom: 212px;
  }
}
@media (max-width: 767px) {
  .listingAllStepsView.listingFooterHidden {
    padding-bottom: 40px;
  }
  :global(.listing_all-steps-view ._hidden-all-steps-view) {
    display: none;
  }

  :global(.listing_all-steps-view ._visible-all-steps-view) {
    display: block;
  }

  :global(.listing_step-view ._visible-step-view) {
    display: block;
  }

  :global(.btn-group_xs-fixed) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    padding: 16px 0;
    background: #ffffff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  }
}

.footerError {
  color: var(--c-status-error-dark);
}