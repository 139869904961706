.signUpInner {
  margin-left: auto;
  margin-right: auto;
}

.signinButton {
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #C1122A;
}

.phoneEditButton {
}

.fieldPhone :global(.field-input__helpers) {
  display: none;
}

@media screen and (min-width: 768px) {
  .signUpButtonContainer {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 1024px) {
  .signUpButtonContainer {
    justify-content: flex-start;
  }
}