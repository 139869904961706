/*
 * Sliders Styles
 */

.rc-slider {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0;
}

.rc-slider-step,
.rc-slider-track,
.rc-slider-rail {
  height: 8px;
}

.rc-slider-track {
  background: var(--c-primary);
}
.rc-slider-track__dark .rc-slider-track {
  background: var(--c-tertiary);
}
.rc-slider-rail {
  background: var(--c-quaternary-dark, #d5d5d5);
}

.slider--inverse-colors .rc-slider-track {
  background: var(--c-quaternary-dark, #d5d5d5);
}

.slider--inverse-colors .rc-slider-rail {
  background: var(--c-tertiary);
}

.rc-slider-handle {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  border: none;
  background: var(--c-primary-light);
  box-shadow: 0 0 0 3px #fff;
  transition: box-shadow 300ms, border 300ms;
  opacity: 1;
}

.rc-slider-handle:hover,
.rc-slider-handle:active,
.rc-slider-handle-click-focused:focus,
.rc-slider-handle-dragging,
.slider--dragging .rc-slider-handle {
  box-shadow: 0 0 0 3px #fff, 0 0 0 10px rgba(35, 65, 10, 0.1) !important;
}

.slider {
  padding: 0 10px;
}

.slider .rc-slider-rail {
  width: calc(100% + 20px);
  margin: 0 -10px;
}

.slider--is-single .rc-slider-track {
  left: -10px !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media (max-width: 767.98px) {
  .rc-slider {
    height: 42px;
  }

  .rc-slider-handle:before {
    content: "";
    display: block;
    position: absolute;
    width: 42px;
    height: 42px;
    left: -11px;
    top: -11px;
  }
}
