.root {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.5;
  composes: global(t-8) global(b-8);
  color: var(--c-status-error-dark);
}

.intro {
  font-weight: bold;
}
