.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 34px auto;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  color: var(--tertiary-light, #7F7F7F);
  white-space: nowrap;
}

.iconRental {
  margin-top: 11px;
}

.smallTextIcon {
  margin-left: 14px;
}
.insurance {
  display: flex;
}

.iconRentalProtection {
  height: 50px;
}

@media screen and (min-width: 768px) {
  .root {
    width: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 767.98px) {
  .iconRentalProtection {
    width: 166px;
    height: 43px;
  }
  .iconRental {
    margin-top: 6px;
  }
}
