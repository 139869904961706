.title,
.subtitle {
  color: #474342;
}

.subtitle {
  padding-bottom: 0;
}

.policyLinkContainer {
  margin-top: -6px;
}

@media (min-width: 768px) {
  .subtitle {
    padding-bottom: 12px;
  }
}

@media (min-width: 1440px) {
  .reasonContainer {
    margin-bottom: -4px;
  }
  .subtitle {
    font-size: 18px;
    line-height: 27px;
  }
}
