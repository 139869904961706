.headerRow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 0 16px;
  position: relative;
}

.headerRow.isSingleForm {
  grid-template-columns: 1fr auto;
}

.titleColumn {
  position: relative;
}
.valueColumn {
  justify-content: flex-end;
  display: grid;
}

.titleContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.valueContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.chevronIconContainer {
  margin-left: 8px;
}

.chevronIcon {
  transition: all 200ms;
  display: inline-flex;
  width: 24px;
  height: 24px;
}
.chevronDown {
  transform: rotate(180deg);
}

.chevronIcon, 
.questionIconContainer {
  cursor: pointer;
}
.chevronIcon > svg,
.questionIconContainer > svg {
  width: 24px;
  height: 24px;
}

.questionIconContainer > svg {
  color: #757272;
}

.questionIconContainer:hover > svg {
  color: #c1122a;
}

.cursorPointer {
  cursor: pointer;
}

.infoContainer {
  margin-left: 8px;
}
.formDescHtmlStyle {
  composes: global(body--l);
  color: #313A44;
}

.formDescHtmlStyle a {
  font-size: inherit !important;
  text-decoration: var(--link-underline-text-decoration);
  text-decoration-thickness: var(--link-underline-text-decoration-thickness);
  text-underline-offset: var(--link-underline-text-underline-offset);
  text-decoration-skip-ink: var(--link-underline-text-decoration-skip-ink);
}

.descPlainStyle {
  composes: global(body--l);
  color: var(--c-tertiary-light);
}

.headActionTagCommonStyle,
.headActionPlainCommonStyle,
.headPlainCommonStyle,
.headTagCommonStyle,
.subTitleL {
  font-size: 18px;
  line-height: 27px;
  padding: 8px 0 8px;
  font-weight: 500;
}

.headActionTagCommonStyle,
.headTagCommonStyle {
  font-weight: 400;
}

.regularActionPlainCommonStyle,
.regularPlainCommonStyle,
.regularActionTagCommonStyle,
.regularTagCommonStyle {
  composes: global(body--l);
}

.bodyLBold {
  font-weight: 500;
  composes: global(body--l);
}

.colorTextBodyLight {
  color: var(--c-tertiary-light);
}
.colorTextBody {
  color: var(--c-tertiary);
}

.statusSuccess {
  color: var(--c-status-success);
}

.primary {
  color: var(--c-primary);
}

.action, .actionButton {
  cursor: pointer;
  text-decoration: var(--link-underline-text-decoration);
  text-decoration-thickness: var(--link-underline-text-decoration-thickness);
  text-underline-offset: var(--link-underline-text-underline-offset);
  text-decoration-skip-ink: var(--link-underline-text-decoration-skip-ink);
}

.action:hover, .actionButton:hover {
  color: var(--c-primary-light);
}


.headActionPlainCommonStyle,
.regularActionPlainCommonStyle,
.regularActionTagCommonStyle,
.headActionTagCommonStyle {
  color: var(--c-primary);
  text-decoration: var(--link-underline-text-decoration);
  text-decoration-thickness: var(--link-underline-text-decoration-thickness);
  text-underline-offset: var(--link-underline-text-underline-offset);
  text-decoration-skip-ink: var(--link-underline-text-decoration-skip-ink);
}

.headActionPlainCommonStyle:hover,
.regularActionPlainCommonStyle:hover,
.regularActionTagCommonStyle:hover,
.headActionTagCommonStyle:hover {
  color: var(--c-primary-light);
}

.borderTopDash::before {
  margin: 8px 0;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background: repeating-linear-gradient(90deg,#c4cdd5 0,#c4cdd5 8px,transparent 0,transparent 16px);
}

.borderTopSolid::before {
  margin: 8px 0;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background: #c4cdd5;
}

.borderTopJoin {
  margin-top: -16px;
}

@media screen and (min-width: 768px) {
  .headActionTagCommonStyle,
  .headActionPlainCommonStyle,
  .headPlainCommonStyle,
  .headTagCommonStyle,
  .subTitleL {
    font-size: 20px;
    line-height: 30px;
    padding: 8px 0 8px;
  }

  .borderTopDash::before,
  .borderTopSolid::before {
    margin: 12px 0;
  }

  .actionButton,
  .actionButton:hover {
    padding: 14px 0 !important;
  }
}

@media screen and (min-width: 1024px) {
  .headActionTagCommonStyle,
  .headActionPlainCommonStyle,
  .headPlainCommonStyle,
  .headTagCommonStyle,
  .subTitleL {
    font-size: 24px;
    line-height: 36px;
    padding: 8px 0 8px;
  }
}
