.list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0;
}

@media (min-width: 768px) {
  .list {
    gap: 40px 0;
  }
}

@media (min-width: 1024px) {
  .list {
    gap: 40px 24px;
  }
}

@media (min-width: 1680px) {
  .list {
    gap: 40px 32px;
  }
}