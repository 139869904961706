@media screen and (max-width: 767.98px) {
  .passwordResetFormInner {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .passwordResetFormInner {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1679.98px) {
  .passwordResetFormInner {
    margin-left: 12px;
    margin-right: 12px;
  }
}
