.container {
}
.extraInfoText {
  white-space: nowrap;
  color: #656D76;
}


@media (min-width: 768px){
  .extraInfo {
    margin-top: -2px;
  }
}