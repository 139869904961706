.itemsContainer {
    composes: global(t-12) global(t-m-16) global(t-l-8) global(t-xl-16) global(b-40) global(b-m-60) global(b-l-120);
}
.items {
    composes: global(row) global(l-2) global(r-2); 
}

@media (min-width: 624px) and (max-width: 767.98px) {
    .card {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
