.instantRentalsOnly {
	white-space: nowrap;
	position: relative;
	z-index: 1;
}

.filters,
.instantRentalsOnly {
	display: none;
}

.mobileRow {
	display: flex;
	justify-content: space-between;
}

.resultCard {
	display: flex;
	align-items: center;
}
.filtersContainer {
    display: contents;
}

.sort {
    width: 220px;
}

@media (min-width: 768px) {
	.container {
		position: relative;
		display: grid;
		grid-template-columns: 1fr auto auto;
		grid-template-areas: 
			"filters            filters filters"
			"instantRentalsOnly showMap sort"
			"resultCard resultCard resultCard";
	}
	.mobileRow {
		display: contents;
	}

	.filters {
		grid-area: filters;
		display: flex;
	}
	
	.instantRentalsOnly {
		grid-area: instantRentalsOnly;
		display: flex;
		align-items: center;
	}
	
	.sort {
		grid-area: sort;
	}
	
	.showMap {
		grid-area: showMap;
	}
	
	.filtersButton {
		grid-area: filtersButton;
		display: none;
	
	}
	
	.resultCard {
		grid-area: resultCard;
	}

	.isMapOpen .showMap,
	.isMapOpen .resultCard,
	.isMapOpen .sort {
		display: none;
	}

	.isMapOpen .instantRentalsOnly {
		background: #fff;
		position: absolute;
		top: 46px;
		padding: 14px 28px 14px 18px;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
	}

	.isMapClosed .showMap {
		margin-right: 24px;
	}
	
}
@media (min-width: 1024px) {
	.isMapOpen .instantRentalsOnly {
		top: 52px;
	}
}

@media (min-width: 1440px) {
	.showMap {
		display: none;
	}
	.isMapClosed .showMap {
		display: flex;
		justify-content: flex-end;
	}

	.isMapClosed .container {
		grid-template-columns: 1fr auto;
		grid-template-areas: 
			"filtersContainer sort"
			"resultCard showMap";
	}
	.isMapClosed .filtersContainer {
		grid-area: filtersContainer;
        display: flex;
	}
	.isMapOpen .resultCard,
	.isMapOpen .sort {
		display: block;
	}


	.isMapOpen .instantRentalsOnly {
		background: none;
		position: relative;
		padding: 0;
		top: 0;
		box-shadow: none;
		border-radius: none;

	}
	.isMapOpen .container {
        grid-template-columns: 1fr auto;
		grid-template-areas: 
			"filters            filters"
			"instantRentalsOnly sort"
			"resultCard         resultCard";
	}

	.isMapOpen .resultCard {
		margin-top: 12px;
		margin-bottom: -8px;
	}

	.isMapClosed .sort,
	.isMapOpen .sort {
		display: flex;
		justify-content: flex-end;
	}

	.isMapClosed .instantRentalsOnly {
		margin-left: 16px;
	}

	.isMapClosed .showMap {
		margin-right: 0px;
	}

	.isMapOpen .filters {
		margin-bottom: 20px;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
	}
    .isMapOpen .filters > :first-child {
        padding-left: 0;
    }
    .isMapOpen .filters > :last-child {
        padding-right: 0;
    }

	.isMapClosed .filters {
        max-width: calc(100vw - 580px);
	}
    
}

@media (min-width: 1680px) {
    .sort {
        width: 264px;
    }
	.isMapClosed .instantRentalsOnly {
		margin-left: 24px;
	}
    .container {
        margin-left: 0px;
	}
    .isMapOpen .container {
        grid-template-columns: 1fr auto
    }
	.isMapClosed .filters {
        max-width: calc(1552px - 500px);
	}

}