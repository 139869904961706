.startInput > input::-webkit-outer-spin-button,
.startInput > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.startInput > input[type=number] {
  -moz-appearance: textfield;
}

.endInput > input::-webkit-outer-spin-button,
.endInput > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.endInput > input[type=number] {
  -moz-appearance: textfield;
}
