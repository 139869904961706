.root :global(.rv-card__arrow):disabled {
    pointer-events: auto;
}

.cardContainer {
    position: relative;
    height: 100%;
}
.btnControlsContainer {
    z-index: 11;
}

.btnControls,
.btnControls:hover,
.btnControls:focus {
    margin-right: 0;
    background: none;
    box-shadow: none;
}