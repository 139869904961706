.topTripContainer {
  flex-wrap: wrap;
}
.topTripTitleContainer,
.topTripButtonContainer {
  width: 100%;
}
.topTripTitle {
  font-weight: normal;
}
.topTripMenu {
  padding: 0;
  width: 306px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0 16px;
}
.topTripMenuItem {
  width: 136px;
  height: 136px;
}
.topTripMenuItem:last-child {
  display: none;
}
.topTripMenuItemInner {
  border-radius: 100%;
  height: 100%;
  width: 100%;
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, .3) 0%, rgba(196, 205, 213, .3) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.topTripMenuItemInner img {
  width: 94px;
  height: 94px;
  object-fit: contain;
  object-position: center center;
}
@media (min-width: 768px){
  .topTripMenu {
    width: 588px;
  }

  .topTripMenuItem {
    width: 152px;
    height: 152px;
  }
  .topTripMenuItem:last-child {
    display: block;
  }
  .topTripMenuItemInner img {
    width: 100px;
    height: 100px;
  }
}
@media (min-width: 1024px){
  .topTripMenu {
    width: 100%;
    gap: 0 44px;
  }
}
@media (min-width: 1440px){
  .topTripMenu {
    gap: 0 52px;
  }
}

@media (min-width: 1680px){
  .topTripContainer {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .topTripTitleContainer {
    width: auto;
    flex: 0 0 calc(16.6666666667% + 32px);
    max-width: calc(16.6666666667% + 32px);
  }
  .topTripButtonContainer {
    width: auto;
  }

  .topTripTitle {
    padding-top: 16px;
  }
}