.modalCarouselCrossButton {
    right: 0 !important;
    top: 0 !important;
}
.sliderDotsList {
    padding: 0 !important;
  }

  @media (min-width: 768px) {
    .modalCarouselCrossButton {
        right: 0 !important;
        top: 0 !important;
    }
  }