.root {
  position: relative;
}
.select1,
.select2 {
  position: relative;
  z-index: 1;
  width: 50%;
}

.select1:hover,
.select2:hover,
.select1Error {
  z-index: 2;
}
.select1 :global(.field-select-button){
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.select2 :global(.field-select-button){
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.select2 {
  margin-left: -1px;
}
