.wrapper {
  position: relative;
}

.wrapperInside {
  position: relative;
  background: #c4cdd5;
  border-radius: 4px;
  overflow: hidden;
}

.wrapperOverlay {
  filter: drop-shadow(0px 5px 10px rgba(9, 10, 11, 0.05));
}

.aspectRatioKeeper {
  height: 0;
  padding-top: calc(var(--image-aspect-ratio, 0.75) * 100%);
  overflow: hidden;
  position: relative;
}

.image {
  position: absolute;
  top: 0;
  display: block;
  max-width: none;
  width: 100%;
  pointer-events: none;
  height: 100%;
}

.image > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.toolbarWrapper,
.coverToolbarWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms;
  pointer-events: none;
}

.wrapper .toolbarContainer {
  opacity: 0;
}

.wrapper:hover .toolbarContainer {
  opacity: 1;
}
@media (max-width: 1023.98px) {
  .wrapper .toolbarWrapper,
  .wrapper .coverToolbarWrapper {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 1024px) {
  .wrapper:hover .toolbarWrapper,
  .wrapper:hover .coverToolbarWrapper {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 1439px) {
  .toolbarWrapper {
    height: 80px;
  }
}
@media (max-width: 767px) {
  .toolbarWrapper {
    height: 50px;
  }
}
.toolbarWrapper::before,
.coverToolbarWrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.0001) 100%
  );
  z-index: 1;
}

:global(.touchevents) .toolbarWrapper,
:global(.touchevents) .coverToolbarWrapper {
  opacity: 1;
  visibility: visible;
  transition: all 300ms;
}

.coverToolbar {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
}
.toolbar {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
}

@media (max-width: 767.98px) {
  .toolbar {
    top: 4px;
    right: 4px;
  }
}
.button {
  composes: global(btn) global(btn--secondary);
  overflow: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: 0 none;
  border-radius: 4px;
  padding: 0;
  vertical-align: top;
  pointer-events: all;
}

.button,
.button::before {
  width: 32px;
  height: 32px;
}

.button + .button {
  margin-left: 8px;
}

.coverToolbar > .button,
.coverToolbar > .button::before {
  width: 42px;
  height: 42px;
}

.coverToolbar > .button + .button {
  margin-left: 12px;
}

.editButton {

}

.deleteButton {
  margin-left: 16px;
}

.buttonLabel {
  composes: global(visually-hidden);
  position: absolute !important;
}

.dragHandle {
  cursor: pointer;
}

.dragHandle::before {
  display: none;
  content: "";
  touch-action: none;
  position: absolute;
  top: 16px;
  left: 19px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url("./drag-handle.svg") no-repeat 0 0;
  pointer-events: none;
  z-index: 2;
}
@media (max-width: 767.98px) {
  .dragHandle::before {
    top: 12px;
    left: 11px;
  }
}
@media (min-width: 768px) {
  .dragHandle::before {
    top: 12px;
    left: 12px;
  }
}


@media (max-width: 1023.98px) {
  .dragHandle::before {
    display: block;
  }
}
@media (min-width: 1024px) {
  .dragHandle:hover::before {
    display: block;
  }
}
.primaryBadge::after {
  display: block;
  content: "Primary";
  position: absolute;
  text-transform: uppercase;
  background: white;
  bottom: 4px;
  left: 4px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  padding: 0 8px;
  pointer-events: none;
}
@media (min-width: 768px) {
  .primaryBadge::after {
    bottom: 12px;
    left: 12px;
  }
}
