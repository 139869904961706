.mdl {
  width: 960px;
  padding: 20px 56px 28px;
}
@media (max-width: 1023.98px) {
  .mdl {
    width: auto;
  }
  .mdlWithHeader {
    padding-top: 52px;
  }
}
@media (max-width: 767.98px) {
  .mdl {
    padding: 17px 16px 90px;
  }
  .mdlWithHeader {
    padding-top: 68px;
  }
}

.logoWrap {
  width: 204px;
  height: 41px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1023.98px) {
  .logoWrap {
    width: 164px;
    height: 43px;
  }
}

.optionsWrap {
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
}
@media (max-width: 1023.98px) {
  .optionsWrap {
    flex-wrap: wrap;
    margin: -8px 0;
  }
}
@media (max-width: 767.98px) {
  .optionsWrap {
    margin: -4px 0;
  }
}

.optionWrap {
  flex: 1 1 0;
  margin-left: 8px;
  margin-right: 8px;
}
@media (max-width: 1023.98px) {
  .optionWrap {
    flex-basis: 100%;
    margin: 8px 0;
  }
}
@media (max-width: 767.98px) {
  .optionWrap {
    margin: 4px 0;
  }
}

.validateBox {
  padding: 20px 24px;
  background-color: #f4f6f8;
  border-radius: 4px;
}
@media (max-width: 767.98px) {
  .validateBox {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.validateBoxItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: -8px;
  margin-right: -8px;
}

.validateBoxItem {
  flex: 0 0 auto;
  margin-left: 8px;
  margin-right: 8px;
}

.disclaimerText {
  color: var(--c-tertiary-light);
}

.disclaimerText p {
  padding-top: 21px;
  padding-bottom: 0;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.disclaimerText p:first-child {
  padding-top: 0;
}

.mdlHeader {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
}
@media (max-width: 767.98px) {
  .mdlHeader {
    position: fixed;
    z-index: 200;
    background-color: #fff;
    transition: box-shadow 100ms;
  }
  .mdlHeaderWithShadow {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  }
  .btnBackIcon {
    margin: 5px;
  }
}

.btnBackIcon {
  position: relative;
  width: 42px;
  height: 42px;
}

.btnBackIcon svg {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.btnBack .btnBackInner span,
.btnBack .btnBackInner path {
  color: var(--c-primary);
  fill: var(--c-primary);
}

.btnBack .btnBackInner {
  display: flex;
  align-items: center;
}

.btnBack:hover .btnBackInner span,
.btnBack:hover .btnBackInner path {
  color: var(--c-primary-light);
  fill: var(--c-primary-light);
}

.btnBackIcon {
  display: flex;
}

.fw700 {
  font-weight: 700;
}
@media screen and (max-width: 767.98px) {
  .paymentMethodStepClassStyle {
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.25) !important;
    border: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .mdl {
    padding: 52px 56px 28px;
  }
  .paymentMethodStepClassStyle {
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.25) !important;
    border: none !important;
  }
  .mdl.stepsModalInner {
    border-radius: 0;
    padding: 52px 0px 28px;
    margin: 0;
    padding-top: 17px;
    box-shadow: none;
  }
  .stepsModalRow {
    margin: 0 -24px;
  }
  .checkoutStepsModalRow {
    margin: 0;
  }
  .stepsModalContainer {
    padding: 0 0px;
    background: #fff;
    min-height: 100%;
  }

  .stepsModalMdlCrossButton {
    position: fixed;
    right: 15px;
    width: 52px;
    height: 52px;
    border-radius: 0;
  }
  .paymentMethodStepContainer {
    width: 672px;
    margin: 0 auto;
  }
  .modalNotGoodsamClassnames.stepsModalContainer{
    padding-top: 4px;
  }

  .modalNotGoodsamClassnames .logoWrap{
    width: 167px;
    height: 42px;
  }
  .modalNotGoodsamClassnames .stepsModalMdlCrossButton{
    right: 0px;
  }
}
@media screen and (min-width: 1024px){
  .checkoutContainer {
    width: 636px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
  .paymentMethodStepContainer {
    width: 568px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1679.98px) {
  .paymentMethodStepContainer {
    width: 572px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1680px) {
  .paymentMethodStepContainer {
    width: 696px;
    margin: 0 auto;
  }
}
