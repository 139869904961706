.desctiption {
  position: relative;
  max-height: calc(24px * 6);
  overflow: hidden;
}

.desctiption.desctiptionCollapse::after {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: 82px;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.825) 48.44%, #FFFFFF 100%);
  pointer-events: none;
}

.desctiption.showDescription {
  max-height: 100%;
}
.desctiption.showDescription::after {
  display: none;
}
@media (min-width: 768px){
  .desctiption {
    max-height: calc(27px * 5);
  }
}

@media (min-width: 1024px){
  .desctiption {
    max-height: calc(27px * 6);
  }
}

@media (min-width: 1680px){
  .desctiption {
    max-height: calc(27px * 5);
  }
}