.crossButton {
  width: 42px !important;
  height: 42px !important;
}

.modal {
  padding: 8px 16px 28px;
  max-height: 100%;
  overflow-y: auto;
}

.subTitle {
  padding-top: 0;
}

.description {
  font-weight: normal;
}

@media (min-width: 768px) {
  .modal {
    width: 736px;
    padding: 20px 42px 28px;
    overflow-y: visible;
    max-height: none;
    min-height: auto;
  }

  .title {
    padding-bottom: 12px;
  }

  .crossButton {
    width: 52px !important;
    height: 52px !important;
  }
}
