.showOptionsButtonMarginTop {
  margin-top: 0px;
}

.showOptionsButton {
  font-weight: 500;
}

.chevron > svg {
  width: 24px;
  height: 24px;
}

.chevron path {
  fill: var(--c-primary);
}

@media (min-width: 768px) {
  .showOptionsButtonMarginTop {
    margin-top: 0px;
  }
}
