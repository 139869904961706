.aside-info {
  composes: global(offset-auto) global(col-12) global(col-l) global(col-xl-3);
}

.headphonesButton {
  justify-content: flex-start;
  padding: 0;
}

.rv_rentals_support_email {
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  justify-content: flex-start;
}

@media (width >= 1024px) and (width < 1440px) {
  .aside-info {
    padding-left: 0;
    padding-right: 0;
  }
}

.aside-info__item {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  composes: global(t-8) global(b-16) global(l-16) global(r-16) global(mt-m-24) global(mt-xl-0) global(mb-m-24);
  border: 2px solid var(--c-secondary, #DEDED8);
}
@media (width >= 1024px) and (width < 1440px) {
  .aside-info__item:last-child {
    margin-bottom: 0;
  }
}

.aside-info__item-fill {
  border: 2px solid var(--c-quaternary, #eee);
  background-color: var(--c-quaternary);
}

.aside-info__block {
  composes: global(l-m-16) global(r-m-16);
}

.aside-info__title {
  min-width: 160px;
}

.aside-info__title strong {
  font-weight: 500;
}

.aside-info__description {
  composes: global(b-8) global(b-m-0) global(b-l-8) global(t-m-8) global(t-l-0);
}

.aside-info__contacts {
  display: flex;
  composes: global(b-16) global(b-m-0) global(b-l-8) global(t-m-8) global(b-l-8) global(t-l-0);
}

.aside-info__icon {
  margin-right: 10px;
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .aside-info {
    margin-left: 48px;
  }
}
@media (min-width: 1440px) and (max-width: 1679.98px) {
  .aside-info {
    margin-left: 44px;
  }
  .aside-info__item {
    padding-left: 16px;
  }
}
@media (min-width: 1024px) {
  .aside-info__contacts {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .aside-info__item {
    flex-direction: row;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .aside-info__item {
    max-width: 100%;
  }
}
