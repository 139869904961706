.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 2px solid var(--c-quaternary, #eee);
  background-color: var(--c-quaternary);
  composes: global(t-8) global(b-16) global(l-16) global(r-16) global(mb-24);
}

.block {
  composes: global(l-m-16) global(r-m-16);
}

.title {
  min-width: 160px;
}

.description {
  composes: global(b-8) global(t-8);
}

.description ul,
.description ol {
  padding-left: 24px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--c-tertiary);
}

.cta {
  display: flex;
  composes: global(b-20) global(t-l-30) global(t-20);
}

@media (min-width: 768px) and (max-width: 1023px) {
  .wrapper {
    flex-direction: row;
  }
}
