.photoContainer {
  aspect-ratio: 16/9;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 4px;
}
.title {
  padding-bottom: 0px;
}
.description {
  position: relative;

}

.description::after {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 70px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.825) 48.44%, #FFFFFF 100%);
  content: '';
  pointer-events: none;
}
.sliderArrow {
  width: 42px;
  height: 42px;
  text-indent: -99999px;
  border: none;
}

.sliderPrevArrow {
  background: url(assets/images/white-left-arrow-slider.svg) 0 0 no-repeat; 
}
.sliderNextArrow {
  background: url(assets/images/white-right-arrow-slider.svg) 0 0 no-repeat; 
}

.sliderPrevArrow:hover {
  background: url(assets/images/red-left-arrow-slider.svg) 0 0 no-repeat; 
}
.sliderNextArrow:hover {
  background: url(assets/images/red-right-arrow-slider.svg) 0 0 no-repeat; 
}

@media (min-width: 1024px){
  .content {
    display: grid;
    grid-template-columns: repeat(9, minmax(10px, 1fr));
    grid-gap: 0 24px;
  }
  .contentColumn {
    width: auto;
    max-width: 100%;
    padding: 0;
  }
  .contentColumn:first-child {
    grid-column: 1/6;
    padding-left: 12px;
  }

  .contentColumn:last-child {
    grid-column: 6/10;
    padding-right: 12px;
  }

  .titleText {
    padding-top: 0;
    margin-top: -4px;
  }

  .menuContainer {
    margin-top: -4px;
  }
}