.content {
  display: grid;
  grid-template-columns: 1fr;
}

.select1 {
  flex-grow: 1;
}
.select2 {
  width: 120px;
  max-width: 120px;
  min-width: 120px;
}

.col2 {
  display: none;
}

@media (min-width: 768px) {
  .select2 {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
  }

  .content {
    grid-template-columns: 1fr auto 1fr auto;
  }

  .col2 {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 1680px) {
  .select2 {
    width: 120px;
    max-width: 120px;
    min-width: 120px;
  }
}
