.container {
  box-shadow: 0px 2px 20px rgba(101, 109, 118, 0.2);
  border-radius: 8px;
}

.avatar {
  position: absolute;
}
.name {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-weight: 500;
  display: -webkit-box;
  display: block;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.content {
  composes: global(col) global(r-0) global(r-l-0) global(r-xl-0);
  padding-left: 132px;
}

.headerInner {
  max-width: 100%;
  composes: global(df) global(fb) global(db-xl) global(mt-xl-4);
}
@media (min-width: 1024px) {
  .content {
    padding-left: 109px;
  }
}

@media (min-width: 1440px) {

  .content {
    padding-left: 0px;
  }

  .header {
    composes: global(mb-36) global(mb-l-28) global(df-xl) global(mb-xl-0);
    padding-left: 110px;
    min-height: 104px;
    margin-bottom: 32px;
  }
  .ratingContainer {
    margin-bottom: -8px;
  }
}
