.mdl {
  padding: 12px 56px 32px;
}

@media (max-width: 767.98px) {
  .mdl {
    padding: 16px 24px 101px;
  }
  .mdlRowInner {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .mdl {
    width: 486px;
    max-width: 100%;
    min-width: 486px;
  }
}

.sectionTitle {
  font-size: 28px;
  line-height: 42px;
}
@media (max-width: 767.98px) {
  .sectionTitle {
    font-size: 24px;
    line-height: 36px;
    padding-top: 26px;
    padding-bottom: 12px;
  }
  .crossButton {
    position: fixed;
    top: 0px;
    right: 0px;
  }
}

@media (min-width: 1920px) {
  .mdl {
    width: 496px;
    max-width: 100%;
    min-width: 496px;
  }
  .sectionTitle {
    font-size: 32px;
    line-height: 48px;
  }
}
