.root {
    composes: global(t-16) global(t-m-20) global(b-m-20);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.title {
    color: var(--c-tertiary, #232323);
}

.minDuration {
    color: var(--c-tertiary, #232323);
}
.instantRentals {
    display: flex;
    width: 100%;
}
.instantRentalsImg {
    width: 20px;
    height: 20px;
}
@media (max-width: 767.98px) {
    .instantRentals {
        display: flex;
        align-items: flex-start;
    }
}
@media (min-width: 768px) {
    .root {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .title {
        flex-basis: 100%;
        flex-grow: 1;
    }
}

@media (min-width: 1024px) {
    .title,
    .interval,
    .minDuration {
        flex-basis: 33.3333%;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .interval {
        text-align: center;
        padding-top: 35px;
    }
    .instantRentals {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .minDuration {
        text-align: right;
        padding-top: 37px;
    }
}
@media (min-width: 1440px) {
    .interval {
        padding-top: 41px;
    }

    .minDuration {
        padding-top: 44px;
    }
}
@media (min-width: 1680px) {

    .instantRentalsImg {
        margin-top: 2px;
    }
}