.section {
  border-top: 1px solid var(--quaternary-dark, #BFC5CE);
  margin-top: 12px;
  padding-top: 16px;
}

.section.isLastBorder:last-child {
  border-bottom: 1px solid var(--quaternary-dark, #BFC5CE);
  padding-bottom: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .section {
    padding-top: 14px;
    margin-top: 12px;
  }

  .section.isLastBorder:last-child {
    padding-bottom: 12px;
    margin-bottom: 16px;
  }
}

@media (min-width: 1024px) {
  .section {
    margin-top: 12px;
  }

}
