
.root {
    transition: background-color 300ms;
    cursor: pointer;
    border-bottom: 1px solid rgba(196, 205, 213, 0.5);
}

.root:hover {
    background-color: rgba(196, 205, 213, 0.2);
}

.startInfo {
    flex: 1;
}

.endInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.status {
    margin: 4px 0 0 0;
}

.name {
    margin: 0;
    padding: 0;
    color: var(--c-tertiary);
}

.avatar {
    position: relative;
    overflow: hidden;
    width: 52px;
    min-width: 52px;
    height: 52px;
}

.avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    z-index: 1;
}

.onlineIndicator {
    position: absolute;
    z-index: 10;
    bottom: 3px;
    right: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--c-status-success-dark);
    box-shadow: 0 0 0 2px #ffffff;
}

.dateTime {
    color: var(--c-tertiary-light);
    padding-top: 0;
    padding-bottom: 6px;
}

.unread {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    line-height: 24px;
    border-radius: 20px;
    padding: 0 8px;
    background: var(--c-secondary-dark);
    color: #ffffff;
    font-weight: 900;
    font-size: 14px;
    opacity: 0;
    transition: opacity 300ms;
}

.unreadVisible {
    opacity: 1;
}
