.referralNotificationActions {
  display: flex;
  flex-direction: row;
}

.referralNotificationAction {
  margin-right: 24px;
}

.referralNotificationAction:last-child {
  margin-right: 0;
  background: rgba(0, 0, 0, 0);
}

@media (max-width: 767px) {
  .referralNotificationActions {
    flex-direction: column;
  }
  
  .referralNotificationAction {
    margin-right: 0;
    margin-bottom: 16px;
  }
  
  .referralNotificationAction:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}
