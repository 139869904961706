.dayPrice {
    composes: global(dn) global(db-m);
    height: 28px;
    padding: 0 8px;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: var(--c-tertiary-dark);
    position: absolute;
    bottom: 11px;
    left: 11px;
    border-radius: 4px;
    background: #ffffff;
    text-align: left;
}

.dayPriceDiff {
    composes: dayPrice;
    color: var(--c-status-warning-dark);
    background: var(--c-status-warning-light);
}

.busy {
    composes: dayPrice;
    color: var(--c-white);
    background: var(--c-tertiary-dark);
}
