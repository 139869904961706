.descPlainStyle {
  composes: global(subtitle--s);
  color: var(--c-tertiary);
}

.buttonsContainerClassName {
  width: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.buttons {
  display: flex;
}

.total {
  display: block;
  font-weight: bold;
}

@media (min-width: 768px) {
  .buttonsContainerClassName {
    width: 100%;
  }

  .buttons {
    display: flex;
    gap: 0 16px;
  }

  .total {
    display: inline;
    margin-right: 6px;
  }
}

@media (min-width: 1024px) and (max-height: 729.98px) {
  .footer {
    width: 738px;
    margin: 0 auto;
  }
}
