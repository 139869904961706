.search {
  width: 100%;
  max-width: 386px;
}

.search :global(.field-input__icon) {
  width: 52px;
  height: calc(100% - 2px);
  border-left: 1px solid var(--c-quaternary);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1px;
  right: 0;
  transform: none;
  z-index: 1;
  cursor: pointer;
}

.search :global(.field-input__field) {
  padding-right: 60px;
}

.search :global(.field-input__label){
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 220px;
}

.collapse ul,
.collapse ol {
}

.collapse ul li,
.collapse ol li {
  color: #474342;
  font-size: 14px;
  line-height: 21px;
}

.aside-info {
  composes: global(col);
  order: -1;
  margin-top: 20px;
  margin-bottom: 20px;
  display: none;
}

.aside-info__item {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 2px solid #dc982e;  
  padding: 8px 16px;
}

.aside-info__block {
  composes: global(l-m-16) global(r-m-16);
}

.aside-info__title {
  min-width: 160px;
}

.aside-info__title strong {
  font-weight: 500;
}

.aside-info__description {
  composes: global(b-8) global(b-m-0) global(b-l-8) global(t-m-8) global(t-l-0);
}

.aside-info__description ul, ol {
  padding-left: 24px;
  padding-bottom: 16px;
}

.aside-info__description li {
  font-size: 16px;
  line-height: 24px;
  color: #474342;
}

@media (min-width: 768px) {
  .aside-info {
    display: block;
  }

  .search :global(.field-input__label){
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .section {
    max-width: calc(100% - 246px);
  }

  .aside-info {
    margin: 0;
    order: 1;
    max-width: 246px;
    box-sizing: content-box;
  }

  .aside-info__item {
    flex-direction: column;
    padding: 8px;
  }

  .aside-info__description ul, ol {
    padding-top: 16px;
  }
}

@media (min-width: 1440px) {
  .section {
    max-width: calc(100% - 306px);
  }

  .aside-info {
    max-width: 306px;
    margin-left: 40px;
  }

  .aside-info__item {
    padding: 8px 16px;
  }
}

@media (min-width: 1680px) {
  .section {
    max-width: calc(100% - 363px);
  }

  .aside-info {
    max-width: 363px;
  }
}
