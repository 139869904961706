.menu {
  padding: 0 0 4px;
  display: flex;
  gap: 0 16px;
}

.menu.menuStretch {
  gap: 0;
}
.menu.sizeLarge {
  padding: 0 0 8px;
}

.menuItem {
  cursor: pointer;
  white-space: nowrap;
}
.menuItem.menuItemStretch {
  flex-grow: 1;
  text-align: center;
}
.menuItem.menuItemDisabled {
  cursor: default;
}

.menuItem.menuItemDisabled p {
  color: var(--c-quaternary-dark);
}

.menuContainer {
  position: relative;
  --cwrr-pipe-width: 0px;
  --cwrr-pipe-left: 0px;
} 

.menuContainer.isTableTabs {
  display: inline-flex;
  margin-bottom: 8px;
}

.menuContainer::before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 4px;
  transition: all 0.25s;
  background-color: var(--c-primary);
  border-radius: 4px;
  width: var(--cwrr-pipe-width, 0);
  transform: translateX(var(--cwrr-pipe-left, 0));
  z-index: 1;
}

.menuContainer::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: var(--c-quaternary-dark);
}

@media screen and (min-width: 768px) {
  .menu.sizeLarge {
    padding: 0 0 4px;
  }
  .menuContainer.isTableTabs {
    display: block;
    margin-bottom: -4px;
  }
  .menu.isTableTabs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    padding-bottom: 0;
  }

  .menu.isTableTabs::before {
    content: "";
  }

  .menuItem.isTableTabs {
    display: grid;
    justify-content: flex-end;
  }

  .menuContainer.isTableTabs::after,
  .menuContainer.isTableTabs::before {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .menu.sizeMiddle {
    padding: 0 0 0px;
  }
}