.modal {
    padding: 0;
    margin: 0 auto;
    border-radius: 0;
    max-height: 100%;
    min-height: 100%;
    overflow-y: auto;
    width: 100%;
}
.content {
    margin: 0 auto;
    padding: 0px 16px 108px;
  }
  .crossButton {
    width: 52px;
    height: 52px;
    position: fixed;
    right: var(--cwrr-scroll-width, 0);
  }
  .bar {
    right: var(--cwrr-scroll-width, 0);
  }

  .policyLink {
    font-size: inherit;
    line-height: inherit;
  }
@media (min-width: 768px) {
    .content {
        padding: 12px 16px 60px;
      }
      .title {
        padding-top: 24px;
        font-size: 24px;
        line-height: 36px;
        padding-bottom: 13px;
      }
}

@media (max-width: 767.98px) {
    .bar {
        position: fixed;
        right: var(--cwrr-scroll-width, 0);
        bottom: 0;
        left: 0;
        z-index: 10;
        background-color: #ffffff;
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
        justify-content: center;
    }
}
@media (min-width: 1024px) {
    .modal {
        min-height: auto;
        border-radius: 8px;
        overflow-y: visible;
        max-height: none;
        margin: 40px 0;
        flex: 0 0 83.3333333333%;
        max-width: calc(83.3333333333% - 58px);
        width: 100%;
      }

      .content {
        padding: 12px 56px 28px;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .crossButton {
        width: 42px;
        height: 42px;
        position: absolute;
        right: 0px;
        top: 0px;
      }
}

@media (min-width: 1440px) {
    .modal  {
      flex: 0 0 66.6666666667%;
      max-width: calc(66.6666666667% - 104px);
      width: auto;
    }

    .title {
      padding-top: 32px;
      padding-bottom: 16px;
      font-size: 32px;
      line-height: 48px;

    }
  }

  @media (min-width: 1680px) {
    .modal  {
      max-width: 1024px;
      width: 100%;
    }
  }
  