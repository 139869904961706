.signaturePlaceholder {
  width: 100%;
  aspect-ratio: 4/3;
  background: #F8F9FA;
  background: var(--c-quaternary-light, #f8f9fa);
  border: 1px solid var(---c-quaternary-dark, #bfc5ce);
  border-radius: 8px;
  display: grid;
  place-content: center;
}

.signaturePlaceholder::before {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  content: "Loading the signature";
  background: var(--c-tertiary-light, #757272);
}

.img {
  border-radius: 8px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.signature {
  aspect-ratio: 4/3;
  max-width: 288px;

  border-radius: 8px;
  border: 1px solid var(---c-quaternary-dark, #bfc5ce);
  background: #fff;
}

.signature.hasNotImage {
  display: grid;
  place-content: center;
  border: none;
  background: var(--c-quaternary, #EAECEF);
}

.photoPlaceholder svg {
  width: 28px;
  height: 30px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .title {
    padding-top: 20px;
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 768px) {
  .signature {
    max-width: 360px;
  }

  .photoPlaceholder svg {
    width: 48px;
    height: 52px;
  }
}

@media (min-width: 1024px) {
  .signature {
    /* max-width: 304px; */
  }

  .photoPlaceholder svg {
    width: 62px;
    height: 66px;
  }
}

@media (min-width: 1440px) {
  .signature {
    /* max-width: 306px; */
  }

  .photoPlaceholder svg {
    width: 63px;
    height: 67px;
  }
}

@media (min-width: 1440px) {
  .signature {
    /* max-width: 306px; */
  }

  .photoPlaceholder svg {
    width: 48px;
    height: 51px;
  }
}
