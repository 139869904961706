.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.footerButtonsContainer {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.footerButtonSelect {
  margin-left: 22px;
}
@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .footerButtonSelect {
    margin-left: 16px;
  }
}
@media screen and (min-width: 0px) and (max-width: 767.98px) {
  .footerContentDiscount {
    max-width: 132px;
  }
  .footerButtonsContainer {
    margin-left: 0px;
  }
}
