.descPlainStyle {
  composes: global(subtitle--s);
  color: var(--c-tertiary);
}

.descHtmlStyle {
  composes: global(subtitle--s);
}

.descHtmlStyle a,
.descHtmlStyle p {
  font-size: inherit !important;
}

.descHtmlStyle p {
  color: var(--c-tertiary, #474342);
  font-size: 16px;
  line-height: 24px;
}

.buttonsContainerClassName {
  width: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.buttons {
  display: flex;
}

.total {
  display: block;
  font-weight: bold;
}

@media (min-width: 768px) {
  .buttonsContainerClassName {
    width: 100%;
  }

  .buttons {
    display: flex;
    gap: 0 16px;
  }

  .total {
    display: inline;
    margin-right: 6px;
  }
}

@media (min-width: 1440px) {
  .descContainer {
    margin-top: -8px;
  }
}


