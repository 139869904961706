.section {
  border-top: 1px solid transparent;
  position: relative;
  padding-top: 4px;
  margin-top: 2px;
}

.section:first-child {
  border-top: none;
  margin-top: 0px;
  padding-top: 0px;
}

.section.sectionMultiSelect:first-child {
  margin-top: 9px;
  padding-top: 12px;
}

.section.sectionMultiSelect {
  padding-top: 16px;
}

.section:last-child {
  margin-bottom: 4px;
}

.section::before {
  background: repeating-linear-gradient(90deg, var(--c-quaternary, #eaecef) 0, var(--c-quaternary, #eaecef) 8px,transparent 0,transparent 16px);
  height: 1px;
  top: -1px;
  left: 0px;
  right: 0px;
  content: '';
  position: absolute;
}

.section:first-child::before {
  display: none;
}

@media (min-width: 768px) {
  .section {
    padding-top: 8px;
    margin-top: 8px;

  }

  .section:first-child {
    padding-top: 4px;
    margin-top: 0px;
  }

  .section:last-child {
    margin-bottom: 12px;
  }
  .section.sectionMultiSelect {
    padding-top: 19px;
  }
  
  .section.sectionMultiSelect:first-child {
    margin-top: 7px;
  }
}
