.header {
  margin-top: 0;
  position: relative;
}

.calendar {
  padding-top: 16px;
}

.dayPrice {
  composes: global(dn) global(db-m);
  height: 28px;
  padding: 0 8px;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #212b36;
  position: absolute;
  bottom: 11px;
  left: 11px;
  border-radius: 4px;
  background: #ffffff;
  text-align: left;
}

.dayPriceDiff {
  composes: dayPrice;
  color: #ff9745;
  background: #ffeada;
  border-radius: 4px;
}

.disabledPrevDates .dayPrice,
.disabledPrevDates .dayPriceDiff {
  display: none;
}

.spinner {
  background: rgba(255,255,255,.5);
}

@media (min-width: 768px) {
  .minDuration {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1024px) {
  .header {
    margin-top: -56px;
  }

  .calendar {
    padding-top: 37px;
  }
}
