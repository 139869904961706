.root {
    composes: global(row);
    align-items: center;
    min-height: 92px;
}
.radioRow {
    composes: global(col-m-6) global(radio-row);
}
@media (max-width: 767px) {
    .radioRow {
        width: 152px;
    }
}

.radioYes {
    composes: global(radio-row__item);
    display: block;
}
.radioNo {
    composes: global(radio-row__item);
}
.inputWrap {
    composes: global(col-auto) global(col-m-6);
    display: block;
}
.input {
    display: block;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 136px;
}
@media (min-width: 768px) {
    .input {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
    }
}
