.pageTitle {
  color: var(--widget-text-border-color, #474342);
  padding-top: 0;
  padding-bottom: 20px;
  line-height: 32px;
}

.logo {
  display: inline-flex;
}
.logo svg {
  width: 94px;
  height: 24px;
}

@media (min-width: 768px) {
  .pageTitle {
    padding-bottom: 24px;
  }

  .logo svg {
    width: 126px;
    height: 32px;
  }
}

@media (min-width: 1440px) {
  .pageTitle {
    font-size: 40px;
    line-height: 54px;
    line-height: 40px;
  }
  
  .logo svg {
    width: 142px;
    height: 36px;
  }
}