.label {
    color: var(--c-tertiary-light);
    padding-top: 4px;
    padding-bottom: 4px;
}

.value {
    padding-top: 0 !important;
    color: var(--c-tertiary);
}
