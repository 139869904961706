.modalWrapper {
  z-index: 3005;
}

.modal {
  overflow-y: auto !important;
}

.btnBack .btnBackInner span,
.btnBack .btnBackInner path {
  color: var(--c-primary);
  fill: var(--c-primary);
}

.btnBack .btnBackInner {
  display: flex;
  align-items: center;
}

.btnBack:hover .btnBackInner span,
.btnBack:hover .btnBackInner path {
  color: var(--c-primary-light);
  fill: var(--c-primary-light);
}

.mdl {
  width: 960px;
  padding: 20px 56px 28px;
  padding-top: 55px;
}

.crossButton {
  position: fixed;
  width: 52px;
  height: 52px;
  right: var(--cwrr-scroll-width, 0);
}
.logoWrap {
  width: 284px;
  height: 32px;
  margin-left: auto;
  margin-right: auto;
}

.fw700 {
  font-weight: 700;
}

.confirmWrapInner {
  display: flex;
  justify-content: center;
}
.mdlHeader {
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.headerBackInner {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #212b36;
}
.headerBackArrowContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.headerBackArrow {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.confirmWrap {
  right: var(--cwrr-scroll-width, 0);
}
@media (max-width: 767.98px) {
  .mdl {
    padding: 16px 16px 108px;
    margin: 0;
    padding-top: 5px;
  }
  .mdl.mdlWidthHeaderArrowBack {
    padding-top: 55px;
  }

  .confirmWrap {
    position: fixed;
    bottom: 0;
    left: 0;
    min-height: 84px;
    background: #fff;
    padding: 16px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
    z-index: 200;
  }

  .mdlHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 52px;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
    background: #ffffff;
    z-index: 10;
  }
  .headerBackInner {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
  }
}

.btnLink {
  padding: 8px 0;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-primary);
  justify-content: flex-start;
  text-align: left;
}

.btnLink:hover {
  color: var(--c-primary-light);
}

.btnLinkIcon {
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  color: inherit;
}

@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .mdl {
    padding: 0;
    margin-top: 0;
    padding-top: 9px;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 132px;

  }

  .mdl.mdlWidthHeaderArrowBack {
    padding-top: 39px;
  }
  .confirmWrap {
    position: fixed;
    bottom: 0;
    left: 0;
    min-height: 92px;
    background: #fff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
    z-index: 200;
  }

  .confirmWrapInner {
    padding: 20px 8px;
    justify-content: end;
  }
}

@media (min-width: 768px) {
  .modal {
    max-height: 100%;
  }
  .confirmWrapInner {
    justify-content: end;
  }
}
@media (max-width: 1023.98px) {
  .mdl {
    width: auto;
  }
  .logoWrap {
    width: 164px;
    height: 57px;
  }
  .btnBackIcon {
    margin: 5px;
  }
}
@media (min-width: 1024px) {
  .mdl {
    padding-top: 4px;
  }

  .mdl.mdlWidthHeaderArrowBack {
    padding-top: 4px;
  }
  .logoWrap svg {
    width: 200px;
    height: 82px;
  }
  .logoWrap {
    height: 70px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
  .mdl {
    width: auto;
    padding: 0;
    margin-top: 0;
    padding-top: 24px;
    box-shadow: none;
    border-radius: 0;
    padding: 24px 32px 0;
    margin: 0 auto 132px;

  }

  .confirmWrap {
    position: fixed;
    bottom: 0;
    left: 0;
    min-height: 92px;
    background: #fff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
    z-index: 200;
  }

  .confirmWrapInner {
    padding: 20px 20px;
    width: auto;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1679.98px) {
  .mdl {
    width: auto;
    padding: 0;
    margin-top: 0;
    padding-top: 64px;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 132px;
    padding: 24px 72px 0;
    margin: 0 auto 132px;
  }

  .confirmWrap {
    position: fixed;
    bottom: 0;
    left: 0;
    min-height: 92px;
    background: #fff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: right;
    z-index: 200;
  }
  .confirmWrapInner {
    padding: 20px 58px;
  }
}

@media screen and (min-width: 1680px) {
  .mdl {
    width: 1352px;
    padding: 0;
    margin-top: 0;
    padding-top: 64px;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 132px;
    padding: 24px 0px 0;
    margin: 0 auto 132px;
  }

  .confirmWrap {
    position: fixed;
    bottom: 0;
    left: 0;
    min-height: 92px;
    background: #fff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
    z-index: 200;
  }
  .confirmWrap2 {
    width: 1352px;
    max-width: 1352px;
    margin: 0 auto;
    padding: 0;
  }
  .confirmWrapInner {
    padding: 20px 0px;
  }
}
