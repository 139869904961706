.footerFixed {
  position: fixed;
  height: 84px;
  left: 0;
  right: var(--cwrr-scroll-width, 0);
  bottom: 0px;
  background: #FFF;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

@media (min-width: 768px) {
  .footerFixed {
    height: 92px;
  }
}

@media (min-width: 1024px) {
  .footerFixed {
    position: static;
    box-shadow: none;
    height: auto;
    padding-top: 30px;
  }
}