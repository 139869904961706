.modal {
  max-width: 496px;
  width: 100%;  
  padding: 12px 56px 28px;
}

.crossButton {
  width: 52px;
  height: 52px;
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.listItem>div {
  width: 100%;
}

.listItem input {
  width: 300px;
  margin-right: 32px;
}

.formActions {
  display: flex;
}

.formAction {
  margin-right: 32px;
}

@media (max-width: 1440px) {
  .modal {
    max-width: 416px;
  }

  .listItem input {
    margin-right: 24px;
    width: 228px;
  }

  .formAction {
    margin-right: 24px;
  }
}

@media (max-width: 1024px) {
  .modal {
    max-width: 468px;
  }

  .listItem input {
    width: 280px;
  }
}

@media (max-width: 768px) {
  .modal {
    max-width: 486px;
  }

  .crossButton {
    width: 42px;
    height: 42px;
  }
  
  .listItem input {
    margin-right: 16px;
    width: 306px;
  }

  .formAction {
    margin-right: 16px;
  }
}

@media (max-width: 534px) {
  .modal {
    max-width: 100%;
    height: 100vh;
    margin: 0;
    border-radius: 0;
    padding: 16px;
  }

  .listItem input {
    width: 100%;
  }

  .listItem:first-child input {
    margin-right: calc(16px + 52px);
  }
}
