.container {
  width: 100%;
  background: #F4F6F8;
  border-radius: 4px;
}

.leftColumn {
  flex-grow: 1;
}

.logo {
  position: relative;
  bottom: -4px;
  display: inline-block;
}

.logo svg {
  width: 64px;
  height: 15px;
}

.price {
  display: inline-block;
}
.formattedPrice {
  font-weight: normal;
}
@media screen and (max-width: 767.98px) {
  .container {
    display: flex;
    justify-content: center;
  }
  .contentContainer {
    display: block;
    text-align: center;
  }

  .member {
    display: inline-block;
    text-align: center;
  }
}
