
.tableContainer {
  border-top: 1px solid #C4CDD5;;
}
.table {
  width: 100%;
  table-layout: fixed;
  border: 0;
  border-collapse: collapse;
  border-top: 1px solid #c4cdd5;
  border-bottom: 1px solid #c4cdd5;
}

.row {
  position: relative;
}
.rowExpandable {
  cursor: pointer;
}

.cell {
  position: relative;
  width: 50%;
  vertical-align: top;
}

.cellContainer {
  padding: 12px 16px 11px;
}
.row:first-child .cellContainer {
  padding-top: 12px;
}
.cell:first-child .cellContainer {
  padding-left: 32px;
}
.cell:last-child .cellContainer {
  padding-right: 32px;
  text-align: right;
}
.rowExtraSection .cellContainer {
  /* padding-top: 0;
  padding-bottom: 0; */
}
.rowExtraSectionStart .cellContainer {
  padding-top: 12px;
}
.rowExtraSectionEnd .cellContainer {
  padding-bottom: 13px;
}
.rowHeading.rowSectionEnd .cellContainer {
  padding-bottom: 32px;
}
.rowExpanded .cellContainer {
  padding-bottom: 0;
}
.rowCollapsable .cellContainer {
  padding-top: 0;
  padding-bottom: 0;
}
.rowCollapsableEnd .cellContainer {
  padding-bottom: 13px;
}
.rowCta .cellContainer {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media (max-width: 767px) {
  .cellContainer {
    padding: 9px 8px 9px;
    display: flex;
    align-items: center;
  }
  .row:first-child .cellContainer {
    padding-top: 8px;
  }
  .cell:first-child .cellContainer {
    padding-left: 0;
  }
  .cell:last-child .cellContainer {
    padding-right: 0;
    justify-content: flex-end;
  }
  .cell.withMobileIndents:first-child .cellContainer {
    padding-left: 16px;
  }
  .cell.withMobileIndents:last-child .cellContainer {
    padding-right: 16px;
  }
  .rowHeading.rowSectionEnd .cellContainer {
    padding-bottom: 24px;
  }
  .rowCollapsableEnd .cellContainer {
    padding-bottom: 9px;
  }
}

.cellContainer::before {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: repeating-linear-gradient(
    to right,
    #c4cdd5 0px,
    #c4cdd5 8px,
    transparent 8px,
    transparent 16px
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.rowSectionStart .cellContainer::before,
.rowSectionEnd + .row .cellContainer::before {
  background: #c4cdd5;
}
.row:first-child .cellContainer::before,
.rowCollapsable .cellContainer::before {
  display: none;
}
.rowExtraSection + .rowExtraSection .cellContainer::before {
  display: none;
}

.rowExpandable .cell:first-child .cellContainer::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  left: 0;
  background-image: url("./chevron-down.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: all 200ms;
}
.rowExpanded .cell:first-child .cellContainer::after {
  transform: rotate(180deg);
}
@media (max-width: 767px) {
  .rowExpandable .cell:first-child .cellContainer::after {
    position: static;
    margin-left: 8px;
  }
}

@keyframes collapseAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.rowCollapsable .cellContainer {
  opacity: 0;
  visibility: hidden;
  animation-name: collapseAnimation;
  animation-duration: 0.2s;
}
.rowCollapsableActive .cellContainer {
  opacity: 1;
  visibility: visible;
}

.tableFootnotes {
}
.tableFootnote {
  color: #656d76;
  padding: 0;
}

.tooltipBtn {
  margin-left: 10px;
}

.textLogo {
  display: inline-flex;
  width: 73px;
  height: 16px;
  vertical-align: middle;
  align-items: center;
}
.textLogo:first-child {
  margin-left: 0;
}
.textLogo:last-child {
  margin-right: 0;
}
.textLogo svg {
  width: 100%;
  height: 100%;
}

.btnTxt {
  font-size: inherit;
  font-weight: inherit;
  color: #c1122a;
  vertical-align: baseline;
}

.cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 32px 20px;
  background-color: #f4f6f8;
  border-radius: 4px;
}

.ctaBtn > :global(.btn) {
  background-color: transparent;
}

@media (max-width: 767px) {
  .cta {
    padding: 16px 29px;
    display: block;
  }
}
@media (max-width: 767px) {
  .cta {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .ctaBtn {
    display: flex;
    justify-content: center;
  }
}

.typographyBasic {
  font-style: normal;
  font-weight: normal;
  color: #212b36;
  font-size: 16px;
  line-height: 24px;
}

.typographyStrong {
  font-style: normal;
  font-weight: bold;
  color: #212b36;
  padding: 16px 0 12px 0;
  font-size: 18px;
  line-height: 27px;
}

.typographySmall {
  font-style: normal;
  font-weight: normal;
  color: #212b36;
  font-size: 14px;
  line-height: 21px;
}
@media (min-width: 768px) and (max-width: 1439px) {
  .typographyStrong {
    padding-top: 20px;
    font-size: 20px;
    line-height: 30px;
  }
}
@media (min-width: 1440px) {
  .typographyStrong {
    padding: 24px 0 12px 0;
    font-size: 24px;
    line-height: 36px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .buttons {
    justify-content: space-between;
  }
}

@media (max-width: 767.98px) {
  .membershipLabel {
    line-height: 2;
  }
}

@media (min-width: 768px) {
  .membershipLabel {
    text-align: left !important;
    line-height: 2;
  }
}
@media (min-width: 1024px) and (min-height: 730px) {
  .confirmButton {
    padding: 13px 34px;
  }
}