.close {
    position: absolute;
    left: auto;
    right: 12px;
    top: 12px;
    padding: 10px !important;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
}

.filters {
    position: absolute;
    top: 12px;
    left: 12px;
    padding: 10px !important;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
}

.instantRentalsOnly {
	white-space: nowrap;
	position: relative;
	z-index: 1;
    grid-area: instantRentalsOnly;
    display: flex;
    align-items: center;
    background: #fff;
    position: absolute;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}
.instantRentalsOnlyLabelContainer {
    padding: 14px 28px 14px 18px;
    cursor: pointer;
    font-weight: bold;
}

.instantRentalsOnlyCheck {
    left: auto;
}

.showFullListCheckboxContainer {
    background: #fff;
    position: absolute;
    top: 12px;
    left: 70px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    border-radius: 4px;
}

.showFullListCheckbox {
    font-weight: bold;
    padding: 10px 18px 12px 17px;
    cursor: pointer;
}

.showFullListCheckboxSquare {
    border: 2px solid #474342;
    left: auto;
}

@media (min-width: 768px) {
    .instantRentalsOnly {
        top: 16px;
        left: 16px;
    }
    
    .filters {
        display: none;
    }

    .showFullListCheckboxContainer {
        left: 259px;
        top: 16px;
    }

    .showFullListCheckbox {
        padding: 13px 28px 14px 17px
    }

    .close {
        top: 16px;
        left: auto;
        right: 16px;
        padding: 13px !important;
    }
}

@media (min-width: 1024px) {
    .showFullListCheckboxContainer {
        left: 282px;
        top: 32px;
    }
    .instantRentalsOnly {
        top: 32px;
        left: 32px;
    }
    .close {
        top: 32px;
        right: 32px;
    }
}

@media (min-width: 1440px) {
    .showFullListCheckboxContainer {
        left: 32px;
    }
}