.content {
  display: grid;
  --grid-columns-number: 4; 
  grid-template-columns: repeat(var(--grid-columns-number), minmax(10px, 1fr));
  grid-gap: 0 16px;
}

.autorPhoto { 
  grid-column: 1;
  grid-row: 1;
}  
.autorInfo { 
  grid-column: 2/-1;
  grid-row: 1;
}  
.description { 
  grid-row: 2;
}
.galery { 
  grid-row: 3;
}
.date { 
  grid-row: 4;
}

.description,
.galery,
.date {
  grid-column: 1/-1;
}

.avatarImg {
  width: 100%;
  border-radius: 50%;
}
@media (min-width: 768px) {
  .content {
    --grid-columns-number: 6; 
    grid-template-rows: auto 1fr auto auto;
  }

  .autorPhoto { 
    grid-row: 1/3;
  }  

  .description,
  .galery,
  .date {
    grid-column: 2/-1;
  }

}
@media (min-width: 1024px) {
  .content {
    --grid-columns-number: 7; 
    grid-gap: 0 24px;
  }

}
@media (min-width: 1440px) {
  .content {
    --grid-columns-number: 8; 
  }

  .autorInfo {
    display: flex;
    align-items: center;
  }

  .autorInfo > div {
    position: relative;
  }
  .autorInfo > div:not(:first-child)::before {
    position: absolute;
    left: 0;
    top: 14px;
    content: '';
    background: #C4CDD5;
    height: 27px;
    width: 1px;
  } 
}
@media (min-width: 1680px) {
  .content {
    grid-template-columns: 100px 1fr;
    grid-gap: 0 32px;
  }
}
