.modalRoot {
    overflow: hidden !important;
    max-height: 100% !important;
}

.modalRoot:before {
    display: none !important;
}

.modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden !important;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 16px;
}

.filters {
    composes: global(t-16) global(t-m-4) global(t-xl-16) global(b-80) global(b-m-40) global(mb-12) global(mb-m-0);
}

.bar {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
    padding: 16px;
}
