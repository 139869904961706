.root {
    background: none;
}

.columnReverse {
    flex-direction: column-reverse;
}

.header {
    cursor: pointer;
    align-items: flex-start;
}

.headerInner {
    flex: 1;
}

.closed .content {
    display: none;
}

.icon {
    margin-top: 10px;
}