.container {
  display: grid;
  grid-template-columns: minmax(92px, 160px) 1fr;
  grid-gap: 0 16px;
}

.title {
  color: var(--tertiary-light, #7F7F7F);
}

.value {
  font-weight: 500;
}

@media (min-width: 768px) {
  .container {
    grid-template-columns: minmax(109px, 160px) 1fr;
  }
}

@media (min-width: 1024px) {
  .container {
    grid-template-columns: minmax(140px, 1fr) 1fr;
  }
}

@media (min-width: 1440px) {
  .container {
    grid-template-columns: minmax(110px, 1fr) 1fr;
  }
}

@media (min-width: 1680px) {
  .container  {
    grid-template-columns: minmax(100px, 1fr) 1fr;
  }
}
