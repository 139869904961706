.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    overflow: hidden;
}

.shadow {
    position: absolute;
    box-shadow: 0 0 0 9999em rgb(0 0 0 / 50%);
    transform: translate3d(0, 0, 0);
}

:global(.ReactCrop) {
    overflow: visible;
}

:global(.ReactCrop__drag-handle::after) {
    background: white;
    border-radius: 50%;
    border: 0 none;
    width: 16px;
    height: 16px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
}

:global(.ReactCrop .ord-nw) {
    margin-top: -8px;
    margin-left: -8px;
}

:global(.ReactCrop .ord-ne) {
    margin-top: -8px;
    margin-right: -8px;
}

:global(.ReactCrop .ord-se) {
    margin-bottom: -8px;
    margin-right: -8px;
}

:global(.ReactCrop .ord-sw) {
    margin-bottom: -8px;
    margin-left: -8px;
}

:global(.ReactCrop__crop-selection) {
    border-image-source: url('./icons/dashed-line.svg');
    box-shadow: none;
}
