.main {
  justify-content: center;
}

@media (min-width:1024px) {
  .list {
    max-width: 632px;
  }
}

@media (min-width:1440px) {
  .list {
    max-width: 636px;
  }
}

@media (min-width:1680px) {
  .list {
    max-width: 760px;
  }
}
