.content :global(h1),
.content :global(h2),
.content :global(h3),
.content :global(h4),
.content :global(h5),
.content :global(h6) {
  font-weight: bold;
  padding: 16px 0 12px 0;
  font-size: 18px;
  line-height: 27px;
}

.content strong {
  font-weight: 500 !important;
}

.content u {
  text-decoration: none !important;
}

@media (min-width: 1440px) {
  .content :global(h1),
  .content :global(h2),
  .content :global(h3),
  .content :global(h4),
  .content :global(h5),
  .content :global(h6) {
    font-weight: bold;
    padding-top: 20px;
    font-size: 20px;
    line-height: 30px;
  }
}

.content :global(ul) {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-tertiary);
  padding-left: 24px;
}