.title, .subtitle {
  color: var(--widget-text-border-color, #474342);
}

.button {
  color: var(--widget-text-color, #474342) !important;
  background: var(--widget-background-color, #fff) !important;
  border-color: var(--widget-text-border-color, #474342) !important;
}
.button:hover,
.button:focus {
  background: var(--widget-pressed-button-color, #E6E6E6) !important;
}

.icon svg {
  width: 64px;
  height: 64px;
}

@media (min-width: 720px) {
  .icon svg {
    width: 96px;
    height: 96px;
  }
}