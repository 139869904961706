@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .checkoutContainer {
    margin: 0 5px;
  }
}
@media screen and (max-width: 767.98px) {
  .checkoutContainer {
    margin-top: -16px;
  }
}
