.collapse {
  border-radius: 4px;
  border: 2px solid var(--c-secondary);
}

.collapseIcon {
  position: absolute;
  right: 0;
  top: 18px;
  color: #212b36;
}
.collapseIcon svg {
  display: block;
}

.collapse :global .rc-collapse {
  background: none;
  border-radius: 0;
  border: none;
}

.collapse :global .rc-collapse > .rc-collapse-item {
  padding: 2px 16px 6px 16px;
}

.title {
  font-weight: 500;
}

.title > strong {
  font-weight: 500;
}
@media (max-width: 767px) {
  .collapse :global .rc-collapse > .rc-collapse-item-active {
    padding-bottom: 14px;
  }
}

.collapse :global .rc-collapse > .rc-collapse-item > .rc-collapse-header {
  position: relative;
  padding: 0;
  padding-right: 36px;
  outline: none;
}

.collapse :global .rc-collapse-content {
  background: none;
  border-radius: 0;
  border: none;
  padding: 0;
}

.collapse :global .rc-collapse-content > .rc-collapse-content-box {
  margin: 0;
}

.toRight {
  border: none;
}

.toRight :global .rc-collapse > .rc-collapse-item > .rc-collapse-header {
  justify-content: flex-end;
}
