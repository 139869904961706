.notificationTitle {
  padding-top: 0;
  padding-bottom: 0;
}

.notificationText {
  padding-bottom: 0;
}

@media (min-width: 1440px) {
  .aside {
    flex: 0 0 calc(25% + 24px);
    max-width: calc(25% + 24px);
  }
}
