.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: var(--cwrr-scroll-width, 0);
  background: #FFF;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  padding: 0 16px; 
  display: flex;
  align-items: center;
}

.footer.small {
  height: 70px;
  padding-bottom: 8px;
}
.footer.medium {
  height: 84px;
}
.table {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}
.row {
  display: table-row;
  height: 100%;
}
.col {
  display: table-cell;
  vertical-align: top;
  padding: 0;
  height: 100%;
  width: 1%;
}
.colContent {
  height: 100%;
  display: flex;
  align-items: center;
}
.col:not(:first-child) .colContent {
  justify-content: flex-end;
  padding-left: 16px;
}

.table .colText {
  padding-top: 20px;
  font-size: 20px;
  line-height: 30px;
}

.boldText .colText {
  font-weight: bold;
}
.footer.dn {
  display: none;
}
@media (min-width: 768px) {
  .footer.small,
  .footer.medium  {
    height: auto;
    position: static;
    box-shadow: none;
    padding: 0;
  }

  .footer.small .colText {
    padding-top: 20px;
  }

  .footer.db-m {
    display: block;
  }
  .footer.dn-m {
    display: none;
  }
}
@media (min-width: 1440px) {
  .footer.small .colText {
    padding-top: 24px;
    font-size: 24px;
    line-height: 36px;
  }
}