.modal {
  padding: 8px 24px 16px;
  width: 288px;
}

.actions {
  padding: 16px 0;
  display: flex;
  justify-content: flex-start;
}

.button {
  margin-left: 16px;
}

.button:first-child {
  margin-left: 0;
}

@media (min-width: 768px) {
  .modal {
    width: 530px;
    padding: 12px 56px 28px;
  }

  .text {
    padding-top: 4px;
    padding-bottom: 16px;
    font-size: 18px;
    line-height: 27px;
  }

  .actions {
    padding: 20px 0;
  }

  .button {
    margin-left: 32px;
  }
}
