.formItem {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 
    "formItemName formItemRemoveButton"
    "formItemEmail formItemEmail";
  grid-gap: 32px 16px;
}

.formItemName {
  grid-area: formItemName;
}

.formItemEmail {
  grid-area: formItemEmail;
}

.formItemRemoveButton {
  grid-area: formItemRemoveButton;
}

.trashDisabled {
  opacity: .5;
}

.footer {
  position: fixed;
  height: 84px;
  left: 0;
  right: var(--cwrr-scroll-width, 0);
  bottom: 0px;
  background: #FFF;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
}

.footerButtonsContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.addButton {
  font-weight: bold;
}

@media (min-width: 768px) {
  .formItem {
    grid-template-columns: 1fr 1fr auto;
    grid-template-areas: 
      "formItemName formItemEmail formItemRemoveButton";
    grid-gap: 32px 16px;
  }

  .footer {
    height: 92px;
  }
}

@media (min-width: 1024px) {
  .footer {
    position: static;
    box-shadow: none;
    height: auto;
    background: none;
  }
}