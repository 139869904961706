.myAccount {
  display: flex;
  flex-direction: column;
  composes: global(b-40) global(b-m-60) global(b-xl-120);
  composes: global(t-16);
  composes: global(t-m-60);
  composes: global(t-l-60);
}

@media (max-width: 767px) {
  :global(.myAccount_all-steps-view ._hidden-all-steps-view) {
    display: none;
  }

  :global(.myAccount_all-steps-view ._visible-all-steps-view) {
    display: block;
  }

  :global(.myAccount_step-view ._visible-all-steps-view) {
    display: none;
  }
}
