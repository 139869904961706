.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

.noScroll {
  overflow: hidden !important;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.closeButton {
  background-image: url("./icons/close-icon.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 42px;
  width: 42px;
  overflow: hidden;
  text-indent: 100px;
  border-radius: 0;
}
