.main {
  padding: 20px 40px;
  background: var(--c-grey-cool, #EEE);
  border-radius: 4px;
}
.loadingQueryPlaceholderWhite {
  background: #fff;
  color: #fff;
}

.loadingQueryPlaceholderGray {
  background: #f4f6f8;
  color: #f4f6f8;
}
@media (width < 768px) {
  .main {
    padding: 16px 20px;
  }
}

.title {
  text-align: center;
  color: #474342;
}

.logo {
  vertical-align: middle;
  width: auto;
  height: 22px;
}
@media (width < 768px) {
  .logo {
    height: 16px;
  }
}

.body {
  display: flex;
  align-items: center;
}
@media (width < 768px) {
  .body {
    display: block;
  }
}

.items {
  display: flex;
  margin: 0 -12px;
  padding: 20px 0;
}
@media (width < 768px) {
  .items {
    display: block;
    margin: -16px 0;
    padding: 16px 0;
  }
}

.item {
  flex: 1 1 0;
  margin: 0 12px;
  padding: 2px 20px 16px;
  background: #ffffff;
  border-radius: 8px;
}
@media (width < 768px) {
  .item {
    margin: 16px 0;
    padding: 4px 16px 12px;
  }
}

.cta {
  padding: 20px 0;
  margin-left: 40px;
}
@media (width < 768px) {
  .cta {
    padding: 16px 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
}

.footer {
  padding-top: 8px;
}
@media (width < 768px) {
  .main {
    padding: 12px 20px;
  }

  .items {
    margin: -19px 0 -15px;
  }
  .title {
    line-height: 32px;
  }
  .item > .itemTitle {
    text-align: center;
  }
  .item > .itemText {
    text-align: center;
  }
}
@media (width >= 768px) {
  .items {
    display: grid;
    grid-template-rows: [heading] auto [content] auto;
    grid-template-columns: 1fr 1fr;
  }

  .item {
    display: contents;
  }

  .item > .itemTitle {
    grid-row: heading;
    display: flex;
    margin: 0px 4px 0 13px;
    padding: 16px 20px 12px 20px;
    border-radius: 8px 8px 0 0;
    background: #ffffff;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }

  .item > .itemText {
    grid-row: content;
    margin: 0px 4px 0 13px;
    padding: 0px 20px 19px 20px;
    border-radius: 0 0 8px 8px;
    background: #ffffff;
  }

  .item:last-child > .itemTitle {
    margin-left: 21px;
    margin-right: -4px;
  }
  .item:last-child > .itemText {
    margin-left: 21px;
    margin-right: -4px;
  }
  .cta {
    margin-left: 60px;
  }
}
