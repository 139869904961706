.avatar {
  position: relative;
}

.avatarImg {
  width: 100%;
  border-radius: 50%;
}

.avatarBadgeContainer {
  position: absolute;
  bottom: -12px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.avatarBadgeContent {
  width: 60px;
  height: 28px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: grid;
  place-content: center;
}
@media (min-width: 768px){
  .avatarBadgeContent  {
    width: 100px;
  }
  .avatarBadgeContainer {
    bottom: -12px;
  }
}
@media (min-width: 1024px) and (max-width: 1439.98px) {
  .avatarBadgeContent {
    width: 58px;
  }
}
@media (min-width: 1440px){
  .avatarBadgeContent  {
    width: 86px;
  }
  .avatarBadgeContainer {
    bottom: -15px;
  }
}