.item {
  display: block;
  margin: 0;
  padding: 9px 12px;
  font-size: 16px;
  line-height: 24px;
  color: #212b36;
  cursor: pointer;
  transition: background 200ms;
}
.item:hover {
  background: #eaecef;
}
