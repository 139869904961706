.listItem:last-child {
  margin-top: -1px;
}

.topCard {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.bottomCard {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
