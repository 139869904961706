
.row {
  display: flex;
  width: 100%;
}

.rowText {
  color: var(--c-tertiary);
}

.number {
  flex: none;
  width: 32px;
  margin-right: 16px;
  text-align: right;
}

.email {
  flex: 1;
  min-width: 0;
}

.email .rowText {  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sum {
  width: 232px;
  flex: none;
  display: flex;
  justify-content: flex-end;
  margin-left: 32px;
}

@media (max-width: 767px) {
  .number {
    width: 24px;
    margin-right: 8px;
  }

  .sum {
    width: auto;
    margin-left: 16px;
  }
}