.root {
    border-top: 1px solid var(--c-grey);
    min-height: 69px;
}

.iconStart {
    font-size: 24px;
    color: var(--c-tertiary-light);
}

.input {
    flex: 1;
    align-self: center;
    max-height: 112px;
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0 !important;
    padding: 8px 0 !important;
    background: none !important;
    box-shadow: none !important;
    font-size: 16px;
    line-height: 24px;
    color: var(--c-tertiary);
    resize: none;
    overflow-y: scroll !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.input::-webkit-scrollbar {
    display: none;
}

.input::placeholder,
.input::-webkit-input-placeholder {
    color: var(--c-tertiary-light);
    opacity: 0.5;
}

.input:-moz-placeholder,
.input::-moz-placeholder {
    color: var(--c-tertiary-light);
    opacity: 0.5;
}

.button {
    padding-left: 24px !important;
    padding-right: 24px !important;
    align-self: flex-end;
}

.clearButton {
    color: var(--c-tertiary-light);
    animation: fade-in 300ms;
}

.mobileButton {
    width: 52px;
    align-self: flex-end;
}

@media (max-width: 767.98px) {
    .root {
        min-height: 53px;
    }
}
