@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
  animation: fade-in 300ms;
}

.dropdown {
}

.paper {
  position: relative;
  padding: 4px 0;
  background-color: #fff !important;
  box-shadow: none !important;
  border: 1px solid #474342;
  border-radius: 3px !important;
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
