.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.item {
  width: calc(33.3% - 16px);
}


@media (min-width: 768px) {
  .container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
