.filters {
  display: block;
}

.sortSelect :global(.field-select-button):focus {
  border: 1px solid rgba(33, 43, 54, 0.3);
}
.menuSelectSort {
  border: 1px solid rgba(33, 43, 54, 0.3) !important;
}
.menuSelectSort.darkTheme {
  border: 1px solid #474342 !important;
}
.sortSelect.darkTheme .sortSelectLabel {
  color: var(--widget-text-color, #474342);
}
.sortSelect.darkTheme :global(.ui-selectmenu-text) {
  color: var(--widget-text-color, #474342);
}
.sortSelect.darkTheme :global(.field-select-button):focus {
  border: 1px solid var(--widget-text-color, #474342);
}
.sortSelect.darkTheme :global(.field-select-button) {
  border: 1px solid var(--widget-text-color, #474342);
}
.sortSelect.darkTheme :global(.ui-selectmenu-button-closed .ui-selectmenu-icon) {
  background: url("components/Widget/components/WidgetFilters/select-dark-arrow-dawn.svg") no-repeat 0 0;
  width: 20px;
  height: 20px;
  margin-top: -6px;
  margin-right: 0px;
}
.sortSelect.darkTheme :global(.ui-selectmenu-button-open .ui-selectmenu-icon) {
  background: url("components/Widget/components/WidgetFilters/select-dark-arrow-dawn.svg") no-repeat 0 0;
  transform: rotate(180deg);
  width: 20px;
  height: 20px;
  margin-top: -6px;
  margin-right: 0px;
}
@media (min-width: 720px) {
  .filters {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 
      minmax(190px, max-content) 
      minmax(150px, max-content) 
      minmax(134px, max-content) 
      1fr;
  }

  .filters.filtersSmall {
    grid-template-columns: 
      minmax(190px, max-content) 
      minmax(134px, max-content) 
      1fr;
  }

  .filters.filtersVerySmall {
    grid-template-columns: 
      minmax(190px, max-content) 
      1fr;
  }

  .filters.isHiddenFilters {
    grid-template-columns: 1fr;
    justify-content: flex-start;
  }
  .filterButtons {
    display: flex;
  }
  .sort {
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
  }
  .sortSelect {
    width: 235px;
  }

}

@media (min-width: 1024px) {
  .filters {
    grid-template-columns: 
      minmax(50px, max-content) 
      minmax(150px, max-content) 
      minmax(134px, max-content) 
      1fr;
  }
  .sortSelect {
    width: 222px;
  }
}


@media (min-width: 1440px) {
  .sortSelect {
    width: 306px;
  }
}

@media (min-width: 1680) {
  .sortSelect {
    width: 232px;
  }
}
