.altColor {
    color: var(--c-charcoal);
}

.cardStripe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 24px;
    background: rgba(196, 205, 213, 0.2);
    border-radius: 8px 0 0 8px;
}

.number {
    letter-spacing: 0.2em;
}

.header {
}

.heading {
    color: var(--c-charcoal);
    padding-bottom: 0;
}

.accType {
    margin: 0;
}

.infoRow:not(:last-child) {
    margin-bottom: 30px;
}

.infoRowLabel {
    color: var(--c-charcoal);
    padding-top: 0 !important;
    padding-bottom: 12px !important;
    font-weight: 400;
}

.infoRowContent > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.infoRow {
}

@media (min-width: 768px) {
    .header {
        padding-top: 10px;
    }

    .heading {
        margin-top: -10px;
    }

    .accType {
        position: absolute;
        right: 64px;
        top: 52px;
    }
}

@media (min-width: 1024px) and (max-width: 1679.98px) {
    .accType {
        right: 52px;
    }
}

@media (max-width: 767.98px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .heading {
        margin-bottom: 12px;
    }

    .cardStripe {
        display: none;
    }

    .accType {
        margin-top: 16px;
    }

    .infoRow:not(:last-child) {
        margin-bottom: 20px;
    }

    .infoRowLabel {
        padding-bottom: 8px !important;
    }
}
