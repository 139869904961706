.localSlider > div {
  width: 100%;
}
.localSliderItem {
}

.localSliderItemInner {
  border-radius: 4px;
}

.localSlider :global(.slick-slide > div) {
  margin: 0 8px;
}
.localSlider :global(.slick-list) {
  margin: 0 -8px;
}
.localSliderItemInner {
  background: #fff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
}

.localSliderImageContainer {
  position: relative;
  aspect-ratio: 1/1;
}

.localSliderImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.localSliderGoodSamBadge {
  position: absolute;
  right: 11px;
  top: 16px;
  z-index: 1;
}

.localSliderAddressContainer {
  position: absolute;
  left: 16px;
  bottom: 12px;
  z-index: 1;
}
.localSliderAddress {
  color: #fff;
  padding-bottom: 2px;
}
.localSliderPlace {
  color: #fff;
  padding-top: 0;
  padding-bottom: 4px;
}

.navArrow {
  width: 42px;
  height: 42px;
  text-indent: -99999px;
  border: none;
}

.navPrevArrow {
  background: url(assets/images/white-left-arrow-slider.svg) 0 0 no-repeat; 
}
.navNextArrow {
  background: url(assets/images/white-right-arrow-slider.svg) 0 0 no-repeat; 
}

.navPrevArrow:hover {
  background: url(assets/images/red-left-arrow-slider.svg) 0 0 no-repeat; 
}
.navNextArrow:hover {
  background: url(assets/images/red-right-arrow-slider.svg) 0 0 no-repeat; 
}

.dots {
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 0 16px;
}

.dots button {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #E9C8CE;
  text-indent: -99999px;
  border: none;
}

.dots > :global(.slick-active > button) {
  background:  #C1122A;
}

@media (min-width: 768px){
  .localSliderAddressContainer {
    bottom: 20px;
  }
}
@media (min-width: 1024px){
  .localSlider :global(.slick-slide > div) {
    margin: 0 12px;
  }
  .localSlider :global(.slick-list) {
    margin: 0 -12px;
  }
}

@media (min-width: 1440px){
  .localSliderAddressContainer {
    bottom: 25px;
  }
}

@media (min-width: 1440px){
  .localSlider :global(.slick-slide > div) {
    margin: 0 16px;
  }
  .localSlider :global(.slick-list) {
    margin: 0 -16px;
  }
  .localSliderAddressContainer {
    bottom: 28px;
  }
}