.container {
  border: 1px solid #BFC5CE;
  border-radius: 8px;
  padding-bottom: 14px;
}

.topContent {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 
    "date total"
    "totalEarnTitle totalEarn"
    "taxesContainer taxesContainer";
}

.date {
  grid-area: date;
}

.dateText {
  color: var(--c-tertiary);
  line-height: 25px;
}

.total {
  grid-area: total;
}

.totalText {
  line-height: 25px;
  text-align: right;
}

.totalTextPrice {
  color: var(--c-primary);
  font-weight: 400;
}

.totalEarnTitle {
  grid-area: totalEarnTitle;
  margin-top: 2px;
}

.totalEarn {
  grid-area: totalEarn;
  text-align: right;
  cursor: pointer;
}

.totalEarnText {
  color: var(--c-tertiary-light);
  font-weight: 400;
}

.totalEarnSum {
  display: inline-flex;
  margin-top: 2px;
  color: var(--c-tertiary-light);
}

.totalEarnContainer {
  grid-area: totalEarnContainer;
  display: contents;
}

.totalEarnContainerInner {
  display: contents;
}

.taxesContainer {
  grid-area: taxesContainer;
  border-top: 1px solid #EAECEF;
  border-bottom: 1px solid #EAECEF;
  display: flex;
  justify-content: space-between;
}

.taxesText {
  color: var(--c-tertiary-light);
}

.taxesTitleText {
  color: var(--c-tertiary-light);
}

.questionIcon {
  cursor: pointer;
}

.footerLink {
  color: var(--c-tertiary-light);
  text-decoration: underline;
  font-weight: 400;
}

.footerLink:hover,
.footerLink:focus {
  text-decoration: none;
  color: var(--c-tertiary-light);
}

.rvName,
.fullDate {
  margin-top: -2px;
}
.rvNameLink {
  width: 100%;
  display: block;
}
.rvNameLink span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 768px) {
  .container {
    padding-bottom: 17px;
  }
  .topContent {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: 
      "date taxesContainer total"
      "totalEarnContainer totalEarnContainer totalEarnContainer";
  }

  .dateText {
    padding-bottom: 2px;
  }
  .total {
    text-align: right;
  }

  .totalText {
    padding-bottom: 2px;
  }

  .taxesTextPrice {
    font-weight: 400;
  }

  .taxesContainer {
    border: none;
    align-items: center;
    position: relative;
  }

  .taxesContainer::before {
    content: '';
    width: 1px;
    top: 15px;
    left: 0;
    bottom: 2px;
    position: absolute;
    background: var(--c-quaternary-dark);
  }

  .taxesTitleText {
    padding-bottom: 2px;
  }
  .taxesText {
    padding-bottom: 2px;
  }

  .totalEarnContainer {
    grid-area: totalEarnContainer;
    display: flex;
    justify-content: flex-end;
    margin-top: 2px;
  }
  .totalEarnContainerInner {
    display: flex;
    cursor: pointer;
  }
  
  .totalEarn {
    display: flex;
  }

  .totalEarnText {
    padding-top: 0;
  }

  .totalEarnSum {
    padding-top: 0;
    margin-top: 0;
  }

  .totalEarnTitle {
    margin-top: 0;
  }

  .footer {
    display: flex;
    border-top: 1px solid #EAECEF;
    margin-top: 6px;
    padding-top: 12px;
  }

  .footerItem {
    overflow: hidden;
  }
  
  .footerLink {
    white-space: nowrap;
  }

  .autorName,
  .fullDate {
    flex-shrink: 0;
  }
  .rvName,
  .fullDate {
    position: relative;
    margin-top: 0;
    padding-left: 16px;
    margin-left: 16px;
  }
  
  .rvName::before,
  .fullDate::before {
    content: '';
    width: 1px;
    top: 5px;
    bottom: 5px;
    left: 0;
    position: absolute;
    background: var(--c-quaternary-dark);
  }

  .rvNameLink {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rvNameLink span {
    white-space: nowrap;
  }

}

@media (min-width: 1440px) {

  .container {
    padding-top: 14px;
    padding-bottom: 18px;
  }

  .taxesTitleText {
    font-size: 20px;
    line-height: 30px;
  }

  .taxesText {
    font-size: 20px;
    line-height: 30px;
  }

  .totalEarnContainer {
    margin-top: 4px;
  }
}
