.readMore {
  color: #C1122A;
}

.aboutTitle {
  padding-bottom: 8px;
}

@media (min-width: 768px){
  .container {
    background: #FFF;
    box-shadow: 0px 2px 20px rgba(101, 109, 118, 0.2);
    border-radius: 8px;
  }

  .readMore {
    padding: 0;
  }
  .readMoreButton {
    padding-top: 8px;
  }
  .editProfileButton {
    padding: 13px 22px;
  }
}