.modalWrap {
  height: 100%;
  background: #fff;
}

.modal {
  box-shadow: none;
  width: 100vw;
  min-height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  max-height: 100%;
  overflow-y: auto;
  background: none;
}

.crossButton {
  width: 52px;
  height: 52px;
}

.buttonGoTo {
  font-weight: bold;
}

.content {
  padding: 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 
    "rightColumn"
    "leftColumn";
}

.leftColumn {
  grid-area: leftColumn;
}

.rightColumn {
  grid-area: rightColumn;
}

.subtitle {
  font-weight: 500;
}

@media (min-width: 768px) {
  .header {
    display: flex;
    justify-content: space-between;
    padding-right: 51px;
  }

  .title {
    padding: 32px 0 16px;
    font-size: 28px;
    line-height: 42px;
  }

  .grid {
    grid-template-areas: 
      "leftColumn"
      "rightColumn";
  }
}
@media (min-width: 1024px) {
  .header {
    padding-right: 61px;
  }

  .title {
    padding: 32px 0 16px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1440px) {
  .crossButton {
    top: 0px;
  }

  .header {
    padding-right: 0px;
  }

  .grid {
    grid-template-areas: 
      "leftColumn rightColumn";
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 24px;
  }

  .title {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (min-width: 1680px) {
  .grid {
    grid-gap: 0 32px;
  }
  
  .content {
    margin: 0 auto;
  }
}
