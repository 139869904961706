.root {
  position: relative;
}

.root::after {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  content: "";
  width: 100px;
  background-image: linear-gradient(
    271.15deg,
    #ffffff 1.13%,
    rgba(255, 255, 255, 0) 99.21%
  );
}

.emailText,
.phoneText {
  font-weight: normal;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .nav::after {
    display: inline-block;
  }
}

@media (min-width: 168px) {
  form .row {
    padding-bottom: 30px;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.hr {
  max-width: 100%;
}

.container {
  max-width: 760px;
}

.widePaddingTop {
  margin-top: 38px;
}

.description {
  color: var(--tertiary-light, #7F7F7F);
  font-weight: 400;
}

.subtitle {
  composes: global(l-8) global(r-8) global(r-m-20) global(r-xl-8) global(t-20) global(b-20) global(t-xl-20) global(b-xl-20);
  display: flex;
  align-items: flex-start;
  white-space: normal;
  border-radius: 4px;
  font-size: 20px;
  line-height: 30px;
  color: var(--c-black-cool);
  outline: none;
  padding-top: 12px;
  padding-bottom: 12px;
}



.leftpanel h5 {
  font-size: 16px;
}

h5.forgotPassword {
  min-width: 200px;
  margin-left: 24px;
}

.editor {
  display: flex;
  margin-top: 20px;
  padding-bottom: 32px;
}

.passwordEditor {
  margin-top: 4px;
  padding-bottom: 6px;
}

.passwordEditor div:nth-child(2) {
  margin-top: 40px;
}

.editor button {
  margin-left: 32px;
  min-width: 82px;
}

.passwordEditor button {
  min-width: 101px;
  margin-top: 40px;
}

.oldPasswordEditor {
  display: flex;
  justify-content: flex-start;
}

.centralpanel {
  margin-top: auto;
}

.centralpanel.inEditing {
  z-index: -1;
}

.centralpanel h6 {
  font-size: 18px;
}

.isVerified {
  color: var(--c-tertiary, #232323);
  display: flex;
  align-items: center;
  margin-top: -6px;
  font-weight: 400;
}

.isVerified span {
  margin-left: 8px;
}

.isVerified svg {
  margin: auto;
  width: 24px;
  height: 24px;
  color: var(--c-status-success-dark);
}

.rightpanel button {
  font-weight: 700;
  float: right;
}

.list {
  composes: global(dn) global(db-m) global(l-0) global(r-0) global(t-16) global(b-16) global(t-m-20) global(b-m-20);
  white-space: nowrap;
  overflow-x: auto;
  list-style: none;
  margin: 0;
}

.item {
  composes: global(mr-m-16) global(mr-xl-0) global(db) global(dib-m) global(db-xl);
}

.item:last-child {
  margin-right: 0;
}

.link {
  composes: global(l-8) global(r-8) global(r-m-16) global(r-xl-8) global(t-8) global(b-16) global(t-xl-16) global(b-xl-16);
  display: flex;
  align-items: flex-start;
  white-space: normal;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-primary);
  cursor: pointer;
  outline: none;
  padding-top: 0px;
  font-weight: normal;
}

.link::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 8px;
}

.link:hover {
  color: var(--c-primary);
}

.disabledLink {
  composes: link;
  cursor: default;
}

.disabledLink:hover {
  color: var(--c-black-cool);
}

.activeLink {
  font-weight: 500;
  background-color: rgba(196, 205, 213, 0.3);
}

.activeLink:hover {
  color: var(--c-primary);
}

.alignCenter {
  align-items: center;
}

@media (min-width: 768px) {
  .isVerified {
    color: var(--c-tertiary, #232323);
    display: flex;
    margin-top: 4px;
  }
}
@media (min-width: 1440px) and (max-width: 1920px) {
  .leftcentralpanel {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
  .leftcentralpanel .centralpanel {
    padding-left: 6px;
  }
  .centralpanel {
    display: flex;
    padding-top: 72px;
    margin-left: auto;
    justify-content: center;
  }
  .editor input {
    width: 364px;
  }
  .phoneNumberValue {
    min-width: 150px;
  }
  .passwordEditor input {
    width: 364px;
    margin-bottom: 40px;
  }
  .container {
    max-width: 100%;
  }
  .passwordEditor div:nth-child(2) {
    margin-top: 0px;
  }
  .forgotPassword {
    display: block;
    font-size: 16px;
    padding-left: 32px;
    margin: 0px;
  }
  .oldPasswordEditor button {
    margin-top: -8px;
    border: none;
    outline: none;
    background-color: white;
    box-shadow: none;
  }
  .oldPasswordEditor button p {
    color: #212b36;
  }
  .oldPasswordEditor button .body--l {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .oldPasswordEditor button:focus,
  .oldPasswordEditor button:hover,
  .oldPasswordEditor button:active,
  .oldPasswordEditor button.animate {
    outline: none;
    box-shadow: none;
  }
  .leftpanel {
    width: 100%;
  }
  .editor input {
    width: 264px;
  }
  .passwordEditor input {
    width: 264px;
  }
  .offset-auto {
    padding-left: 0px;
  }
  .leftpanel {
    width: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .leftcentralpanel {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
  .leftcentralpanel .centralpanel {
    padding-left: 6px;
  }
  .centralpanel {
    display: flex;
    padding-top: 72px;
    margin-left: auto;
    justify-content: center;
  }
  .editor input {
    width: 364px;
  }
  .phoneNumberValue {
    min-width: 150px;
  }
  .passwordEditor input {
    width: 364px;
    margin-bottom: 40px;
  }
  .container {
    max-width: 100%;
  }
  .passwordEditor div:nth-child(2) {
    margin-top: 0px;
  }
  .forgotPassword {
    display: block;
    font-size: 16px;
    padding-left: 32px;
    margin: 0px;
  }
  .oldPasswordEditor button {
    margin-top: -8px;
    border: none;
    outline: none;
    background-color: white;
    box-shadow: none;
  }
  .oldPasswordEditor button p {
    color: #212b36;
  }
  .oldPasswordEditor button .body--l {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .oldPasswordEditor button:focus,
  .oldPasswordEditor button:hover,
  .oldPasswordEditor button:active,
  .oldPasswordEditor button.animate {
    outline: none;
    box-shadow: none;
  }
  .leftpanel {
    width: 100%;
  }
  .editor input {
    width: 264px;
  }
  .passwordEditor input {
    width: 264px;
  }
  .offset-auto {
    padding-left: 0px;
  }
  .leftpanel {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .leftcentralpanel {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
  .centralpanel {
    display: flex;
    padding-top: 72px;
    margin-left: auto;
    justify-content: center;
  }
  .editor input {
    width: 364px;
  }
  .passwordEditor input {
    width: 364px;
    margin-bottom: 40px;
  }
  .container {
    max-width: 100%;
  }
  .passwordEditor div:nth-child(2) {
    margin-top: 0px;
  }
  .forgotPassword {
    display: block;
    font-size: 16px;
    padding-left: 32px;
  }
  .oldPasswordEditor button {
    margin-top: 0px;
    border: none;
    outline: none;
    background-color: white;
    width: 200px;
    box-shadow: none;
  }
  .oldPasswordEditor button p {
    color: #212b36;
  }
  .oldPasswordEditor button:focus,
  .oldPasswordEditor button:hover,
  .oldPasswordEditor button:active,
  .oldPasswordEditor button.animate {
    outline: none;
    box-shadow: none;
  }
  .leftpanel {
    width: 100%;
  }
  .editor input {
    width: 264px;
  }
  .passwordEditor input {
    width: 264px;
  }
  .offset-auto {
    padding-left: 0px;
  }
}

@media (min-width: 560px) {
  .rightpanel {
    padding-top: 32px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .row {
    display: flex;
    flex-direction: column;
  }
  .leftwovalueoreditorrightpanel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: -webkit-fill-available;
  }
  .leftwovalueoreditorrightpanel .rightpanel {
    float: right;
  }
  .editor {
    width: -webkit-fillavailable;
    justify-content: space-between;
  }
  .leftvalueandeditorcentralpanel {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
  .field-input--phone {
    width: 100%;
  }
  .leftvalueandeditorcentralpanel .centralpanel {
    padding-left: 8px;
  }
  .centralpanel h6::before {
    content: none;
  }
  .oldPasswordEditor {
    flex-direction: column;
  }
  h5.forgotPassword {
    margin-left: 0px;
    padding-left: 0px;
  }
  .oldPasswordEditor button {
    margin-top: 0px;
  }
  .oldPasswordEditor button {
    margin-top: 0px;
    border: none;
    outline: none;
    background-color: white;
    width: 200px;
    box-shadow: none;
    padding-left: 0px;
    margin-left: 0px;
  }
  .oldPasswordEditor button p {
    color: #212b36;
  }
  .oldPasswordEditor button:focus,
  .oldPasswordEditor button:hover,
  .oldPasswordEditor button:active,
  .oldPasswordEditor button.animate {
    outline: none;
    box-shadow: none;
  }
  .newPasswordEditor {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 0px;
  }
  .passwordEditor div:nth-child(2) {
    margin-top: 0px;
  }
  .container {
    max-width: 100%;
  }
  .leftpanel {
    min-width: 12px;
  }
  .container h2 {
    padding-bottom: 0px;
  }
  .generalSettingsInput {
    width: 100%;
  }
}
