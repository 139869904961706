.spinnerContainer {
    min-height: 400px;
    position: relative;
}

.wrapper:after {
    content: " ";
    display: table;
    clear: both;
}

.root {
    composes: global(row) global(no-gutters) global(f-nowrap);
    width: 100%;
    display: grid;
    --grid-columns-number: 12; 
    grid-template-columns: repeat(var(--grid-columns-number), minmax(10px, 1fr));
    grid-gap: 0 24px;
    margin: 0 auto;
    padding-left: 72px;
    padding-right: 72px;
}

.content {
    composes: global(container) global(t-8) global(t-m-32);
    position: relative;
    z-index: 1;
    padding-top: 32px;
    margin: 0;
    width: 100%;
    max-width: 100%;
    grid-column: 1/8;
    padding-right: 0;
    padding-left: 0;
}

.map {
    pointer-events: none;
    z-index: 1;
    position: sticky;
    top: var(--cwrr-search-map-offset, 0);
    height: calc(100vh - var(--cwrr-search-map-offset, 0));
    float: right;
    width: 100%;
    margin-left: -100%;
}

.mapInner {
    pointer-events: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc((50% - 72px + 12px) / 6 * 7 + 72px);
    width: calc((50% - 72px + 12px) / 6 * 5 - 24px + 72px);
}

.isMapClosed .map {
    display: none;
}

.isMapClosed .root{
    display: block;
    width: 100%;
}

.isMapClosed .content {
    width: 100%;
}

@media (min-width: 1680px) {
    .root {
        grid-gap: 0 32px;
        padding-left: 0px;
        padding-right: 0px;
        width: 1552px;
    }

    .isMapClosed .root{
        display: grid;
        width: 1552px;
    }

    .isMapClosed .content {
        grid-column: 1/-1;
    }

    .isMapOpen .mapInner {
        left: calc(50% + (1552px + 32px) / 12 + 16px);
        width: calc(50% - (1552px + 32px) / 12 - 16px);
    
    }
}

@media (max-width: 1439.98px) {
    .root {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }

    .content {
        padding: 32px 32px 0;
    }
    .isMapOpen .content {
        grid-column: 1/13
    }
    .isMapOpen .list {
        display: none;
    }

    .isMapOpen .map {
        width: 100%;
        position: relative;
    }

    .isMapOpen .mapInner {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        width: auto;
    }
}
@media (max-width: 1023.98px) {
    .content {
        padding: 32px 16px 0;
    }

}
@media (max-width: 767.98px) {
    .isMapOpen .root {
        display: none;
    }

    .content {
        padding: 8px 16px 0;
    }

    .map {
        top: auto;
        height: 100vh;
        float: none;
        display: block;
        margin-left: 0;
    }
}
