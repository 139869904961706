.crossButton {
  width: 42px;
  height: 42px;
}

.modal {
  padding: 8px 16px 16px;
  max-height: 100%;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .modal {
    width: 486px;
    padding: 12px 56px 28px;
    overflow-y: visible;
    max-height: none;
    min-height: auto;
  }

  .crossButton {
    width: 52px;
    height: 52px;
  }
}

@media (min-width: 1024px) {
  .modal {
    width: 468px;
  }
}

@media (min-width: 1440px) {
  .modal {
    width: 464px;
  }
}

@media (min-width: 1680px) {
  .modal {
    width: 496px;
  }
}
