.containerSpinnerPlaceholder {
  position: relative;
  max-width: 364px;
  min-height: 299px;
  display: none;
}
.startBooking {
  width: 364px;
}

.booking {
  position: relative;
}

.bookingContainer {
  position: relative;
  z-index: 11;
  min-width: 320px;
  margin: 0 auto;
}
.crossButton {
  position: fixed;
  width: 52px;
  height: 52px;
  right: var(--cwrr-scroll-width, 0);
}
.booking {
}

.numb {
  font-size: 24px;
  line-height: 36px;
}

.price {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
}
.boxingBoxPriceStart {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-tertiary);
}
.boxingBoxPriceStart b {
  font-size: 24px;
  line-height: 36px;
  margin-right: 8px;
}
.boxingBoxPriceStart div {
  margin-bottom: 4px;
}
.priceDiscount {
  color: #656d76;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin-right: 12px;
  text-decoration-line: line-through;
}

.discount {
  background-color: #39842b;
  height: 32px;
  width: 58px;
  border-radius: 4px;
  align-items: flex-start;
  padding: 2px 8px;
  font-size: 18px;
  line-height: 28px;
  text-transform: none;
  font-family: Aeonik, Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #fff;
  margin-left: auto;
}

.additional {
  margin-top: 4px !important;
  color: #656d76;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.modalRoot:before {
  display: none !important;
}

.modal {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100dvh;
  max-height: 100dvh;
  overflow-y: auto;
}

.dayPickerInfo {
  height: 86px;
  border-top: 1px solid var(--c-quaternary-dark);
  border-bottom: 1px solid var(--c-quaternary-dark);
  margin: 7px 0px 20px;
  width: auto;
}
.dayPickerInner {
  display: flex;
  height: 60px;
  margin-top: 6px;
  cursor: pointer;
}
.dayPickerRow {
  position: relative;
  width: 50%;
}
.fieldInputIcon {
  position: absolute;
  right: 24px;
  top: 6px;
  line-height: normal;
  font-size: 20px;
}

.fieldInputIcon svg {
  width: 20px;
  height: 20px;
}
.dayPickerRow > label {
  left: 0;
}
.dayPickerRow:first-child {
  border-right: 1px solid var(--c-quaternary-dark);
}
.dayPickerRow:last-child label {
  left: 26px;
}
.dayPickerRow:last-child {
  padding-left: 25px;
}
.dayPickerLabel {
  display: block;
  margin-top: 6px;
}

.dayPickerValue {
  padding-top: 12px;
  margin-top: -12px;
}

.dayPickerRow:last-child .fieldInputIcon {
  right: 0;
}
.valueField {
  padding: 0;
}
.boxingBoxPrice {
  height: 56px;
}
.boxingBoxPriceSpinnerContainer {
  position: relative;
  top: -10px;
  transform: scale(.7, .7);
}
.boxingBoxPriceContainer {
  position: relative;
  height: 56px;
}
.boxingBoxPriceInner {
  position: relative;
}
.boxingBoxPricePerNight {
  display: flex;
}

.boxingBoxPricePrice {
  font-size: 18px;
  line-height: 27px;
  font-weight: normal;
  color: var(--c-tertiary-light, #7F7F7F);
  text-decoration-line: line-through;
  margin-right: 9px;
  margin-top: 4px;
}

.boxingBoxPricePerNightText {
  composes: global(body--l);
  margin-top: -4px;
}

.boxingBoxPriceTotal {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin-right: 8px;
  margin-top: 2px;
}
.boxingBoxPriceDuration {
  composes: global(body--s);
  margin-top: -5px;
  color: var(--c-tertiary-light, #7F7F7F);
}
.boxingBoxPriceDiscount {
  position: absolute;
  top: 19px;
  right: 2px;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  padding: 0;
  background: var(--c-status-success-dark, #39842B);
  border-radius: 4px;
}
.boxingBoxPickUpDeliveryContainer {
  border-bottom: 1px solid #c4cdd5;
  margin: 0 0px 10px;
  padding-bottom: 10px;
}
.continueButtonContainerInner {
  height: 100%;
}
.menuPipeHintContent {
  display: block;
  max-width: 343px;
}
.tabsTitle {
  composes: global(body--l);
}
.tabsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabsTooltipContainer {
  cursor: pointer;
}
.btnIcon {
  width: 24px;
  height: 24px;
}
.btnIcon svg {
  color: #757272;
}

.btnIcon:hover svg {
  color: var(--c-tertiary);
}
.tooltipQuestionContainer {
  width: 300px;
}
.tooltipQuestionContainerText {
  color: #fff;
}

.dayPickerLabel {
  composes: global(body--l);
  font-weight: 500;
}

.dayPickerValue {
  composes: global(body--l);
}

@media screen and (min-width: 768px) {
  .boxingBoxPriceTotal {
    font-size: 24px;
    line-height: 36px;
    margin-right: 8px;
  }

  .boxingBoxPricePrice {
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 567.98px) {
  .boxingContainer {
    margin: 0 auto;
    padding-top: 37px;
  }
  .booking {
    box-shadow: none;
    padding: 0;
  }

  .boxingBoxPriceDiscount {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 28px;
  }

  .dayPickerLabel {
    margin-top: 2px;
  }

  .dayPickerRow:last-child {
    padding-left: 16px;
  }

  .dayPickerRow:last-child .fieldInputIcon {
    right: 2px;
  }

  .boxingBoxPickUpDeliveryContainer {
    padding-bottom: 20px;
  }

  .boxingBoxPickUpDeliveryContainer {
    margin: 0 0px 12px;
  }
}

@media screen and (min-width: 568px) and (max-width: 1023.98px) {

  .tabsContainer {
    margin-top: 14px;
  }
  .boxingContainer {
    margin: 0 auto;
    padding-top: 57px;
  }
  .continueButtonInner {
    margin: 0 auto;
  }
  .booking {
    box-shadow: none;
    padding: 0;
  }

  .boxingBoxPriceDiscount {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 28px;
    width: 58px;
    height: 32px;
    padding: 0;
    top: 11px;
    right: -1px;
  }

  .boxingBoxPriceTotal {
    margin-right: 9px;
    font-size: 24px;
    line-height: 36px;
    margin-top: 9px;
  }

  .boxingBoxPricePrice {
    margin-right: 13px;
    margin-top: 12px;
    font-size: 20px;
    line-height: 30px;
  }
  .boxingBoxPricePerNightText {
    margin-top: 8px;
  }

  .boxingBoxPrice {
    height: 61px;
  }

  .fieldInputIcon {
    right: 22px;
    top: 6px;
  }
  .dayPickerInfo {
    margin: 23px 0px 24px;
    height: 94px;
  }

  .dayPickerValue {
    margin-top: -14px;
  }
  .dayPickerRow:last-child .fieldInputIcon {
    right: -1px;
  }

  .boxingBoxPickUpDeliveryContainer {
    padding-bottom: 24px;
    margin: 0 0px 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .bookingContainer {
    padding-bottom: 28px;
  }
}
@media screen and (min-width: 1024px) {
  .containerSpinnerPlaceholder {
    display: block;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
  .tabsTitle {
    padding-top: 4px;
    padding-bottom: 0;
  }

  .tabsTooltipContainer {
    margin-top: 14px;
  }

  .bookingContainer {
    width: 348px;
    margin: 0;
    padding: 0;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .boxingContainerInner {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  .booking {
    padding: 24px;
    padding-top: 20px;
    padding-bottom: 0px;
    box-shadow: none;
    width: auto;
    width: 348px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.25);
  }

  .boxingContainer {
    padding: 0;
    width: 100%;
  }
  .boxingBoxPrice {
    height: 51px;
  }
  .boxingBoxPricePrice {
    margin-top: 4px;
  }

  .boxingBoxPriceTotal {

  }
  .boxingBoxPricePerNightText {
    margin-top: 0px;
  }
  .boxingBoxPriceDuration {
    margin-top: -3px;
  }
  .boxingBoxPriceDiscount {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 28px;
    width: 54px;
    height: 32px;
    padding: 0;
    top: auto;
    bottom: 6px;
    right: 0;
  }
  .dayPickerInfo {
    height: 86px;
    margin: 13px 0px 20px;
  }

  .dayPickerLabel {
    margin-top: 2px;
  }

  .dayPickerValue {
    margin-top: -12px;
  }

  .fieldInputIcon {
    right: 23px;
    top: 12px;
  }

  .boxingBoxPickUpDeliveryContainer {
    padding-bottom: 20px;
    margin: 0 0px 12px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1679.98px) {
  .bookingContainer {
    width: 348px;
    margin: 0;
    padding: 0;

    border-radius: 8px;
    box-sizing: border-box;
  }
  .boxingContainerInner {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  .booking {
    padding: 24px;
    padding-top: 16px;
    padding-bottom: 0px;
    box-shadow: none;
    width: auto;
    width: 348px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.25);
  }

  .boxingContainer {
    padding: 0;
    width: 100%;
  }
  .boxingBoxPrice {
    height: 57px;
  }
}

@media screen and (min-width: 1680px) {
  .bookingContainer {
    width: 364px;
    margin: 0;
    padding: 0;

    border-radius: 8px;
    box-sizing: border-box;
  }
  .boxingContainerInner {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  .booking {
    padding: 32px;
    padding-top: 25px;
    padding-bottom: 0px;
    box-shadow: none;
    width: auto;
    width: 364px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.25);
  }

  .boxingContainer {
    padding: 0;
    width: 100%;
  }
  .boxingBoxPrice {
    height: 61px;
  }

  .boxingBoxPrice {
    height: 51px;
  }
  .boxingBoxPricePrice {
    margin-top: 4px;
  }

  .boxingBoxPriceTotal {

  }
  .boxingBoxPricePerNightText {
    margin-top: 0px;
  }
  .boxingBoxPriceDuration {
    margin-top: -3px;
  }
  .boxingBoxPriceDiscount {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 28px;
    width: 54px;
    height: 32px;
    padding: 0;
    top: auto;
    bottom: 6px;
    right: 0;
  }
  .dayPickerInfo {
    height: 86px;
    margin: 13px 0px 20px;
  }

  .dayPickerLabel {
    margin-top: 2px;
  }

  .dayPickerValue {
    margin-top: -12px;
  }

  .fieldInputIcon {
    right: 23px;
    top: 12px;
  }

  .boxingBoxPickUpDeliveryContainer {
    padding-bottom: 20px;
    margin: 0 0px 12px;
  }
}

@media screen and (max-width: 767.98px) {

  .booking {
    box-shadow: none;
  }
  .startBooking {
    width: 100%;
  }

  .boxingBoxPriceStart {
    font-size: 16px;
    line-height: 24px;
  }
  .boxingBoxPriceStart b {
    font-size: 20px;
    line-height: 30px;
    margin-right: 6px;
  }
  .boxingBoxPriceStart div {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 767.98px) {
  .modal {
    padding-bottom: 80px;
  }

  .continueButtonContainer {
    position: fixed;
    height: 84px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: var(--cwrr-scroll-width, 0);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
    min-width: 320px;
    z-index: 1;
    width: auto;
  }

  .continueButtonInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0 10px;
    height: 100%;
    box-sizing: border-box;
  }
  .continueButtonContainerInner {
  }

  .continueButton {
    width: auto;
    order: 1;
  }
  .buttonStyle {
    order: 1;
    width: 100%;
  }
  .continueButtonTitle {
    width: auto !important;
    text-align: left !important;
  }
}

.bookingBoxStart {
  width: 364px;
}
.bookingBoxStart .checkAvailabilityButton {
  width: 100%;
}
.bookingBoxStart .booking {
  width: 100%;
  padding: 24px 32px 32px;
  background-color: #ffffff;
  box-shadow: 0px 6px 16px rgb(0 0 0 / 25%);
  display: block;
  margin: 0;
}
.bookingBoxStart .boxingBoxPrice {
  height: 56px;
}
.bookingBoxStart .fieldInputIcon {
  right: 23px;
  top: 6px;
}

.bookingBoxStart .dayPickerInfo {
  position: static;
  margin: 21px 0 20px;
}

.bookingBoxStart .dayPickerLabel {
  margin-top: 6px;
}

.bookingBoxStart .dayPickerValue {
  margin-top: -3px;
}

.bookingBoxStart .dayPickerInfo {
  height: 94px;
}

.bookingBoxStart .checkAvailabilityButton {
  font-size: 16px;
  line-height: 16px;
  height: 52px;
}

.bookingBoxStart .boxingBoxPriceStart .boxingBoxPriceStartDailyRateTextMin {
  display: none;
}
.bookingBoxStart
  .boxingBoxPriceStart
  .boxingBoxPriceStartMinimumDurationTextMin {
  display: none;
}

.bookingBoxStart
  .boxingBoxPriceStart
  .boxingBoxPriceStartMinimumDuration
  > span {
  margin-left: 3px;
}
.bookingBoxStart .boxingBoxPriceStart .boxingBoxPriceStartMinimumDuration {
  font-size: 14px;
  line-height: 21px;
}

@media screen and (max-width: 1023.98px) {
  .modal {
    border-radius: 0px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
  .bookingBoxStart {
    width: 348px;
  }

  .bookingBoxStart .booking {
    padding: 24px 24px 32px;
  }

  .bookingBoxStart .boxingBoxPrice {
    height: 47px;
  }

  .bookingBoxStart .dayPickerLabel {
    margin-top: -7px;
  }

  .bookingBoxStart .dayPickerValue {
    margin-top: -13px;
  }

  .bookingBoxStart .dayPickerInfo {
    height: 86px;
    margin-bottom: 20px;
  }

  .bookingBoxStart .fieldInputIcon svg {
    width: 20px;
    height: 20px;
  }

  .bookingBoxStart .fieldInputIcon  {
    top: 3px;
  }
}
@media screen and (min-width: 1024px) {
  .boxingBoxPrice {
    height: 56px;
  }

  .boxingBoxPriceDiscount {
    top: 3px;
    width: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 28px;
    width: 54px;
    height: 32px;
    padding: 0;
    top: 5px;
    bottom: auto;
    right: 0;
  }

  .bookingBoxStart {
    width: 348px;
  }

  .bookingBoxStart .booking {
    padding: 24px 24px 32px;
  }

  .bookingBoxStart .boxingBoxPrice {
    height: 47px;
  }

  .bookingBoxStart .dayPickerInner {
    margin-top: 14px;
  }

  .bookingBoxStart .dayPickerLabel {
    margin-top: -7px;
  }

  .bookingBoxStart .dayPickerValue {
    margin-top: -13px;
  }

  .bookingBoxStart .dayPickerInfo {
    height: 86px;
    margin-bottom: 20px;
  }

  .bookingBoxStart .fieldInputIcon svg {
    width: 20px;
    height: 20px;
  }

  .bookingBoxStart .fieldInputIcon  {
    top: 3px;
  }

}
@media screen and (min-width: 1440px) and (max-width: 1679.98px) {
  .bookingBoxStart {
    width: 348px;
  }

  .bookingBoxStart .booking {
    padding: 24px 24px 32px;
  }
}

@media screen and (min-width: 1440px) {
  .boxingBoxPriceDuration {
    margin-top: 0;
  }

  .dayPickerInfo {
    margin: 14px 0 20px;
  }

  .dayPickerLabel {
    margin-top: 3px;
  }

  .boxingBoxPickUpDeliveryContainer {
    margin: 0 0 16px;
    padding-bottom: 20px;
  }

  .fullAddres {
    margin-top: 8px;
  }
}


