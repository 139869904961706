.container {
  background-color: var(--c-tertiary);
  border-radius: 3px;
  border: 1px solid var(--tooltipBorder);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  color: var(--c-white);
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s;
  z-index: 9999;
  padding: 12px 16px 16px;
}

.container .arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
  pointer-events: none;
}

.container[data-popper-placement*="top"] .arrow {
  width: 16px;
  height: 8px;
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  margin-bottom: -8px;
}

.container[data-popper-placement*="top"] .arrow::before {
  content: "";
  border-color: var(--c-tertiary) transparent transparent transparent;
  border-width: 8px 8px 0px 8px;
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.container[data-popper-placement*="bottom"] .arrow {
  left: 0;
  top: 0;
  margin-top: -8px;
  width: 16px;
  height: 8px;
}

.container[data-popper-placement*="bottom"] .arrow::before {
  content: "";
  border-color: transparent transparent var(--c-tertiary) transparent;
  border-width: 0 8px 8px 8px ;
  position: absolute;
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.container[data-popper-placement*="left"] .arrow {
  width: 8px;
  height: 16px;
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  margin-right: -8px;
}

.container[data-popper-placement*="left"] .arrow::before {
  content: "";
  border-color: transparent transparent transparent var(--c-tertiary);
  border-width: 8px 0px 8px 8px;
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.container[data-popper-placement*="right"] .arrow {
  width: 8px;
  height: 16px;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  margin-left: -8px;
}

.container[data-popper-placement*="right"] .arrow::before {
  content: "";
  border-color: transparent var(--c-tertiary) transparent transparent;
  border-width: 8px 8px 8px 0;
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}
