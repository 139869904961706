.content {
  position: relative;
  height: 100%;
  display: flex;
  cursor: pointer;
}

.content::before,
.content.disabled::before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  left: -3px;
  bottom: -3px;
  right: -3px;
  border: 3px solid var(--c-primary);
  border-radius: 8px;
  opacity: 0;
  transition: opacity 300ms;
}

.content:not(.disabled)::after {
  content: "";
  display: none;
  position: absolute;
  height: 80px;
  top: 2px;
  left: 2px;
  right: 2px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.00006) 100%);
  z-index: 2;
  border-radius: 4px 4px 0 0;
  pointer-events: none;
}

.content.checked::before,
.content.disabled::before {
  opacity: 1;
}

.content.disabled {
  opacity: .4;
}

.content.checked::after {
  display: block;
}

.content.disabled::after {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: 2;
}

.content.checked {
  margin-bottom: -1px;
}

.img {
  cursor: pointer;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
  min-height: 106px;
  box-sizing: border-box;
  border-radius: 4px;
  aspect-ratio: 3/2;
}

.img.checked,
.img.disabled {
  border: 2px solid #fff;
}

.checkbox {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 8px;
}

.checkboxSquare {
  border: 2px solid #fff !important;
}

.checkbox.checked > .checkboxSquare,
.checkbox.disabled > .checkboxSquare {
  border: 2px solid var(--c-primary) !important;
}

@media (min-width: 768px) {
  .checkbox {
    right: 3px;
    top: 12px;
  }

  .img {
    max-height: 250px;
    min-height: 182px;
  }
}

@media (min-width: 1024px) {
  .img {
    min-height: 158px;
  }
}

@media (min-width: 1440px) {
  .img {
    min-height: 178px;
  }
}

@media (min-width: 1680px) {
  .img {
    min-height: 168px;
  }
}
