.container {
  padding: 8px 0;
}

.str {
  white-space: normal;
  font-size: 16px;
  line-height: 24px;
}

.container p {
  white-space: pre-wrap;
}

.whiteSpaceNowrap {
  white-space: nowrap !important;
}
