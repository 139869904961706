.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clubName {
  padding-top: 12px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clubIconContainer {
  margin-right: 9px;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
    "planType planType"
    "activationDate expirationDate"
    "memberNumber autoRenew";
}

.planType {
  grid-area: planType;
}

.activationDate {
  grid-area: activationDate;
}

.expirationDate {
  grid-area: expirationDate;
}

.memberNumber {
  grid-area: memberNumber;
}

.autoRenew {
  grid-area: autoRenew;
}

.description {
  color: var(--c-tertiary-light, #7F7F7F);
}

.notificationInner {
  margin: 12px 0;
}

.clubPhoneNumberLink {
  color: var(--c-primary);
  font-weight: 500;
}

.footerButton {
  line-height: 16px;
  font-weight: bold;
}

.clubIcon {
  width: 40px;
  height: 40px;
  min-width: 40px;
}

.notificationInner {
  margin: 12px 0;
}

.supportInfoItem {
  margin-top: 24px;
}

.supportInfoItem:first-child {
  margin-top: 0px;
}

.memberDescriptionValuePlainStyle,
.memberDescriptionHtmlStyle > p {
  padding-right: 4px;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 0px;
  padding-top: 0px;
  color: var(--c-tertiary, '#232323');
}

@media (min-width: 768px) {

  .clubIconContainer {
    margin-right: 12px;
  }
  .clubIcon {
    width: 48px;
    height: 48px;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 
      "memberNumber planType planType"
      "activationDate expirationDate autoRenew";
  }

  .notificationInner {
    margin: 16px 0;
  }

  .supportInfoItem {
    margin-top: 32px;
  }
  
  .supportInfoItem:first-child {
    margin-top: 0px;
  }
}

@media (min-width: 1440px) {
  .clubIcon {
    width: 54px;
    height: 54px;
  }
}
