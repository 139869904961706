.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 0 12px;
}

.check {
  position: absolute;
  left: -32px;
  top: 16px;
}

.tickIcon > svg {
  width: 24px;
  height: 24px;
}

.insuranceDescription {
  padding: 0px 0 0px;
}

.insuranceDescriptionValuePlainStyle {
  composes: global(body--l);
  padding-bottom: 0px;
  padding-top: 0px;
  color: var(--c-primary, '#C30B24');
}

.insuranceDescriptionHtmlStyle {
  composes: global(body--l);
  padding-bottom: 0px;
  padding-top: 0px;
}

.insuranceDescriptionHtmlStyle p {
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.insuranceDescriptionHtmlStyle a {
  font-size: 16px;
  line-height: 24px;
  text-decoration: var(--link-underline-text-decoration);
  text-decoration-thickness: var(--link-underline-text-decoration-thickness);
  text-underline-offset: var(--link-underline-text-underline-offset);
  text-decoration-skip-ink: var(--link-underline-text-decoration-skip-ink);
}

.insuranceDescriptionHtmlStyle a:hover {
  color: var(--c-primary-light);
}

.planName {
  color: var(--c-tertiary-dark);
  padding-top: 12px;
  padding-bottom: 12px;
}

.selectedItemPackagesList {
  /* display: flex; */
  flex-wrap: wrap;
  gap: 0 16px;
}

.selectedItemPackagesList.selectedItemPackagesListWithBeforeIcon {
  position: relative;
  padding-left: 30px;
}

.selectedItemPackagesBeforeIcon {
  position: absolute;
  left: 0;
  top: 8px;
}

.selectedItemPackagesBeforeIcon > svg {
  width: 24px;
  height: 24px;
}

.selectedItemPackagesBeforeIcon > svg path {
  fill: revert-layer;
}

.selectedItemPackagesListItem {
  display: inline;
}

.selectedItemPackagesListItemContent {
  display: inline;
}

/* .selectedItemPackagesListItem:not(:last-child):after {
  content: ';';
} */

.selectedPackages {
  display: inline;
  align-items: center;
  gap: 0 4px;
  padding-top: 0;
  padding-bottom: 0px;
  margin-top: -6px;
}

.allowedContainer {
  margin: 13px 0 5px;
}

.switchContainer {
  margin-top: 4px;
}

.switch {
  display: flex;
  align-items: flex-start;
  gap: 0 16px;
  margin-left: 12px;
  margin-right: -16px;
}

.tooltipQuestionContainerText {
  color: #fff;
}

.btnIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 8px;
  position: relative;
  bottom: -6px;
}

.btnIcon svg {
  width: 24px;
  height: 24px;
}

@media (min-width: 768px) {
  .header {
    gap: 0 16px;
  }

  .planName {
    padding-bottom: 10px;
  }
  

  .selectedPackages {
    margin-top: -7px;
  }

  .allowedContainer {
    margin: 8px 0 9px;
  }

  .switchContainer {
    margin-top: 8px;
  }

  .check {
    left: -36px;
    top: 28px;
  }

  .switch {
    margin-top: 8px;
    margin-right: -20px;
  }
}

@media (min-width: 1024px) {
  .planName {
    font-size: 20px;
    line-height: 30px;
  }

  .selectedPackages {
    margin-top: -8px;
  }
}
