.modal {
  margin: 0;
  padding: 0;
  border-radius: 0;
  overflow-y: auto;
  min-height: 100%;
  max-height: 100%;
  box-shadow: none;
}

.crossButton {
  width: 52px;
  height: 52px;
  position: fixed;
  right: var(--cwrr-scroll-width, 0);
}

.content {
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 40px;
  margin: 0 auto;
}
.content.footer-small {
  padding-bottom: 110px;
}
.content.footer-medium {
  padding-bottom: 124px;
}

.title.medium {
  padding-top: 24px;
  padding-bottom: 12px;
  font-size: 24px;
  line-height: 36px;
}

@media (min-width: 768px) {
  .modal.small .content {
    padding-top: 12px;
    padding-left: 13px;
    padding-right: 13px;
    max-width: 66.6666666667%;
  }

  .modal.medium .content {
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .content.footer-small,
  .content.footer-medium,
  .content {
    padding-bottom: 60px;
  }
}

@media (min-width: 1024px) {
  .modal.small,
  .modal.medium {
    max-width: calc(50% - 44px);
    min-height: auto;
    border-radius: 8px;
    overflow-y: visible;
    max-height: none;
    margin: 40px 0;
  }

  .modal.medium  {
    max-width: calc(83.3333333333% - 58px);
  }

  .modal.small .content,
  .modal.medium .content {
    padding-top: 12px;
    padding-left: 56px;
    padding-right: 56px;
    max-width: 100%;
  }

  .content.footer-small,
  .content.footer-medium,
  .content {
    padding-bottom: 48px;
  }

  .crossButton {
    position: absolute;
    right: 0;
    width: 42px;
    height: 42px;
  }
}

@media (min-width: 1440px) {
  .title {
    padding-bottom: 16px;
  }

  .modal.small {
    max-width: calc(50% - 84px);
  }

  .modal.medium  {
    max-width: calc(66.6666666667% - 104px);
  }
  .title.medium {
    padding: 32px 0 16px;
    font-size: 28px;
    line-height: 42px;
  }
}

@media (min-width: 1680px) {
  .modal.small {
    max-width: 760px;
  }

  .modal.medium  {
    max-width: 1024px;
  }
}
