.title {
  display: flex;
  align-items: center;
}
.title.sizeMiddle {
  font-size: 16px;
  line-height: 24px;
}
.title.sizeLarge {
  font-size: 18px;
  line-height: 27px;
}

.hintIcon {
  width: 24px;
  height: 24px;
  color: inherit;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .title.sizeMiddle {
    font-size: 18px;
    line-height: 27px;
  }

  .title.isTableTabs {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    cursor: text;
  }
}

@media screen and (min-width: 1024px) {
  .title.sizeMiddle {
    font-size: 16px;
    line-height: 24px;
  }
}