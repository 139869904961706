.pac-container {
  z-index: 10000;
  overflow-y: auto;
  margin-top: 0;
  padding: 5px 0 0;
  border: none;
  border-radius: 4px;
  background-color: $c-white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.280813);
}

.pac-logo:after {
  margin: 10px 12px 15px 0;
}
.pac-item {
  padding: 9px 12px;
  font-size: 0;
  line-height: 0;
  border: none;
  cursor: pointer;
  white-space: normal;
  background-color: $c-white;
  transition: background-color 0.25s;
}
.pac-item * {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
  text-transform: none;
  color: $c-black-cool;
  font-family: Aeonik, Arial, Helvetica, sans-serif;
  font-weight: normal;
}
@media (min-width: 768px) {
  .pac-item * {
    font-size: 16px;
  }
}
.pac-matched {
  font-weight: 700;
}
.pac-item:hover,
.pac-item:hover .pac-item-query,
.pac-item-selected,
.pac-item-selected .pac-item-query {
  background-color: $c-black-hover;
}
.pac-icon-marker {
  display: none;
}
