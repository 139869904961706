.root {
    flex: 1;
}

.valueAlt {
    color: var(--c-tertiary-light);
}

.touchable {
    cursor: pointer;
    color: var(--c-primary);
}

.date {
    flex: 1;
}

.main {
    width: 284px;
}

.prices {
    flex: 1;
}

.dates {
    align-items: stretch;
    justify-content: stretch;
}

.datesDivider {
    width: 1px;
    background-color: var(--c-grey);
}

.footer :global(.field-input__field) {
    background-color: #ffffff;
}

.submit {
    justify-content: flex-end;
}

.tiiLink {
    font-size: inherit;
    line-height: inherit;
    padding: 0;
}

.headerTitle {
    font-weight: 500;
}

@media (max-width: 1439.98px) {
    .root:not(.isSingleBlock) .main {
        width: 100%;
    }

    .root:not(.isSingleBlock) .info {
        flex-direction: column;
    }
}

@media (max-width: 767.98px) {
    .root.isSingleBlock .main {
        width: 100%;
    }

    .root.isSingleBlock .info {
        flex-direction: column;
    }
    .notification {
        z-index: 99999999;
    }
}
@media (min-width: 768px) and (max-width: 1439.98px) {
    .notificationInner {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
        margin: 0 auto;
    }
}

