.mainInfo {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 
    "edit    avatar   share"
    "name    name     name"
    "rating  rating   rating"
    "address address  address"
    "date    date     date";
}

.edit {
  grid-area: edit;
  align-self: center;
  justify-self: flex-start;
}

.avatar {
  grid-area: avatar;
}

.rating {
  grid-area: rating;
  justify-self: center;
}

.address {
  grid-area: address;
  justify-self: center;
}

.share {
  grid-area: share;
  align-self: center;
  justify-self: flex-end;
}

.name {
  grid-area: name;
  justify-self: center;
}

.date {
  grid-area: date;
  justify-self: center;
}

.info {
  grid-area: info;
  display: contents;
}
.infoDate {
  grid-area: infoDate;
  display: contents;
}

@media (min-width: 768px) {
  .mainInfo {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 
      "avatar name"
      "avatar info"
      "avatar address";
  }

  .info {
    display: flex;
    align-items: center;
  }

  .edit {
    display: none;
  }

  .name {
    justify-self: flex-start;
  }

  .address {
    justify-self: flex-start;
  }
  .share {
    align-self: flex-start;
  }
  .devider {
    align-self: stretch;
  }
}

@media (min-width: 1024px) {
  .mainInfo {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto 1fr auto auto;
    grid-template-areas: 
      "avatar   name"
      "avatar   rating"
      "address  address"
      "infoDate infoDate"
      ;
  }

  .info {
    display: contents;
  }

  .date {
    grid-area: date;
    justify-self: flex-start;
    display: flex;
    align-items: center;
  }

  .infoDate {
    display: flex;
  }

  .rating {
    justify-self: flex-start;
  }

  .deviderDate {
    align-self: stretch;
  }
}