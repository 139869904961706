.modalRoot {
  max-height: 100% !important;
}

.crossButton {
  position: fixed;
  width: 52px;
  height: 52px;
  background-color: transparent;
}
.hidden {
    opacity: 0 !important;
}

.modal {
  padding: 0 16px;
  overflow: hidden;
  width: 960px;
}

.content {
  width: 100%;
  position: relative;
  align-self: stretch;
}

.wrap {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
}

.mobileHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobileHeader p {
  padding-bottom: 0;
  padding-top: 0;
}

.stepsContainer {
}

.stepActive {
}

.stepInactive {
  display: none;
}

@media (min-width: 768px) {
  .mobileHeader {
    flex-direction: row;
  }

  .mobileHeader p {
    padding-bottom: 12px;
    padding-top: 16px;
  }

}

@media (max-width: 767.98px) {
  .modalContent {
    margin-bottom: 84px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .modalContent {
    margin-bottom: 100px;
  }
}
@media (max-width: 1023.98px) {
  .modal {
    max-height: 100%;
    overflow-y: auto;
  }
  .crossButton {
    right: var(--cwrr-scroll-width, 0);
  }

}

@media (min-width: 1024px) and (max-height: 729.98px) {
  .modal {
    width: 100%;
    margin: 0 auto;
    min-width: auto !important;
    max-height: 100%;
    overflow-y: auto;
  }
  .modalContent {
    width: 738px;
    margin: 0 auto;
  }
  .wrap {
    align-items: flex-start;
  }
  .content {
   margin: 0;
  }
  .crossButton {
    position: fixed;
    right: var(--cwrr-scroll-width, 0);
  }
}

@media (min-width: 1024px) and (min-height: 730px) {
  .modalRoot {
    overflow: hidden;
  }
  .modal {
    padding: 0;
    border: 4px solid #ffffff;
    margin: 30px 0 35px;
    max-height: calc(100vh - 75px);
  }
   .content {
    margin: 0;
    margin-bottom: 85px;
    margin-right: 4px;
  }
  .stepsContainer {
    max-height: calc(100vh - 296px);
    overflow-y: auto;
    padding: 0 21px 0 24px;
    margin-bottom: 17px;
    scrollbar-width: thin;
    scrollbar-color: #C4CDD5 transparent;
    
  }

  .stepsContainer.isPaymentMethodStep {
    margin-bottom: 0px;
}
  .stepsContainer::-webkit-scrollbar {
    width: 4px;
  }
  
  .stepsContainer::-webkit-scrollbar-track {

    border-radius: 4px;
  }
  
  .stepsContainer::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #C4CDD5;
  }
  
  .crossButton {
    position: absolute;
    width: 42px;
    height: 42px;
    top: -4px;
    right: -4px;
    z-index: 3;
  }
  .content {
    width: 100%;
  }

  .mobileHeader {
    display: none;
  }

  .wrap {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
  }
}