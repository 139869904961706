@keyframes fade-in {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.root {
    animation: fade-in 400ms;
}

.isReceived .text {
    background-color: var(--c-secondary-lightest);
    border: 1px solid var(--c-secondary-lighter);
}

.isSent {
    align-self: flex-end;
}

.avatar {
    position: relative;
    overflow: hidden;
    width: 42px;
    min-width: 42px;
    height: 42px;
    border-radius: 50%;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text {
    border-radius: 4px;
    border: 1px solid rgba(101, 109, 118, 0.15);
    background-color: #ffffff;
    color: var(--c-tertiary);
    white-space: pre-line;
    word-break: break-word;
    word-wrap: break-word;
}

.date {
    color: var(--c-tertiary-light);
}

.isSystem .text {
    background-color: var(--c-quaternary);
    border-width: 0;
}

.isSystem .avatar {
    background-color: var(--c-primary);
}

.isSystem .avatar img {
    width: auto;
}

@media (min-width: 768px) {
    .isSent {
        margin-left: 54px;
    }
}

@media (max-width: 495.98px) {
    .avatar {
        display: none;
    }
}
