.spinner {
  top: 334px;
}

.spinnerContainer {
  background: none !important;
}

.spinnerContainer .spinner .spinner__icon {
  background: url("./assets/spinner-white.svg") no-repeat center;
}

.spinnerWLDark .spinner__icon {
  background: url("./assets/spinner-white.svg") no-repeat center;
}