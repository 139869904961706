.toolbar {
    composes: global(container);
}

.actions {
    height: 84px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
@media (min-width: 768px) {
    .actions {
        height: 92px;
        justify-content: center;
    }
}

.done {
    position: absolute;
    right: 0;
}

.icon {
    display: block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.rotateLeftIcon {
    composes: icon;
    background-image: url("./icons/rotate-left.svg");
    height: 16px;
}

.rotateRightIcon {
    composes: icon;
    background-image: url("./icons/rotate-right.svg");
    height: 16px;
}

@media screen and (min-width: 768px) and (max-width: 1023.98px) {
    .imageCaptionContainer {
        width: 400px;
    }
}

@media screen and (min-width: 1024px) {
    .imageCaptionContainer {
        width: 500px;
    }
}