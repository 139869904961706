.discountContainer {
  display: inline-flex;
  align-items: center;
}

.discount {
  display: inline-flex;
  font-size: 16px;
  line-height: 28px;
  padding: 0px 8px;
  border-radius: 4px;
  color: #fff;
  background: var(--c-status-success-dark);
  margin-right: 16px;
}

.discountPrice {
  font-size: 20px;
  line-height: 30px;
  color: #656d76;
  text-decoration-line: line-through;
  margin-right: 12px;
}

.total {
  display: block;
  font-size: 20px;
  line-height: normal;
  color: var(--c-tertiary);
}

.totalText {
  font-size: 16px;
  line-height: 24px;
}
