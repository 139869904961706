.menuItem {
  cursor: pointer;
}
.menuItem:hover {
  background: rgba(196, 205, 213, 0.3);
  border-radius: 4px;
}
.menuItemActive {
  background: rgba(196, 205, 213, 0.3);
  border-radius: 4px;
}