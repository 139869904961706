.icon svg {
  width: 64px;
  height: 64px;
}

.icon svg path {
  fill: var(--c-quaternary-dark);
}

@media (min-width: 768px) {
  .icon svg {
    width: 96px;
    height: 96px;
  }
}
