.avatarContainer {
  height: 100%;
  cursor: pointer;
}

.avatarInner,
.avatarInner2 {
  height: 100%;
}
.avatarPhotoCameraIcon {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 6px solid var(--c-white, #FFF);
  border-radius: 100%;
  background: var(--c-white, #FFF);
}
