.card {
    border-radius: 8px;
    background-color: var(--c-grey-cool);
}

.collapseHeader {
    border-top: 1px solid rgba(196, 205, 213, 0.5);
}

.collapse:last-child {
    border-bottom: 1px solid rgba(196, 205, 213, 0.5);
}

.headerTitle {
    font-weight: 500;
}
