.stepsMenuContainer {
  padding-top: 8px;
}

.stepsMenu {
  display: flex;
  padding: 0;
  gap: 0 16px;
}

.stepTitle {
  padding-top: 0;
}

.stepsMenuItemButton svg path {
  fill: revert-layer;
}

.stepsMenuItemButton.stepsMenuItemButtonNotWasSelected {
  cursor: default;
  pointer-events: none;
}

.leftGradient:before, .rightGradient:after {
  bottom: 0;
  content: "";
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: all .1s;
  visibility: visible;
  width: 100px;
  z-index: 1;
}

.leftGradient:before {
  background: linear-gradient(271.15deg,#fff 1.13%,hsla(0,0%,100%,0) 99.21%);
  left: 0;
  transform: rotate(-180deg);
}

.rightGradient:after {
  background-image: linear-gradient(271.15deg,#fff 1.13%,hsla(0,0%,100%,0) 99.21%);
  right: 0;
}

.isScroll {
  overflow-x: auto;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

.isScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  cursor: pointer;
}

.isScroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.isScroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.isScroll.isScrollBar {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #656d76 transparent;
}

.isScroll.isScrollBar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
}

.isScroll.isScrollBar::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 2px;
}

.isScroll.isScrollBar::-webkit-scrollbar-thumb {
  background-color: #656d76;
  border-radius: 2px;
} 

.justifyContentCenter {
  justify-content: center;
}

@media screen and (max-width: 767.98px) {
  .stepsMenu {
    justify-content: center;
  }
  .stepsMenuItemButton {
    width: 52px;
    height: 52px;
    border: none !important;
    background: none !important;
    position: relative;
  }
  
  .stepsMenuItemButton svg {
    width: 52px;
    height: 52px;
  }

  .stepsMenuItem:not(:last-child) .stepsMenuItemButton::before {
    position: absolute;
    content: "";
    width: 40px;
    top: 50%;
    left: calc(100% - 11px);
    margin-top: -1px;
    height: 2px;
    background-color: #DFE2E6;
    z-index: 1;
  }

  .stepsMenuItem:not(:last-child) .stepsMenuItemButton.stepsMenuItemButtonCompleted::before {
    background-color: #B0CEAA;
  }
}

@media screen and (min-width: 768px) {
  .stepsMenuContainer {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .stepsMenuItemButton {
    border: none !important;
    height: 52px;
    padding: 13px 17px 13px 21px;
  }

  .stepsMenuItemButton.stepsMenuItemButtonSelected {
    background: var(--c-status-default-transparent, '#eee');
    border-radius: 4px;
  }

  .stepsMenuItemButton:hover {
    background: var(--c-status-default-transparent, '#eee');
    border-radius: 4px;
  }

}
