@supports (height: 100dvh) {
  .wrapper {
    height: 100dvh;
  }
}

.container {
  padding-bottom: 84px;
}

@media (max-width: 1023.98px) {
  .crossButtonLocation {
    border-radius: 0;
    width: 52px;
    height: 52px;
  }
}

@media (min-width: 786px) {
  .container {
    padding-bottom: 92px;
  }
}

@media (min-width: 1680px) {
  .content {
    margin-left: -32px;
    margin-right: -32px;
  }
}
