.root {
  position: relative;
}

@media (min-width: 768px) and (max-width: 1440px) {
  .root::after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    content: "";
    width: 100px;
    background-image: linear-gradient(
      271.15deg,
      #ffffff 1.13%,
      rgba(255, 255, 255, 0) 99.21%
    );
    opacity: 0;
    visibility: hidden;
    transition: all 100ms;
  }
}

.list {
  composes: global(dn) global(db-m) global(l-0) global(r-0) global(t-m-20) global(b-m-20) global(mt-m-20) global(mb-m-12) global(mt-l-8) global(mb-l-8);
  white-space: nowrap;
  overflow-x: auto;
  list-style: none;
  display: flex;
}
:global(.no-touchevents) .list {
  scrollbar-width: thin;
  scrollbar-color: #656d76 transparent;
}
:global(.no-touchevents) .list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
}
:global(.no-touchevents) .list::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 2px;
}
:global(.no-touchevents) .list::-webkit-scrollbar-thumb {
  background-color: #656d76;
  border-radius: 2px;
}

.item {
  composes: global(mr-12) global(mr-m-16) global(db) global(dib-m) global(db-xl);
  position: relative;
}

.item:last-child {
  margin-right: 0;
}

.link {
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  align-items: flex-start;
  white-space: normal;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: var(--c-black-cool);
  outline: none;
  composes: global(l-8) global(r-8) global(r-m-16);
  font-weight: normal;
}
@media (min-width: 1440px), (max-width: 767px) {
  .link {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.link::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  background: transparent url("./tick-gray.svg") no-repeat 0 0;
  margin-right: 8px;
  flex: 0 0 auto;
}

.disabledLink {
  composes: link;
  cursor: default;
}

.disabledLink:hover {
  color: var(--c-black-cool);
}

.activeLink {
  font-weight: 500;
  background-color: rgba(196, 205, 213, 0.3);
  pointer-events: none;
}

.activeLink:hover {
  color: var(--c-primary);
}

.completedLink {
  composes: link;
}

.withClick:hover {
    cursor: pointer;
    background-color: rgba(196, 205, 213, 0.2);
}

.completedActiveLink {
  composes: activeLink;
}

.completedLink::before,
.completedActiveLink::before {
  background-image: url("./tick-green.svg");
}

.errorLink::before {
  background-image: url("./error.svg");
}

.stepTitle {
  padding-top: 0 !important;
  margin-bottom: 8px;
}

@media (max-width: 1023.98px) {

  .list {
    justify-content: center;
  }

  .stepsMenuContainer {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1024px) {
  .root {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
  }
}
@media (max-width: 767px) {
  .linkMobileS {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 14px;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 1px solid var(--c-grey);
    border-radius: 50%;
    color: var(--c-grey);
  }

  .linkMobileS.withClick,
  .linkMobileS.activeLink {
    color: var(--c-tertiary) !important;
    border: 1px solid var(--c-tertiary);
    background-color: transparent;
  }

  .linkMobileS.completedLink {
    border: 1px solid var(--c-primary);
    background-color: var(--c-primary);
    color: #ffffff !important;
  }

  .link::before {
    content: none;
  }
}

@media (min-width: 1024px) and (max-height: 729.98px) {
  .root {
    position: relative;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) and (min-height: 730px) {
  .root {
    margin: 0 32px;
    margin-right: 21px;
    margin-left: 23px;
  }

  .stepsMenuContainer {
    margin-right: 0px;
    margin-top: 8px;
  }
  .list.hasScrollShadow::after {
    position: absolute;
    top: auto;
    bottom: -80px;
    right: 0;
    left: 0;
    height: 80px;
    width: 100%;
    content: '';
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0.89%, rgba(255, 255, 255, 0) 99.11%);
    pointer-events: none;
  }

  .root.hasScrollShadow .shadowContainer {
    position: absolute;
    bottom: -50px;
    left: 0;
    height: 50px;
    right: -32px;
    left: -32px;
    overflow: hidden;
    pointer-events: none;
}

  .root.hasScrollShadow .shadowContainer::after {
      content: '';
      position: absolute;
      bottom: 50px;
      left: 32px;
      right: 32px;
      height: 104px;
      box-shadow: 0px -8px 32px rgba(0, 0, 0, 0.25);
      z-index: 1;
  }
}